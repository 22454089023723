import { createSlice } from "@reduxjs/toolkit";
import {
    MY_REQUESTS,
} from '../../../utils/constants'

export const LayoutSlice = createSlice({
    name: "layout",
    initialState: {
        sidebar: false,
        approveDialog: false,
        processing: true,
        ntSideBar: false,
        ntSideBarType: MY_REQUESTS,
        notifyLoading: true,
        loadingState: [] as any[],
        refreshTrigger: 0,
        numReqCards: 0
    },
    reducers: {
        toggleSidebar: (state: any, { payload }) => {
            state.sidebar = payload;
        },
        tgApproveDialog: (state: any, { payload }) => {
            state.approveDialog = payload;
        },
        toggleProcessing: (state: any, { payload }) => {
            state.processing = payload;
        },
        tgNtSideBar: (state: any, { payload }) => {
            state.ntSideBar = payload;
        },
        setNtSideBar: (state: any, { payload }) => {
            state.ntSideBarType = payload;
        },
        setLoadingState: (state: any, { payload }: any) => {
            if (!state.loadingState.includes(payload.type)) {
                state.loadingState.push(payload);
            }

            if (state.loadingState.length === state.numReqCards) {
                state.notifyLoading = false;
                state.loadingState = [];
            }
        },
        setNotifyLoading: (state: any, { payload }) => {
            state.notifyLoading = payload;
        },
        tgRefresh: (state: any) => {
            state.refreshTrigger += 1;
        },
        setNumReqCards: (state: any, {payload}) => {
            state.numReqCards = payload;
        }
    },
    extraReducers: {

    },
})

export const {
    toggleSidebar,
    tgApproveDialog,
    toggleProcessing,
    tgNtSideBar,
    setNtSideBar,
    setLoadingState,
    setNotifyLoading,
    tgRefresh,
    setNumReqCards
} = LayoutSlice.actions

export default LayoutSlice.reducer;