import React from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const TradeShiftSuccessStep = () => {
    return (
        <Box padding={2}>
            <Box
                sx={{
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    padding: 2,
                    marginTop: 1,
                    backgroundColor: "#ffffff",
                }}
            >
                <Box display="flex" alignItems="center" mb={2}>
                    <CheckCircleOutlineIcon color="success" />
                    <Typography variant="body1" ml={1}>
                        Your shift request has been successfully submitted.
                    </Typography>
                </Box>
                <Typography variant="body2" gutterBottom>
                    <strong>Key Information</strong>
                </Typography>
                <Typography variant="body2" gutterBottom>
                    The relevant team members have been notified about your request to have this shift covered.
                </Typography>
                <Typography variant="body2" gutterBottom>
                    <strong>Stay Updated on Your Request</strong>
                </Typography>
                <Typography variant="body2" gutterBottom>
                    To receive timely updates, ensure that Nextgen notifications are enabled. You can manage your
                    notification preferences here to stay informed of any changes or actions taken.
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    Please note that while we’ve taken steps to assist in finding coverage, there's no guarantee that
                    another employee will take your shift or that management will approve your request. It's advisable
                    to follow up and ensure your request is addressed.
                </Typography>
            </Box>
        </Box>
    );
};

export default TradeShiftSuccessStep;
