import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/solid.min.css";
import "@fortawesome/fontawesome-free/css/regular.min.css";
import "@fortawesome/fontawesome-free/css/brands.min.css";
import Cookies from "js-cookie";
import { enableMapSet } from "immer";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// Check if the cookie is set
const userToken = Cookies.get("U_EVAL_VALUE");

// THIS WILL SET COOKIES FOR TOKEN FOR DEVELOPMENT ONLY

if(process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'local') {

    // allief - manager (53460)
    // const cookieValue = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dnZWRpbl9pZCI6IjUzNDYwIiwidXNlcl9hY2NvdW50X2lkIjoiMTUzMzgzNTE5MyJ9.NzrPaM3FAVpSuUN-4SyqjGdy_cZoyd2PMH1j4n4319I";
  
    // cicib (39914)
    // const cookieValue = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dnZWRpbl9pZCI6IjM5OTE0IiwidXNlcl9hY2NvdW50X2lkIjoiMTUzMzgzNTE5MyJ9.NqfExd3_NfKj4LtXwUo3i9HcYftaquH75KXd6KVi_co";
    
    // danielm (38453)
    // const cookieValue = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dnZWRpbl9pZCI6IjM4NDUzIiwidXNlcl9hY2NvdW50X2lkIjoiMTUzMzgzNTE5MyJ9.mkAimJx9RUDJwIkAm_pp4fkQxlHueWOEpoP2YybcNI0";
  


    //// production tokens
    // bqass/welcome
    const cookieValue = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dnZWRpbl9pZCI6IjU2NTQyIiwidXNlcl9hY2NvdW50X2lkIjoiMTcyNTUyNDEwOSJ9.NaxTWeeIi4GjHeUmvgFcdfvhUKMgH6aA3Co20UsoQxk";
    
    // aqass/welcome
    // const cookieValue = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2FjY291bnRfaWQiOiIxNzI1NTI0MTA5IiwibG9nZ2VkaW5faWQiOiI1NjUzOSJ9.4Pm1dzrFyivFSDvw4rTWBLV_A4DpunAQ0vv4e28YKCU"
    // const cookieValue = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dnZWRpbl9pZCI6IjQwNzk5IiwiaXNfc3VwZXJfdXNlciI6ZmFsc2UsImlhdCI6MTcyOTUxMDg5OCwibmJmIjoxNzI5NTEwODk4LCJ1c2VyX2RhdGEiOnsib3NfZW1wX2FjY291bnRfaWQiOiI0MDc5OSIsInVzZXJfYWNjb3VudF9pZCI6IjE2NTM1NjIyODEiLCJvc19lbXBfcGFzc3dvcmQiOiJhZDA3Zjk5OWQ4MDIwODcyNGU4MzhhZmQzZjVmMGJkNjpkYyIsIm9zX2VtcF9maXJzdF9uYW1lIjoiQWRhbSIsIm9zX2VtcF9sYXN0X25hbWUiOiJIdW50ZXIiLCJlbWFpbCI6ImFkYW1AZWZmaWNpZW50b2ZmaWNlLmNvbSIsIm9zX2VtcGxveWVlX3JvbGVfY29kZSI6bnVsbCwiZGlzcGxheV9uYW1lIjoiQWRhbSBIdW50ZXIifX0.fmhAa6EJs774W5-sfW1jJHJXJhw7TuJ_HZ-Mkd8MIHk"
    Cookies.set(
      "U_EVAL_VALUE",
      cookieValue
    );
  }


if (!userToken) {
    // const redirectUrl = `${process.env.REACT_APP_PHP_FRONTEND_URL}/login/loginform`;
    const redirectUrl = `https://app.webapph.com/app17/login/loginform/`;
    // Redirect to login if the cookie is not set
    window.location.href = redirectUrl;
    // window.location.replace(redirectUrl);
} else {
    // Enable the plugin
    enableMapSet();

    root.render(
        <Provider store={store}>
            <App />
        </Provider>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}
