import React, { useEffect } from "react";
import { Breaktime, PayperiodFilterType, TimesheetGroupedByDate, TimesheetType } from "../../types";
import { Typography, Box, Button, Paper, Divider, Theme, useMediaQuery } from "@mui/material";
import { ListContainer, RecordDate, RecordDateLine, RecordItem, RecordTime, RecordDuration } from "./styles";
import { DateTimeUtil } from "../../utils";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../redux/store";
import { setAllowRefetch, setSelectedRecord } from "../../redux/features/timesheetv2/slice";
import { AccessTime as AccessTimeIcon, FreeBreakfast as FreeBreakfastIcon } from "@mui/icons-material";
import { FiberManualRecord as FiberManualRecordIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getSummary } from "../../redux/features/timesheet";
import { getTimeSheets } from "../../redux/features/timesheetv2";
import RecordRow from "./recordRow";

export type RecordsComponentProps = {
    timesheetsGrouped?: TimesheetGroupedByDate;
    timesheets: TimesheetType[];
    handleAddEntry?: (item: TimesheetType) => void;
    handleEditEntry?: (item: TimesheetType) => void;
    handleDeleteEntry?: (item: TimesheetType) => void;
    isMobile: boolean;
};

const RecordsComponent: React.FC<RecordsComponentProps> = ({
    timesheetsGrouped,
    handleAddEntry,
    handleEditEntry,
    handleDeleteEntry,
    timesheets,
    isMobile
}) => {
    return (
        <>
            <Box>
                {timesheets.map((timesheet) => <RecordRow isMobile={isMobile} key={timesheet.id} timesheet={timesheet}/>)}
            </Box>
        </>
    );
};

export default RecordsComponent;
