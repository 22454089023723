import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../axios/API";
import AxiosClient from "../../../axios/axiosClient";
import { DeleteSwapScheduleRequest, UpdateSwapScheduleRequest } from "../../../types";
// import { ISchedule } from './models/schedule';

/**
 * NEW APIs
 */

export const getShiftsByDate = createAsyncThunk(
    "/schedules/by-date",
    async (data: { startDatetime: string; endDatetime: string }, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await API.get("/schedule", {
                params: data,
            });
            return response.data;
        } catch (error) {
            console.log(error);
            return rejectWithValue(error);
        }
    }
);

export const getSwapEmployees = createAsyncThunk(
    "/schedules/swap_employees",
    async (
        data: {
            request_type: number;
            start_date: string;
            end_date: string;
            assigned_shift_code: string;
            department_code: string;
            group_code: string[];
            name_search: string;
        },
        thunkAPI
    ) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await API.post("/schedule/swap_employees", data);
            return response.data;
        } catch (error) {
            console.log(error);
            return rejectWithValue(error);
        }
    }
);

export const createSwapRequest = createAsyncThunk(
    "/schedules/cannotwork_request",
    async (
        data: { scheduleId: number; scheduleIds: string[]; employeeIds: string[]; notes: string; requestType: number },
        thunkAPI
    ) => {
        const { rejectWithValue } = thunkAPI;
        try {
            console.log("data ===>", JSON.stringify(data, null, 2));
            const response = await API.post("/schedule/cannotwork_request", data);
            console.log("response ===>", response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            return rejectWithValue(error);
        }
    }
);

export const updateSwapRequest = createAsyncThunk(
    "/schedules/swap/update",
    async (data: UpdateSwapScheduleRequest, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        // const { scheduleId: id, ...rest } = data;
        try {
            const { referenceId, ...body } = data;
            console.log("data ===>", JSON.stringify(data, null, 2));
            const response = await API.post(`/schedule/swap/${referenceId}`, body);
            return response.data;
        } catch (error) {
            console.log(error);
            return rejectWithValue(error);
        }
    }
);

export const deleteSwapRequest = createAsyncThunk(
    "/schedules/swap/delete",
    async (data: DeleteSwapScheduleRequest, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        // const { scheduleId: id, ...rest } = data;
        try {
            const { scheduleId } = data;
            console.log("data ===>", JSON.stringify(data, null, 2));
            const response = await API.delete(`/schedule/cannotwork_request/${scheduleId}`);
            return response.data;
        } catch (error) {
            console.log(error);
            return rejectWithValue(error);
        }
    }
);

/**
 * OLD APIs
 */
export const getScheduleData = createAsyncThunk("/schedule", async (data: object) => {
    try {
        const response = await API.get("/schedule-employee-shift/shifts", {
            params: data,
        });
        // console.log('api call schedule data====>', response.data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
});

export const getAvailSchedules = createAsyncThunk("/trade", async (data: any) => {
    try {
        const response = await API.get("/schedule-employee-shift/avail_shifts", {
            params: data,
        });
        // console.log('api call2 ====>', response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const getAvailEmp = createAsyncThunk("/release", async (data: any) => {
    try {
        const response = await API.get("/schedule-employee-shift/avail_emp", {
            params: {
                data: data,
            },
        });
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const confirmTrade = createAsyncThunk("/trade/confirm", async (data: any) => {
    try {
        // console.log('data log before ajax call trade====>', data)
        const response = await API.post("/schedule-employee-shift/trade", data);
        // console.log('api call result: trade ====>', response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const confirmRelease = createAsyncThunk("/release/confirm", async (data: any) => {
    try {
        // console.log('data log before ajax call release ====>', data)

        const response = await API.post("/schedule-employee-shift/release", data);
        // console.log('api call result: release ====>', response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const confirmDrop = createAsyncThunk("/drop/confirm", async (data: any) => {
    try {
        const response = await API.post("/schedule-employee-shift/drop", {
            user: data.user,
            selectedShift: data.selectedShift,
            reason: data.reason,
        });
        // console.log('api call result: drop ====>', response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const getSwapTransactions = createAsyncThunk("/swap/txns", async (user: any) => {
    try {
        // console.log('data log ====>', user)
        const response = await API.get("/shift-swap-transaction/txns", {
            params: user,
        });
        // console.log('api call result: txns ====>', response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const acceptRequest = createAsyncThunk("/txn/accept", async (request: any) => {
    try {
        // console.log('id log ====>', request)
        const response = await API.post("/shift-swap-transaction/accept", {
            request: request,
        });
        // console.log('api call result: accept txn ====>', response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const rejectRequest = createAsyncThunk("/txn/reject", async (request: any) => {
    try {
        // console.log('id log ====>', request)
        const response = await API.post("/shift-swap-transaction/reject", {
            request: request,
        });
        // console.log('api call result: reject txn ====>', response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const getSwapRequests = createAsyncThunk("/txn/requests", async ({ user }: any) => {
    try {
        // console.log('id log ====>', user)
        const response = await API.get("/shift-swap-transaction/requests", {
            params: { user: user },
        });
        // console.log('api call result: requests ====>', response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const confirmRequests = createAsyncThunk("/txn/confirm", async (data: any) => {
    try {
        // console.log('id log ====>', data)
        const response = await API.post("/shift-swap-transaction/txns/confirm", {
            request: data,
        });
        // console.log('api call result: confirm ====>', response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const revertAction = createAsyncThunk("/txn/revert", async (data: any) => {
    try {
        // console.log('revert data log ====>', data)
        const response = await API.post("/shift-swap-transaction/revert", {
            request: data,
        });
        // console.log('api call result: revert ====>', response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});
