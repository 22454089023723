import React from "react";
import Header from "../../components/Header";
import { Outlet } from "react-router-dom";
import { Box, useTheme } from "@mui/material";

export const MainContainer = () => {
  const theme = useTheme();

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Header />
      <Box
        sx={{
          position: "relative",
          top: "65px",
          height: `calc(100vh - 65px)`,
          overflow: "hidden",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
