import React from "react";
import { useSelector } from "react-redux";
import { PayperiodFilterType, PayperiodType } from "../../types";
import { useAppDispatch } from "../../redux/store";
import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    MenuItem,
    Popover,
    Select,
    Typography,
    TextField,
} from "@mui/material";
import { setPayperiod, setPayperiodFilter } from "../../redux/features/payperiod";
import { getPayperiods, getPayperiod } from "../../redux/features/payperiod";
import { ArrowBack, ArrowForward, CalendarToday as CalendarTodayIcon } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { setAllowRefetch } from "../../redux/features/timesheetv2/slice";

type DateTimeFilterProps = {
    showRangeSelector?: boolean;
    showGoTo?: boolean;
};

const DateTimeFilter: React.FC<DateTimeFilterProps> = ({ showGoTo = true, showRangeSelector = true }) => {
    const dispatch = useAppDispatch();
    const payperiods = useSelector((state: any) => state.payperiod.payperiods) as PayperiodType[];
    const payperiodFilters = useSelector((state: any) => state.payperiod.payperiodFilters) as PayperiodFilterType[];
    const [selectedPeriod, setSelectedPeriod] = useState<PayperiodType | undefined>(undefined);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [filterRange, setFilterRange] = useState<string>("");
    const allowRefetch = useSelector((state: any) => state.timesheetV2.allowRefetch);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleAnchorElOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAnchorElClose = () => {
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? "simple-popover" : undefined;

    // 1. Fetch pay periods on component mount
    useEffect(() => {
        if(allowRefetch) {
            dispatch(getPayperiods({}));
        }
    }, [dispatch]);

    // 2. Set the first available pay period as the default selected period once pay periods are loaded
    useEffect(() => {
        if (payperiods.length > 0) {
            setSelectedPeriod(payperiods[0]); // Set the first pay period as the default
        }
    }, [payperiods]);

    // 3. Set the first available pay period filter as the default range once filters are loaded
    useEffect(() => {
        if (payperiodFilters.length > 0) {
            setFilterRange(`${payperiodFilters[0].start_date_ui} - ${payperiodFilters[0].end_date_ui}`);
            dispatch(setPayperiodFilter(payperiodFilters[0])); // Set the default filter range
        }
    }, [payperiodFilters, dispatch]);

    // 4. Fetch detailed data for the selected pay period whenever it changes
    // This ensures that detailed information is loaded for the currently selected pay period
    useEffect(() => {
        if (selectedPeriod) {
            dispatch(getPayperiod({ payperiod_id: selectedPeriod.company_id }));
        }
    }, [selectedPeriod, dispatch]);

    const handlePayperiodFilterChange = (event: any) => {
        console.log(event.target.value);
    };

    // payperiod change handler
    const handlePeriodChange = (event: any) => {
        const selected = payperiods.find((period) => period.company_id === event.target.value);
        setSelectedPeriod(selected);
        if (selected) {
            dispatch(setPayperiod(selected));
        }
    };

    // set filter range
    const handleSetFilterRange = (period: PayperiodFilterType) => {
        setFilterRange(`${period.start_date_ui} - ${period.end_date_ui}`);
        dispatch(setPayperiodFilter(period));
        dispatch(setAllowRefetch(true));
        handleAnchorElClose();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                    // padding: 2,
                    marginTop: 2,
                    marginBottom: 2,
                    overflow: "auto",
                }}
            >
                {/* payperiods dropdown */}
                <Box sx={{ minWidth: 100, maxWidth: 150, flexShrink: 0, marginRight: 1 }}>
                    <Select
                        value={selectedPeriod?.company_id || ""}
                        onChange={handlePeriodChange}
                        size="small"
                        sx={{ marginRight: 2, minWidth: 100 }}
                    >
                        {payperiods.map((period: PayperiodType, index: number) => (
                            <MenuItem key={index} value={period.company_id}>
                                {period.company_name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box
                    display={"flex"}
                    alignItems={"center"}
                    sx={{
                        border: 1,
                        borderColor: "grey.500",
                        borderRadius: 1,
                        flexShrink: 0,
                        overflow: "auto",
                        whiteSpace: "nowrap",
                    }}
                >
                    <IconButton size={"small"}>
                        <ArrowBack />
                    </IconButton>

                    <Button
                        aria-describedby={popoverId}
                        onClick={handleAnchorElOpen}
                        sx={{ minWidth: 100, maxWidth: 150, color: "black" }}
                    >
                        {filterRange ? filterRange : "Select Range"}
                    </Button>

                    <IconButton size={"small"}>
                        <ArrowForward />
                    </IconButton>

                    {/* POPOVER */}
                    <Popover
                        id={popoverId}
                        open={popoverOpen}
                        anchorEl={anchorEl}
                        onClose={handleAnchorElClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        {/* list of payperiod filters */}
                        <List>
                            {payperiodFilters.map((period: PayperiodFilterType, index: number) => (
                                <ListItem
                                    key={index}
                                    onClick={() => {
                                        handleSetFilterRange(period);
                                        handleAnchorElClose();
                                    }}
                                    sx={{
                                        fontSize: 14,
                                    }}
                                >
                                    {period.start_date_ui} - {period.end_date_ui}
                                </ListItem>
                            ))}
                        </List>

                        {/* Range selector */}
                        {showRangeSelector && (
                            <>
                                <Divider />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                        width: 300,
                                        margin: "auto",
                                        padding: 2,
                                    }}
                                >
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                        <DatePicker
                                            label="Start Date"
                                            value={startDate}
                                            onChange={(newValue: any) => setStartDate(newValue)}
                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        endAdornment: (
                                                            <IconButton>
                                                                <CalendarTodayIcon />
                                                            </IconButton>
                                                        ),
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                        <DatePicker
                                            label="End Date"
                                            value={endDate}
                                            onChange={(newValue) => setEndDate(newValue)}
                                            slotProps={{
                                                textField: {
                                                    InputProps: {
                                                        endAdornment: (
                                                            <IconButton>
                                                                <CalendarTodayIcon />
                                                            </IconButton>
                                                        ),
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Button
                                        variant="text"
                                        sx={{ marginTop: 2, backgroundColor: "skyblue", color: "white" }}
                                        onClick={handleAnchorElClose}
                                    >
                                        Ok
                                    </Button>
                                </Box>
                            </>
                        )}

                        {/* GOTO Section */}
                        {showGoTo && (
                            <>
                                <Divider />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 2,
                                        width: 300,
                                        margin: "auto",
                                        padding: 2,
                                    }}
                                >
                                    <DatePicker
                                        label="Go To"
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue)}
                                        slotProps={{
                                            textField: {
                                                InputProps: {
                                                    endAdornment: (
                                                        <IconButton>
                                                            <CalendarTodayIcon />
                                                        </IconButton>
                                                    ),
                                                },
                                            },
                                        }}
                                    />
                                    <Button
                                        variant="text"
                                        sx={{ backgroundColor: "skyblue", color: "white", height: "fit-content" }}
                                        onClick={handleAnchorElClose}
                                    >
                                        Ok
                                    </Button>
                                </Box>
                            </>
                        )}
                        {/* END POPOVER */}
                    </Popover>
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default DateTimeFilter;
