import { ThunkDispatch } from "@reduxjs/toolkit";
import { ActorProfile, ActorProfile2, Breaktime, TimesheetType } from "../types";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../redux/store";

export function calculateTotalWorkDurationV2(timesheet: TimesheetType): string {
    let totalDuration = 0;

    if (timesheet.start_datetime && timesheet.end_datetime) {
        const start = new Date(timesheet.start_datetime).getTime();
        const end = new Date(timesheet.end_datetime).getTime();

        if (!isNaN(start) && !isNaN(end) && end >= start) {
            totalDuration += end - start;
        }
    }

    const hours = Math.floor(totalDuration / (1000 * 60 * 60));
    const minutes = Math.floor((totalDuration % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}h ${minutes}m`;
}

export const calculateBreakTimeV2 = (timesheet: TimesheetType): string => {
    // let totalBreakTime = 0;
    let totalBreakTimeInMinutes = 0;
    // if (timesheet.breaktimes_json) {
    //     console.log("timesheet.breaktimes_json", timesheet.breaktimes_json);
    //     // const breaktimes = JSON.parse(timesheet.breaktimes_json);
    //     const breaktimes = timesheet.breaktimes_json?.breaktimes;
    //     console.log("breaktimes", breaktimes);
    // }
    // const hours = Math.floor(totalBreakTime / (1000 * 60 * 60));
    // const minutes = Math.floor((totalBreakTime % (1000 * 60 * 60)) / (1000 * 60));

    // return `${hours}h ${minutes}m`;
    if (timesheet.breaktimes_json) {
        console.log("timesheet.breaktimes_json", timesheet.breaktimes_json);
        const breaktimes = timesheet.breaktimes_json?.breaktimes;
        console.log("breaktimes", breaktimes);

        // Assuming breaktimes is an array of break time durations in milliseconds
        if (Array.isArray(breaktimes)) {
            breaktimes.forEach((breaktime: Breaktime) => {
                // Convert each break time from milliseconds to minutes
                totalBreakTimeInMinutes += Math.floor(breaktime.hours || 0 / (1000 * 60));
            });
        }
    }

    return `${totalBreakTimeInMinutes}m`;
};

export const FetchMatchingEmployee = (
    employeeAccountId?: number,
    employees?: ActorProfile2[]
): ActorProfile2 | undefined => {
    return employees?.find((employee) => employee.id == employeeAccountId);
};
