import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../axios/API";
import { axiosClient } from "../../../axios";

export const getCategories = createAsyncThunk("/categories", async (undefined, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
        const response = await API.get("/notification/category");
        console.log("response", response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
        return rejectWithValue(error);
    }
});

export const getInbox = createAsyncThunk(
    "/inbox",
    async (data: { dateRange?: string; category?: string[] }, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        console.log("data", data);
        try {
            const response = await axiosClient.post({url: "/notification/inbox", data});
            // console.log("response", response.data);
            return response;
        } catch (error) {
            console.log("error ===>", error);
            return rejectWithValue(error);
        }
    }
);

export const getInboxDetails = createAsyncThunk(
    "/inbox/details",
    async (data: { notificationId: number }, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;

        try {
            const response = await API.get(`/notification/inbox/details`, {
                params: data,
            });
            console.log("response", response.data);
            return response.data;
        } catch (error) {
            console.log("error ===>", error);
            return rejectWithValue(error);
        }
    }
);
