import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationState, NotificationType } from "../../../types";
import { FULFILLED, PENDING, REJECTED } from "../../constants";
import { getCategories, getInbox, getInboxDetails } from "./api";
import { NotificationCategoryEnum } from "../../../enums";

const initialState: NotificationState = {
    requestState: FULFILLED,
    notificationInboxDetailState: FULFILLED,
    manageRequests: [],
    myRequests: [],
    pickups: [],
    trades: [],
    allNotifications: [],
    categories: [],
    selectedNoticationIndexDetail: undefined,
};

export const NotificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        resetSelectedNotificationIndexDetail: (state: NotificationState, action: PayloadAction<null>) => {
            state.selectedNoticationIndexDetail = undefined;
        },
    },
    extraReducers: {
        /** Get categories */
        [getCategories.pending.type]: (state) => {
            state.requestState = PENDING;
        },

        [getCategories.fulfilled.type]: (state, action) => {
            state.requestState = FULFILLED;
            state.categories = action.payload;
        },

        [getCategories.rejected.type]: (state) => {
            state.requestState = REJECTED;
        },

        /** Get inbox */
        [getInbox.pending.type]: (state) => {
            state.requestState = PENDING;
        },

        [getInbox.fulfilled.type]: (state, action) => {
            state.requestState = FULFILLED;
            console.log('*** Notifications fetched', action.payload.data);
            // Use reduce to create two separate lists: trades and the rest of the notifications
            const [trades, remainingNotifications] = (action.payload.data as NotificationType[]).reduce<
                [NotificationType[], NotificationType[]]
            >(
                (acc, notification) => {
                    if (
                        notification.notificationCategory !== undefined &&
                        notification.notificationCategory == NotificationCategoryEnum.EMP_SHIFT_SWAPPING
                    ) {
                        acc[0].push(notification); // Add to trades
                    } else {
                        acc[1].push(notification); // Add to remainingNotifications
                    }
                    return acc;
                },
                [[], []]
            );

            state.allNotifications = remainingNotifications;
            state.trades = trades;

            console.log(`*** Notifications fetched for ${NotificationCategoryEnum.EMP_SHIFT_SWAPPING}`, state.trades);
        },

        [getInbox.rejected.type]: (state) => {
            state.requestState = REJECTED;
        },

        /** Get Inbox Detail */
        [getInboxDetails.pending.type]: (state) => {
            state.notificationInboxDetailState = PENDING;
        },

        [getInboxDetails.fulfilled.type]: (state, action) => {
            state.notificationInboxDetailState = FULFILLED;
            state.selectedNoticationIndexDetail = action.payload;
            console.log("state.selectedNoticationIndexDetail", state.selectedNoticationIndexDetail);
        },

        [getInboxDetails.rejected.type]: (state) => {
            state.notificationInboxDetailState = REJECTED;
        },
    },
});

export const { resetSelectedNotificationIndexDetail } = NotificationSlice.actions;
export default NotificationSlice.reducer;
