import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    IconButton,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarToday as CalendarTodayIcon } from "@mui/icons-material";
import InitialTradeForm from "./InitialTradeForm";
import SelectionStep from "./SelectionForm";
import SuccessStep from "./SuccessForm";
import { RootState, useAppDispatch } from "../../../redux/store";
import { setFilterEndDate, setFilterStartDate, setSwapNote } from "../../../redux/features/mySchedule/ScheduleSlice";
import { useSelector } from "react-redux";
import { SwappableEmployee } from "../../../types/state/swap";
import { createSwapRequest, getSwapEmployees } from "../../../redux/features/mySchedule/ScheduleApi";
import { ShiftType } from "../../../types";
import useGetAllSwappableEmployees from "../../../hooks/useGetAllSwappableEmployees";
import { IUser } from "../../../redux/features/user/models/user";

const TradeShift = () => {
    const dispatch = useAppDispatch();
    // Separate state for start and end dates
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const fmShift = useSelector((state: any) => state.mySchedule.selectedShift) as ShiftType;
    const selectedEmployees = useSelector(
        (state: any) => state.mySchedule.selectedSwapEmployees
    ) as SwappableEmployee[];
    const [note, setNote] = useState("");
    const [step, setStep] = useState(1);
    const filterStartDate = useSelector((state: RootState) => state.mySchedule.swapFilterStartDate);
    const filterEndDate = useSelector((state: RootState) => state.mySchedule.swapFilterEndDate);
    const getEmployeeFetchStatus = useSelector((state: any) => state.mySchedule.requestState);
    const [oldSearchContents, setOldSearchContents] = useState("");
    const User = useSelector((state: any) => state.user.user) as IUser;

    const handleContinue = () => {
        dispatch(setSwapNote(note));
        setStep(2);
    };

    useEffect(() => {
        setStartDate(filterStartDate ? new Date(filterStartDate) : null);
        setEndDate(filterEndDate ? new Date(filterEndDate) : null);
    }, [filterStartDate, filterEndDate]);

    const handleNameSearchChange = (value: string) => {
        console.log("TradeShift: handleNameSearchChange");
        setOldSearchContents(value);
        const payload = {
            request_type: 1,
            start_date: startDate ? startDate.toISOString() : new Date().toISOString(),
            end_date: endDate ? endDate.toISOString() : new Date(new Date().getFullYear(), 11, 31).toISOString(),
            assigned_shift_code: fmShift.osShiftCode || "",
            department_code: User.departmentCode || "",
            group_code: [],
            name_search: "",
        };

        if (value.length > 3 && getEmployeeFetchStatus === "FULFILLED" && value !== oldSearchContents) {
            payload.name_search = value;
        }

        if (value.length === 0 || value.length > 3) {
            dispatch(getSwapEmployees(payload));
        }

        // dispatch(getSwapEmployees(payload));
    };

    const handleSuccess = () => {
        console.log("TradeShift: handleSuccess");
        console.log("fmshift ====>", fmShift);
        console.log("selectedEmployees ====>", selectedEmployees);
        console.log("note ====>", note);

        const payload = {
            scheduleId: fmShift.id!,
            employeeIds: selectedEmployees.map((employee) => employee.osEmpAccountId!.toString()),
            scheduleIds: selectedEmployees.map((employee) => employee.id!.toString()),
            notes: note,
            requestType: 1,
        };
        dispatch(createSwapRequest(payload));
        setStep(3);
    };

    const handleStartDateChange = (date: Date | null) => {
        if (date) {
            dispatch(setFilterStartDate(date.toISOString()));
        }
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        if (date) {
            dispatch(setFilterEndDate(date.toISOString()));
        }
        setEndDate(date);
    };

    const handleSearch = () => {
        // If no start date, default to January 1st of the current year
        const defaultStartDate = new Date(new Date().getFullYear(), 0, 1); // January 1st of the current year

        // If no end date, default to December 31st of the current year
        const defaultEndDate = new Date(new Date().getFullYear(), 11, 31); // December 31st of the current year
        const payload = {
            request_type: 1,
            start_date: (startDate ? startDate : defaultStartDate).toISOString(),
            end_date: (endDate ? endDate : defaultEndDate).toISOString(),
            assigned_shift_code: fmShift.osShiftCode || "",
            department_code: User.departmentCode|| "",
            group_code: [],
            name_search: "",
        };

        if (startDate && endDate) {
            dispatch(getSwapEmployees(payload));
        }
    };

    return (
        <Box padding={2}>
            {step === 1 && <InitialTradeForm onContinue={handleContinue} setNote={setNote} />}
            {step === 2 && (
                <SelectionStep
                    startDate={startDate}
                    endDate={endDate}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    onSuccess={handleSuccess}
                    handleNameSearch={handleNameSearchChange}
                    onSearch={handleSearch}
                />
            )}
            {step === 3 && <SuccessStep />}
        </Box>
    );
};

export default TradeShift;
