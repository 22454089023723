import { PhpRoute } from "../enums";

export const providePhpRoute = (route: PhpRoute) => {
    const env = process.env.REACT_APP_ENV === "prod" ? "prod" : "dev";
    const baseUrls = {
        dev: "https://dev2.somroli.com/app17",
        prod: "https://app.webapph.com/app17",
    };

    const routes = {
        [PhpRoute.HOME]: "/",
        [PhpRoute.PROFILE]: "/profile",
        [PhpRoute.LOGOUT]: "/user/logout",
    };

    const baseUrl = baseUrls[env];
    const endpoint = routes[route];

    const url = `${baseUrl}${endpoint}`;
    console.log("url", url);
    return url;
};
