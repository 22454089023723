import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import {
    SHIFT_STATUS,
    SCHEDULE_DATE_FORMAT,
    SHIFT_ASSIGNED,
    REQUEST_REJECTED,
    PENDING_FOR_TRADE,
    PENDING_FOR_RELEASE,
    PENDING_FOR_DROP,
    TRADE,
    RELEASE,
} from "./constants";
import { ShiftType } from "../types";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getEventDate = (date: string) => {
    if (!date) {
        date = new Date().toISOString();
        dayjs(date).utc().format(SCHEDULE_DATE_FORMAT);
    }

    return dayjs(date).utc().format(SCHEDULE_DATE_FORMAT);
};

export const formatShift = (shift: ShiftType | any) => {
    const user = shift.osEmployeeProfile;

    if (!user) {
        return null;
    }

    console.log("shift", shift);
    const fullName =
        toCapital(user.osEmpFirstName || user.os_emp_first_name) +
        " " +
        toCapital(user.osEmpLastName || user.os_emp_last_name); //convert!!!
    const date = dayjs(shift.osEmpStartDateTime || shift.start)
        .utc()
        .format("MMM DD YYYY");
    const time =
        dayjs(shift.osEmpStartDateTime || shift.start)
            .utc()
            .format("h:mma") +
        " - " +
        dayjs(shift.osEmpEndDateTime || shift.end)
            .utc()
            .format("h:mma");
    const location = shift.geoLocation ? shift.geoLocation : "Global";
    const title = shift.title;
    const now = dayjs(new Date());
    const isPast = now < dayjs(shift.osEmpStartDateTime || shift.start);
    const status = shift.osScheduleShiftStatus || shift.os_schedule_shift_status;
    const isActionable = status === SHIFT_ASSIGNED || status === REQUEST_REJECTED;

    // if os_schedule_shift_status is 1(Trade), 2(Release), or 3(Drop) then canRevert will be true
    // const canRevert = (shift as ShiftType).canRevert;
    // const tradeInitiated = (shift as ShiftType).tradeInitiated
    
    const canRevert = ValidateCanRevert(shift);
    const pending = canRevert;
    const osEmpImageUrl = user.osEmpImageName === null ? user.os_emp_image_name || "" : user.osEmpImageName;
    const site = shift.usUserSiteProfile?.userSiteName;
    const jobcode = shift.osWorkCode?.workCodeName;
    const notes = shift.logs;
    const customFields = shift.shiftTagsJson || shift.shift_tags_json;
    const department = shift.departmentCode;

    return {
        id: shift.id,
        fullName: fullName,
        osEmpImageUrl: osEmpImageUrl,
        date: date,
        time: time,
        site: site,
        department: department,
        jobcode: jobcode,
        notes: notes,
        customFields: customFields,
        location: location,
        title: title,
        checked: false,
        status: shift.osScheduleShiftStatus,
        actionable: isPast && isActionable,
        canRevert: canRevert,
        pending: pending,
        label: SHIFT_STATUS[shift.osScheduleShiftStatus] ? SHIFT_STATUS[shift.osScheduleShiftStatus].label : "Closed",
    };
};

export const ValidateCanRevert = (shift: ShiftType): boolean => {
    return false; // PATCH: disable reverting action for trade/release/drop
    return ( 
        shift.osScheduleSwapTransactionId != null &&
        shift.osScheduleSwapTransactionId != 0 &&
        (shift.osScheduleShiftStatus === PENDING_FOR_TRADE ||
            shift.osScheduleShiftStatus === PENDING_FOR_RELEASE ||
            shift.osScheduleShiftStatus === PENDING_FOR_DROP)
    );
};

export const formatEmp = (emps: any[]) => {
    let result: any[] = [];

    result = emps.map((emp: any) => {
        let user = emp;
        let osEmpImageUrl = user.osEmpImageName;

        return {
            id: emp.id,
            osEmpImageUrl: osEmpImageUrl,
            fullName: toCapital(user.osEmpFirstName) + " " + toCapital(user.osEmpLastName),
            checked: false,
        };
    });

    return result;
};

export const formatShiftDate = (shiftDate: any) => {
    if (!shiftDate) return "-----";

    let startDate = shiftDate.startDateTime;
    let endDate = shiftDate.endDateTime;

    return dayjs(startDate).utc().format("h:mma") + " - " + dayjs(endDate).utc().format("h:mma");
};

export const fixTimezone = (stringDate: string) => {
    // Create a date object with the specified time and timezone offset
    let date = new Date(stringDate);
    const timeZoneOffset = new Date(date).getTimezoneOffset();

    // Get the timezone offset of your computer in minutes
    const computerTimeZoneOffset = new Date().getTimezoneOffset();

    // Calculate the difference between the target timezone and your computer's timezone
    const timeZoneDiffInMinutes = computerTimeZoneOffset - timeZoneOffset;

    // Adjust the time based on the timezone difference
    date.setMinutes(date.getMinutes() + timeZoneDiffInMinutes);

    return date;
};

export const formatContent = (data: any, type: number, userId: number) => {
    if (type === TRADE) {
        let employee = data.osEmployeeProfile;
        return {
            id: data.id,
            userId: userId,
            fullName: toCapital(employee.osEmpFirstName) + " " + toCapital(employee.osEmpLastName),
            osEmpImageUrl: employee.osEmpImageName,
            month: dayjs(data.osEmpStartDateTime).utc().format("MMM"),
            date: dayjs(data.osEmpStartDateTime).utc().format("D"),
            time:
                dayjs(data.osEmpStartDateTime).utc().format("h:mma") +
                " - " +
                dayjs(data.osEmpEndDateTime).utc().format("h:mma"),
            role: "Engineer",
            location: "Global",
            checked: false,
        };
    } else if (type === RELEASE) {
        let employee = data;
        return {
            id: data.id,
            fullName: toCapital(data.osEmpFirstName) + " " + toCapital(data.osEmpLastName),
            osEmpImageUrl: employee.osEmpImageName,
            role: "Engineer",
            location: "Global",
            checked: false,
        };
    }
};

export const toCapital = (str: string) => {
    if (str === "") {
        return "";
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getCertainDate = (offset: number) => {
    let date = dayjs(new Date()).utc();
    return date.hour(0).minute(0).add(offset, "day");
};

export const getWeekFromDate = (date: any) => {
    let curDay = dayjs(date).day();
    let monday = dayjs(date)
        .utc()
        .hour(0)
        .minute(0)
        .second(0)
        .add(-curDay + 1, "day");
    let sunday = dayjs(date)
        .utc()
        .hour(0)
        .minute(0)
        .second(0)
        .add(7 - curDay, "day");

    return {
        monday: monday,
        sunday: sunday,
    };
};

export const createCIURL = (version: number, path: string) => {
    /**
     * @todo
     * can access state variable here?
     */
    if (process.env.REACT_APP_ENV) {
        console.log(">>>>>process.env.REACT_APP_CI_SERVER_URL", process.env.REACT_APP_CI_SERVER_URL);
        return process.env.REACT_APP_CI_SERVER_URL + "/CI" + path;
    } else {
        return process.env.REACT_APP_CI_SERVER_URL + "/app" + version + path;
    }
};

export const createReactURL = (path: string) => {
    /**
     * @todo
     * can access state variable here?
     */
    return process.env.FRONTEND_URL + path;
};
