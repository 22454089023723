import React, { useEffect } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { ShiftType } from "../../../types";
import { useAppDispatch } from "../../../redux/store";
import { getSwapEmployees } from "../../../redux/features/mySchedule/ScheduleApi";
import useGetAllSwappableEmployees from "../../../hooks/useGetAllSwappableEmployees";
import { setFilterStartDate, setFilterEndDate } from "../../../redux/features/mySchedule/ScheduleSlice";
import { DateTimeUtil } from "../../../utils";
import { IUser } from "../../../redux/features/user/models/user";

const TradeShiftInitialStep: React.FC<{ onContinue: () => void, setNote: (text: string) => void }> = ({ onContinue, setNote }) => {
    const selectedShift = useSelector((state: any) => state.mySchedule.selectedShift) as ShiftType;
    const dispatch = useAppDispatch();
    const User = useSelector((state: any) => state.user.user) as IUser;

    useEffect(() => {
        console.log("selectedShift", selectedShift);
        
        const startDate = DateTimeUtil.ParseWithDayjsUtc(selectedShift.osEmpStartDateTime!);
        const endDate = new Date(selectedShift.osEmpStartDateTime!);
        endDate.setDate(endDate.getDate() + 7);
        dispatch(setFilterStartDate(startDate.toISOString()));
        dispatch(setFilterEndDate(endDate.toISOString()));
        // const now  = DateTimeUtil.formatDateToMySQL(new Date());
        const payload = {
            request_type: 1,
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            assigned_shift_code: selectedShift.osShiftCode || "",
            department_code: User.departmentCode || "",
            group_code: [],
            name_search: ""
        };

        console.log("payload", payload);
        dispatch(getSwapEmployees(payload));
    }, []);
    
    return (
        <Box>
            <Box
                sx={{
                    // border: "1px solid rgba(0, 0, 0, 0.12)",
                    // padding: 2,
                    marginTop: 1,
                    backgroundColor: "#ffffff",
                }}
            >
                <Box
                    sx={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        padding: 1,
                        backgroundColor: "#f9f9f9",
                        marginBottom: 2,
                    }}
                >
                    <Typography variant="body2" gutterBottom>
                        {/* {selectedShift.osEmpStartDateTime} - {selectedShift.osEmpEndDateTime} */}
                        {DateTimeUtil.formatDateTimeRangeUtc(selectedShift.osEmpStartDateTime!, selectedShift.osEmpEndDateTime!)}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {selectedShift.osEmployeeProfile?.osEmpFirstName} {selectedShift.osEmployeeProfile?.osEmpLastName}
                    </Typography>
                </Box>
                <Typography variant="body2" gutterBottom>
                    1. You will be asked to enter a reason for requesting the trade and to select possible shifts you'd
                    like to trade for.
                </Typography>
                <Typography variant="body2" gutterBottom>
                    2. Management will either approve or reject your request. If approved, the employee you've requested
                    a trade with will be notified.
                </Typography>
                <Typography variant="body2" gutterBottom>
                    3. If that employee accepts your shift, management will confirm and complete the trade.
                </Typography>
                <TextField fullWidth variant="outlined" margin="normal" placeholder="Reason for request" size="small"
                    onChange={(e)=> setNote(e.target.value)}
                />
                <Box textAlign="right" marginTop={2}>
                    <Button variant="contained" color="primary" size="small" onClick={onContinue}>
                        Continue
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default TradeShiftInitialStep;
