export const GetEmployeeShiftSwapStatusText = (status?: number) => {
    switch (status) {
        case 0:
            return "Pending for trade";
        case 1:
            return "Processing";
        case 2:
            return "Completed";
        case 3:
            return "Rejected";
        default:
            return "Pending for trade";
    }
}