import { Box } from "@mui/material";
import { ManagerContainer, EmployeeContainer } from "../../components/timecard";

const TimecardV2: React.FC = () => {
    return (
        <Box style={{ height: "100vh", overflow: "auto" }}>
            {/* <ManagerContainer /> */}
            <EmployeeContainer/>
        </Box>
    );
};
 
export default TimecardV2;
