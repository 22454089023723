// src/fakeData.ts
import { faker } from '@faker-js/faker';

export interface EmployeeDetail {
  name: string;
  date: string;
  data: Array<{
    record_type: string;
    earning_code_name: string;
    os_emp_start_datetime?: string;
    os_emp_end_datetime?: string;
    hours: string;
    os_emp_schedules_id: string;
  }>;
}

export interface Total {
  overall: {
    value_formatted: string;
  };
}

export const generateFakeEmployeeData = (): EmployeeDetail[] => {
  return Array.from({ length: 10 }, () => ({
    name: faker.name.fullName(),
    date: faker.date.recent().toISOString(),
    data: Array.from({ length: 2 }, () => ({
      record_type: faker.helpers.arrayElement(['T', '']),
      earning_code_name: faker.word.noun(),
      os_emp_start_datetime: faker.date.recent().toISOString(),
      os_emp_end_datetime: faker.date.recent().toISOString(),
      hours: faker.number.float({ min: 0, max: 8, multipleOf: 0.01 }).toFixed(2),
      os_emp_schedules_id: faker.string.uuid(),
    })),
  }));
};

export const total: Total = {
  overall: {
    value_formatted: '08h01m',
  },
};

// New interfaces and functions for employeeContainer

export interface PayPeriodDetail {
  payPeriod: string;
  regularHours: string;
  overtimeHours: string;
  doubletimeHours: string;
}

export const generateFakePayPeriodData = (): PayPeriodDetail[] => {
  return Array.from({ length: 10 }, () => ({
    payPeriod: `${faker.date.past().toLocaleDateString()} - ${faker.date.future().toLocaleDateString()}`,
    regularHours: faker.number.float({ min: 0, max: 40, multipleOf: 0.01 }).toFixed(2),
    overtimeHours: faker.number.float({ min: 0, max: 10, multipleOf: 0.01 }).toFixed(2),
    doubletimeHours: faker.number.float({ min: 0, max: 5, multipleOf: 0.01 }).toFixed(2),
  }));
};

// New interfaces and functions for records

export interface RecordDetail {
  date: string;
  startTime: string;
  endTime: string;
  duration: string;
}

export const generateFakeRecordsData = (): RecordDetail[] => {
  return Array.from({ length: 10 }, () => ({
    date: faker.date.recent().toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' }),
    startTime: faker.date.recent().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    endTime: faker.date.recent().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    duration: `${faker.number.int({ min: 0, max: 2 })}h ${faker.number.int({ min: 0, max: 59 })}m`,
  }));
};
