import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PayperiodState } from "../../../types/state";
import { getPayperiods, getPayperiod } from "./api";
import { FULFILLED, PENDING, REJECTED } from "../../constants";
import { PayperiodFilterType, PayperiodType } from "../../../types";

const initialState: PayperiodState = {
    payperiods: [],
    payperiodFilters: [],
    requestStatus: FULFILLED,
    selectedPayperiodFilter: undefined,
    selectedPayperiod: undefined,
};

export const PayperiodSlice = createSlice({
    name: "payperiod",
    initialState: initialState,
    reducers: {
        setPayperiodFilter: (state: PayperiodState, action: PayloadAction<PayperiodFilterType>) => {
            state.selectedPayperiodFilter = action.payload;
        },
        setPayperiod: (state: PayperiodState, action: PayloadAction<PayperiodType>) => {
            state.selectedPayperiod = action.payload;
        },

        setPreviousSelectedPayperiodFilter: (state: PayperiodState, action: PayloadAction<PayperiodFilterType>) => {
            state.previousSelectedPayperiodFilter = action.payload;
        },
    },
    extraReducers: {
        /** get pay periods */
        [getPayperiods.pending.type]: (state) => {
            state.payperiods = [];
            state.requestStatus = PENDING;
        },

        [getPayperiods.fulfilled.type]: (state, { payload }) => {
            if (payload.data) {
                state.payperiods = payload.data;
                console.log("payperiods", state.payperiods);
                if (state.payperiods.length > 0) {
                    console.log("payperiods first", state.payperiods[0]);
                    state.selectedPayperiod = state.payperiods[0];
                }
            }
            state.requestStatus = FULFILLED;
        },

        [getPayperiods.rejected.type]: (state) => {
            state.payperiods = [];
            state.requestStatus = REJECTED;
        },

        /** get pay period filters */
        [getPayperiod.pending.type]: (state) => {
            state.payperiodFilters = [];
            state.requestStatus = PENDING;
        },

        [getPayperiod.fulfilled.type]: (state, { payload }) => {
            if (payload.data) {
                state.payperiodFilters = payload.data;
            }
            state.requestStatus = FULFILLED;
        },

        [getPayperiod.rejected.type]: (state) => {
            state.payperiodFilters = [];
            state.requestStatus = REJECTED;
        },
    },
});

export const { setPayperiod, setPayperiodFilter, setPreviousSelectedPayperiodFilter } = PayperiodSlice.actions;
export default PayperiodSlice.reducer;
