import React, { useEffect, useState } from "react";
import { AppBar, Tabs, Tab, Box, Typography, Button, useMediaQuery, Theme } from "@mui/material";
import { Wrapper, OuterBox, TabContent, AppBarStyled, RecordsScrollable, FixedSummary } from "./styles";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { getSummary, getTimeSheets } from "../../redux/features/timesheetv2";
import DateTimeFilter from "../dateTimeDisaplay/DateTimeFilter";
import { PayperiodFilterType, PayperiodType, TimesheetGroupedByDate, TimesheetType } from "../../types";
import RecordsComponent from "./recordsComponent";
import SummaryComponent from "./summaryComponent";
import { setAllowRefetch } from "../../redux/features/timesheetv2/slice";
import { PullToRefresh } from "../ux";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

const EmployeeContainer: React.FC = () => {
    const [value, setValue] = useState(0);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const dispatch = useAppDispatch();
    const user = useSelector((state: any) => state.user.user);
    const selectedPayperiod = useSelector((state: any) => state.payperiod.selectedPayperiod) as PayperiodType;
    const selectedPayperiodFilter = useSelector(
        (state: any) => state.payperiod.selectedPayperiodFilter
    ) as PayperiodFilterType;
    const previousSelectedPayperiodFilter = useSelector(
        (state: any) => state.payperiod.previousSelectedPayperiodFilter
    ) as PayperiodFilterType;
    const timesheetsGroupedByDate = useSelector(
        (state: any) => state.timesheetV2.timesheetGroupedByDate
    ) as TimesheetGroupedByDate;
    const timesheetSummary = useSelector((state: any) => state.timesheetV2.timesheetSummary);
    const timesheets = useSelector((state: any) => state.timesheetV2.timesheets) as TimesheetType[];
    const allowRefetch = useSelector((state: any) => state.timesheetV2.allowRefetch) as boolean;
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const fetchData = () => {
        if (allowRefetch) {
            if (!selectedPayperiodFilter) return;

            const payloadTime = `${selectedPayperiodFilter?.start_datetime} - ${selectedPayperiodFilter?.end_datetime}`;
            const getTimsheetsBody = {
                employeeId: user[0].os_emp_account_id,
                payPeriod: payloadTime,
                sortBy: "os_emp_start_datetime",
                sortOrder: "ASC",
                recordType: "A",
            };

            dispatch(getTimeSheets(getTimsheetsBody));

            const getSummaryBody = {
                employeeId: user[0].os_emp_account_id,
                payPeriod: payloadTime,
            };

            dispatch(getSummary(getSummaryBody));
            dispatch(setAllowRefetch(true));
        }
    };
    useEffect(() => {
        fetchData();
    }, [selectedPayperiodFilter]);

    const renderMobile = () => {
        return (
            <>
                <AppBarStyled position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="employee container tabs"
                        variant="fullWidth"
                        textColor="inherit"
                        indicatorColor="secondary"
                    >
                        <Tab label="Summary" {...a11yProps(0)} />
                        {timesheets && <Tab label="Records" {...a11yProps(1)} />}
                    </Tabs>
                </AppBarStyled>
                <TabPanel value={value} index={0}>
                    <TabContent>
                        <SummaryComponent timesheetSummary={timesheetSummary} />
                    </TabContent>
                </TabPanel>
                {timesheets && (
                    <TabPanel value={value} index={1}>
                        <TabContent>
                            <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                                <Box sx={{ display: "flex", mt: 1, flexDirection: "row-reverse" }}>
                                    <Typography variant="body1" color="primary">
                                        Add Record
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 1, flexGrow: 1 }}>
                                    <RecordsComponent
                                        // timesheetsGrouped={timesheetsGroupedByDate}
                                        timesheets={timesheets}
                                        isMobile={isMobile}
                                    />
                                </Box>
                            </Box>
                        </TabContent>
                    </TabPanel>
                )}
            </>
        );
    };

    const renderWeb = () => {
        return (
            <Box display="flex" flexDirection="column" height="100vh" width="100%">
                {timesheetsGroupedByDate && Object.keys(timesheetsGroupedByDate).length > 0 && (
                    <RecordsScrollable hasMoreThanFour={timesheetSummary.length > 4}>
                        <RecordsComponent timesheetsGrouped={timesheetsGroupedByDate} timesheets={timesheets} isMobile={isMobile}/>
                    </RecordsScrollable>
                )}
                <FixedSummary>
                    <SummaryComponent timesheetSummary={timesheetSummary} />
                </FixedSummary>
            </Box>
        );
    };

    const onRefresh = async () => {
        console.log("refreshing...");
        fetchData();
    };

    return (
        <Wrapper isMobile={isMobile}>
            <PullToRefresh onRefresh={onRefresh} threshold={2} 
            >
                <OuterBox>
                    <DateTimeFilter />
                    {isMobile ? renderMobile() : renderWeb()}
                </OuterBox>
            </PullToRefresh>
        </Wrapper>
        // <Wrapper>
        //     <PullToRefresh onRefresh={onRefresh} threshold={2} 
        //     >
        //         <OuterBox>
        //             <DateTimeFilter />
        //             {isMobile ? renderMobile() : renderWeb()}
        //         </OuterBox>
        //     </PullToRefresh>
        // </Wrapper>
    );
};

export default EmployeeContainer;
