import { useState, useEffect, useRef } from "react";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHouse } from '@fortawesome/free-solid-svg-icons';

import {
    EventClickArg,
    EventContentArg,
    DatesSetArg,
    CustomButtonInput,
    // ViewOptions
} from "@fullcalendar/core";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeline from "@fullcalendar/timeline";
import resourceTimeline from "@fullcalendar/resource-timeline";
import Box from "@mui/material/Box";
import { EventInput } from "@fullcalendar/core";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";

import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";

import { PENDING_FOR_DROP, PENDING_FOR_RELEASE, PENDING_FOR_TRADE, SHIFT_STATUS } from "../../utils/constants";

import { createReactURL, getEventDate } from "../../utils/helper";

import { getUsers } from "../../redux/features/user/UserApi";

import { toggleProcessing, toggleSidebar } from "../../redux/features/layout/LayoutSlice";

import { getScheduleData, getShiftsByDate, getSwapRequests } from "../../redux/features/mySchedule/ScheduleApi";

import { selectShift, setDateRange } from "../../redux/features/mySchedule/ScheduleSlice";
import { Button, Typography } from "@mui/material";
import { ShiftType } from "../../types";
import { D } from "@fullcalendar/resource/internal-common";
dayjs.extend(utc);

const Calendar = () => {
    const [shifts, setShifts] = useState([] as EventInput[]);
    const [notified, setNotified] = useState(false);
    const [resources, setResources] = useState([] as any[]);
    const rawShifts = useSelector((state: any) => state.mySchedule.shifts) as ShiftType[];
    const user = useSelector((state: any) => state.user.user);
    const dateRange = useSelector((state: any) => state.mySchedule.dateRange);
    const calendarRef = useRef(null);

    const dispatch = useAppDispatch();

    const customButtons: { [name: string]: CustomButtonInput } = {
        notificationButton: {
            text: "",
            icon: "fa fa fa-bell",
            click: () => {
                /**
                 * @todo
                 * call createReactURL()
                 */
                window.location.href = createReactURL("/notification/schedule-notification");
            },
        },
    };

    const customView = {
        weekWithoutTime: {
            type: "resourceTimelineWeek",
            slotDuration: { days: 1 },
            buttonText: "week",
        },
    };

    useEffect(() => {
        if (user.id !== -1) {
            let res = {
                id: user.id?.toString() ?? 0,
                title: user.firstName + " " + user.lastName,
            };
            setResources([res]);
        }

        if (parseInt(user.isSuperUser) === 1 && user.userAccountId !== -1) {
            dispatch(getUsers(user));
        }
    }, [user, dispatch]);

    useEffect(() => {
        let shiftsData = getShifts(rawShifts);
        // check if shift.start is today
        shiftsData = shiftsData.map((shift) => {
            if (dayjs(shift.start?.toString()).isSame(dayjs(), "day")) {
                // Modify the shift object if the start date is today
                // For example, you can add a new property like 'isToday' to the shift object
                return {
                    ...shift,
                    // color: "red",
                    color: "#009688"
                };
            } else {
                // Return the original shift object if the start date is not today
                return shift;
            }
        });

        setShifts(shiftsData);
    }, [dispatch, rawShifts]);

    useEffect(() => {
        const getRequests = async () => {
            try {
                if (user.id !== -1) {
                    if (dateRange !== null) {
                        const payload = {
                            startDatetime: dayjs(dateRange.start).format("YYYY-MM-DD HH:mm:ss"),
                            endDatetime: dayjs(dateRange.end).format("YYYY-MM-DD HH:mm:ss"),
                        };
                        // const today = new Date();
                        // today.setHours(0, 0, 0, 0);
                        // const aftermonth = new Date(today);
                        // aftermonth.setDate(today.getDate() + 31);
                        // aftermonth.setHours(23, 59, 59, 999);
                        // const payload = {
                        //     startDatetime: dayjs(today.toISOString()).format("YYYY-MM-DD HH:mm:ss"),
                        //     endDatetime: dayjs(aftermonth.toISOString()).format("YYYY-MM-DD HH:mm:ss"),
                        // };
                        console.log("datetime here", payload);
                        // console.log("payload", payload);
                        await dispatch(getShiftsByDate(payload));
                        // await dispatch(getScheduleData(payload));
                        dispatch(toggleProcessing(false));
                    }
                    if (!notified) {
                        dispatch(toggleProcessing(true));
                        await dispatch(getSwapRequests({ user }));
                        setNotified(true);
                        dispatch(toggleProcessing(false));
                    }
                }
            } catch (error) {
                dispatch(toggleProcessing(false));
                // Handle any errors here
            }
        };

        dispatch(toggleProcessing(true));
        getRequests();
    }, [dateRange, notified, user, dispatch]);

    const getShiftDataByRange = () => {
        if (dateRange !== null) {
            const payload = {
                startDatetime: dayjs(dateRange.start).format("YYYY-MM-DD HH:mm:ss"),
                endDatetime: dayjs(dateRange.end).format("YYYY-MM-DD HH:mm:ss"),
            };
            dispatch(getShiftsByDate(payload));
        }
    };

    // useEffect(() => {
    //     const getData = async () => {
    //         try {
    //             const payload = {
    //                 startDatetime: dayjs(dateRange.start).format("YYYY-MM-DD HH:mm:ss"),
    //                 endDatetime: dayjs(dateRange.end).format("YYYY-MM-DD HH:mm:ss"),
    //             };
    //             await dispatch(getShiftsByDate(payload));

    //             dispatch(toggleProcessing(false));
    //         } catch (error) {
    //             console.log("error", error);
    //             dispatch(toggleProcessing(false));
    //         }
    //     };

    //     dispatch(toggleProcessing(true));
    //     getData();
    // }, [dateRange, user, dispatch]);

    const getShifts = (rawShifts: ShiftType[]): EventInput[] => {
        var psShifts = rawShifts.map((shift) => {
            const user = shift.osEmployeeProfile;
            const status = shift.osScheduleShiftStatus;
            const pending =
                status === PENDING_FOR_TRADE || status === PENDING_FOR_RELEASE || status === PENDING_FOR_DROP;

            // console.log("shift", shift);
            // console.log("user", user);
            // console.log("status", status);
            if (user && shift.osEmpStartDateTime && shift.osEmpEndDateTime && shift.osEmpAccountId) {
                // console.log("shift here", JSON.stringify(shift, null, 2));
                return {
                    id: shift.id,
                    resourceId: shift.osEmpAccountId?.toString(),
                    title: shift.title ? shift.title : "Shift",
                    // start: getEventDate(shift.osEmpStartDateTime),
                    start: getEventDate(shift.osEmpStartDateTime),
                    status: status,
                    pending: pending,
                    actionable: dayjs(shift.osEmpStartDateTime) > dayjs(new Date()),
                    color: status != null && SHIFT_STATUS[status] ? SHIFT_STATUS[status].color : "#9e9e9e",
                };
            }
            return null;
        });
        console.log("psShifts", psShifts);

        return (psShifts as EventInput[]).filter((shift) => shift !== null) as EventInput[];
    };

    /** Shift tile */
    const renderEventContent = (eventContent: EventContentArg) => {
        const shift = getShiftById(eventContent.event.id);
        if (!shift) {
            return <></>;
        }

        const djStart = dayjs(shift.osEmpStartDateTime).utc();
        const djEnd = dayjs(shift.osEmpEndDateTime).utc();
        const startTime = djStart.format("h:mma");
        const endTime = djEnd.format("h:mma");
        const dur = djEnd.diff(djStart, "minutes");
        const hours = Math.floor(dur / 60);
        const minutes = dur % 60;
        const status = shift.osScheduleShiftStatus;

        return (
            <Box
                sx={{
                    display: "flex",
                    flexFlow: "column",
                    padding: 0.5,
                    position: "relative",
                    color:"black"
                }}
            >
                <Box>{eventContent.event.title}</Box>
                <Box>
                    {startTime} - {endTime}
                </Box>
                <Box>
                    {hours} hrs : {minutes} mins
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        top: 0,
                        right: 0,
                        padding: 0.1,
                    }}
                >
                    {/* <Box>{SHIFT_STATUS[status].icon}</Box> */}
                    <Typography>*</Typography>
                </Box>
            </Box>
        );
    };

    const getShiftById = (id: string) => {
        return rawShifts.find((shift: any) => shift.id.toString() === id);
    };

    const handleEventClick = (clickInfo: EventClickArg) => {
        dispatch(selectShift(clickInfo.event.id));
        dispatch(toggleSidebar(true));
    };

    const handleDateChange = async (dateSet: DatesSetArg) => {
        setShifts([]);
        const dateRange = { start: dateSet.startStr, end: dateSet.endStr };
        dispatch(setDateRange(dateRange));
    };

    const testTrigger = () => {
        console.log("schedules", shifts);
        getShiftDataByRange();
    };

    return (
        <>
            <Box
                sx={{
                    height: "calc(100vh - 100px)", // Adjust this value to control the height of the calendar
                    overflowY: "auto", // Enable vertical scrolling
                    overflowX: "hidden", // Prevent horizontal scrolling (optional)
                }}
            >
                <FullCalendar
                    height={"auto"} // Set height to auto so it adjusts to the container
                    schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                    // customButtons={customButtons}
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeline, resourceTimeline]}
                    headerToolbar={{
                        left: "today",
                        center: "prev title next",
                        right: "",
                    }}
                    initialView="dayGridMonth"
                    // views={customView}
                    weekends={true}
                    initialEvents={shifts}
                    datesSet={handleDateChange}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                    events={shifts}
                    resources={resources}
                    slotLabelFormat={[{ day: "numeric", weekday: "short", month: "short" }]}
                    resourceAreaHeaderContent={"Employee"}
                    resourceAreaWidth={"15%"}
                />
            </Box>
        </>
    );
};

export default Calendar;
