// src/styles.tsx
import { styled } from '@mui/system';
import { Box, Paper, Theme, useMediaQuery } from '@mui/material';

export interface WrapperProps {
  isMobile: boolean;
}
export interface RecordsScrollableProps {
  hasMoreThanFour?: boolean;
}

export const Wrapper = styled(Box)<WrapperProps>(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  width: '100%',
  ...(isMobile ? { alignItems: 'center' } : {}),
}));

export const OuterBox = styled(Box)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
    width: '80%',
  },
}));

export const InnerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

export const ListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  background: '#fff',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  marginBottom: theme.spacing(2),
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

export const Col = styled(Box)(({ theme }) => ({
  flex: 1,
  textAlign: 'center',
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },
}));

export const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  width: '100%',
  borderTop: '1px solid #175a9f',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    padding: theme.spacing(2),
  },
}));

export const LeftBtnBox = styled(Box)({
  position: 'absolute',
  left: 0,
});

export const RightBtnBox = styled(Box)({
  position: 'absolute',
  right: 0,
});

export const BtnBox = styled(Box)({
  zIndex: 1,
});

// New styles for tabs
export const TabContent = styled(Box)(({ theme }) => ({
  // padding: theme.spacing(2),
  // [theme.breakpoints.up('sm')]: {
    // padding: theme.spacing(3),
  // },
}));

export const AppBarStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

// New styles for summary
export const SummaryContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '1',
}));

export const SummaryRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 0),
  },
}));

export const SummaryItem = styled(Box)(({ theme }) => ({
  flex: 1,
  textAlign: 'center',
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },
}));

// New styles for records
export const RecordItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(1, 2), // Increased padding for better alignment
  borderBottom: '1px solid #e0e0e0',
  '&:last-child': {
    borderBottom: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },
}));

export const RecordDate = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
});

export const RecordDateLine = styled(Box)(({ theme }) => ({
  width: 3, // Adjust this to make the line more visible if needed
  height: '100%', // Ensure the parent container has a height
  backgroundColor: 'black', // Ensure this color contrasts with the background
  marginRight: theme.spacing(1), 
  display: 'block', // Ensure the line is displayed as a block element
  color:'black'
}));

export const RecordTime = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // Align text to the left
  justifyContent: 'center', 
});

export const RecordDuration = styled(Box)({
  color: 'grey',
  marginTop: 4, // Adjust margin for spacing between time and duration
  fontSize: '0.875rem', // Smaller font size to match the first image
});

export const ScrollableContainer = styled(Box)({
  height: 'calc(100vh - 200px)',
  overflowY: 'auto',
  paddingBottom: '16px',
});

export const RecordsScrollable = styled(Box)<RecordsScrollableProps>(({ hasMoreThanFour = true }) => (
  {
    height: hasMoreThanFour ? 'calc(100vh - 100px)' : 'calc(45vh - 100px)',
    // flexGrow: 1,
    // overflowY: 'auto',
    // marginBottom: 16, // Adjust as needed for spacing
  }
));

export const FixedSummary = styled(Box)({
  position: 'sticky',
  bottom: 0,
  // backgroundColor: 'white',
  // padding: 10,
  // boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
  // zIndex: 10,
});



