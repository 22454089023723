import React from "react";
import { TimesheetType } from "../../../types";

export interface TimesheetDetailsWebProps {
    timesheet: TimesheetType
}


const TimesheetDetailsWeb: React.FC<TimesheetDetailsWebProps> = ({timesheet}) => { 
    return (
        <div>
            Timesheet Details Web
        </div>
    );
}

export default TimesheetDetailsWeb;