import React from "react";
import { Typography, List, ListItem, Box } from "@mui/material";
import { ActorProfile2, Schedule } from "../../types";
import { DateTimeUtil, FetchMatchingEmployee } from "../../utils";

// Component to render employee details elegantly from Schedule's employee attribute
const EmployeeDetail = ({ employee }: { employee?: ActorProfile2 }) => {
    if (!employee) return null; // If no employee details available, render nothing

    return (
        <Box display="flex" flexDirection="column" color="#333" width="100%">
            <Typography variant="subtitle1" fontWeight="bold" color="#003366" mb={1}>
                Employee: {`${employee.osEmpFirstName} ${employee.osEmpLastName}`}
            </Typography>
            
            <Box display="flex" justifyContent="space-between" mb={1} width="100%">
                <Box width="48%">
                    <Typography variant="body2" color="#555">
                        <strong>Email:</strong> {employee.osEmpEmail || "N/A"}
                    </Typography>
                </Box>
                <Box width="48%">
                    <Typography variant="body2" color="#555">
                        <strong>Phone:</strong> {employee.osEmpPhone || "N/A"}
                    </Typography>
                </Box>
            </Box>
            
            <Box display="flex" justifyContent="space-between" mb={1} width="100%">
                <Box width="48%">
                    <Typography variant="body2" color="#555">
                        <strong>Country:</strong> {employee.osEmpCountry || "N/A"}
                    </Typography>
                </Box>
                <Box width="48%">
                    <Typography variant="body2" color="#555">
                        <strong>Hire Date:</strong> {DateTimeUtil.formatWithDayjs(employee.osEmpHireDate, "MMM D, YYYY")}
                    </Typography>
                </Box>
            </Box>

            {employee.osEmpBizEmployeeNumber && (
                <Box width="100%" mt={1}>
                    <Typography variant="body2" color="#555">
                        <strong>Employee Number:</strong> {employee.osEmpBizEmployeeNumber}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

// Component to render schedules and associated employee details elegantly
const ScheduleDetail = ({ schedules, employees }: { employees?: ActorProfile2[]; schedules?: Schedule[] }) => {
    if (!schedules || !schedules.length) return null; // If no schedules, render nothing

    return (
        <List sx={{ padding: 0, width: "100%" }}>
            {schedules.map((schedule, index) => {
                const employeeId = schedule.osEmpAccountId;
                const emp = FetchMatchingEmployee(employeeId, employees);
                return (
                    <ListItem
                        key={index}
                        sx={{
                            padding: "16px 0",
                            borderBottom: "1px solid #e0e0e0",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%", // Full width for list items
                        }}
                    >
                        <Box display="flex" flexDirection="column" width="48%">
                            {/* Schedule Information */}
                            <Typography variant="h6" fontWeight="bold" color="#003366" mb={1}>
                                Shift Title: {schedule.title || `Schedule ${index + 1}`}
                            </Typography>
                            <Box mb={1}>
                                <Typography variant="body2" color="#555">
                                    <strong>Shift Code:</strong> {schedule.osShiftCode || "N/A"}
                                </Typography>
                                <Typography variant="body2" color="#555">
                                    <strong>Shift Time:</strong>{" "}
                                    {DateTimeUtil.formatDateTimeRangeUtc(
                                        schedule.osEmpStartDateTime,
                                        schedule.osEmpEndDateTime
                                    )}
                                </Typography>
                            </Box>
                            {schedule.departmentCode && (
                                <Typography variant="body2" color="#555">
                                    <strong>Department:</strong> {schedule.departmentCode}
                                </Typography>
                            )}
                        </Box>

                        {/* Employee Details */}
                        {emp && (
                            <Box display="flex" flexDirection="column" width="48%">
                                <EmployeeDetail employee={emp} />
                            </Box>
                        )}
                    </ListItem>
                );
            })}
        </List>
    );
};

// Main component to render schedules and their associated employee details
const RenderEmployeeAndSchedules = ({
    schedules,
    title,
    employees,
}: {
    employees?: ActorProfile2[];
    schedules?: Schedule[];
    title?: string;
}) => {
    return (
        <Box sx={{ padding: "24px", width: "100%", margin: "auto" }}>
            <Typography
                variant="h5"
                sx={{
                    color: "#003366", // Elegant dark blue
                    marginBottom: "24px",
                    fontWeight: 600,
                    fontSize: "1.75rem",
                }}
            >
                {title || "Approved Schedules"}
            </Typography>

            {/* Render Schedules */}
            <ScheduleDetail schedules={schedules} employees={employees} />
        </Box>
    );
};

export default RenderEmployeeAndSchedules;
