import { Box, Typography, Divider } from "@mui/material";
import { Breaktime, TimesheetType } from "../../types";
import { calculateBreakTimeV2, calculateTotalWorkDurationV2, DateTimeUtil } from "../../utils";
import { RecordDate } from "./styles";
import { useNavigate } from "react-router";
import { setSelectedRecord } from "../../redux/features/timesheetv2/slice";
import { useAppDispatch } from "../../redux/store";
import { AccessTime as AccessTimeIcon, FreeBreakfast as FreeBreakfastIcon } from "@mui/icons-material";
import { FiberManualRecord as FiberManualRecordIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

export interface RecordRowType {
    isMobile: boolean;
    timesheet: TimesheetType;
}

const RecordRow: React.FC<RecordRowType> = ({ isMobile = true, timesheet }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleNavigationV2 = (timesheet: TimesheetType) => {
        dispatch(setSelectedRecord(timesheet));
        navigate("/timecardv2/details");
    };

    const date = timesheet.start_datetime;
    const totalWorkDuration = calculateTotalWorkDurationV2(timesheet);
    const totalBreakTime = calculateBreakTimeV2(timesheet);
    const formattedRange = DateTimeUtil.formatDateTimeRange(
        timesheet.start_datetime,
        timesheet.end_datetime,
        "!",
        false
    );
    const isInvalid = formattedRange.includes("!");
    const isVacation = timesheet?.record_type === "T";
    const splitDate = (date: string) => {
        return date.trim().split(" ");
    };
    const renderMobile = () => {
        return (
            <>
                {/** Main row */}
                <Box
                    sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                    onClick={() => handleNavigationV2(timesheet)}
                >
                    {/* Details */}
                    <Box sx={{ flex: 1, color: isVacation ? "red !important" : "black !important" }}>
                        {/** Row 1 - Date, Total Work Duration, right arrow */}
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <RecordDate>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: isVacation ? "red !important" : "black !important",
                                            marginRight: 1,
                                        }}
                                    >
                                        {splitDate(DateTimeUtil.getFormattedDate(date))[0]}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{ color: isVacation ? "red !important" : "black !important" }}
                                    >
                                        {splitDate(DateTimeUtil.getFormattedDate(date))[1]}
                                    </Typography>
                                </RecordDate>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "center", marginRight: 1 }}>
                                    <AccessTimeIcon sx={{ marginRight: 0.5, fontSize: "18px" }} />
                                    <Typography
                                        variant="body1"
                                        sx={{ color: isVacation ? "red !important" : "black !important" }}
                                    >
                                        {totalWorkDuration}
                                    </Typography>
                                </Box>

                                {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                                <ChevronRightIcon />
                            </Box> */}
                            </Box>
                        </Box>

                        {/** Row 2 - earning code name, break time */}
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: isInvalid || isVacation ? "error.main" : "primary.main",
                                    cursor: "pointer",
                                    marginTop: 1,
                                    textDecoration: "none",
                                    "&:hover": {
                                        textDecoration: "underline",
                                        color: isInvalid ? "error.dark" : "primary.dark",
                                    },
                                }}
                            >
                                {timesheet.earning_code?.name}
                            </Typography>

                            {timesheet.record_type == "A" && (
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: isInvalid || isVacation ? "error.main" : "primary.main",
                                            cursor: "pointer",
                                            marginTop: 1,
                                            textDecoration: "none",
                                            "&:hover": {
                                                textDecoration: "underline",
                                                color: isInvalid ? "error.dark" : "primary.dark",
                                            },
                                        }}
                                    >
                                        {formattedRange}
                                    </Typography>
                                </Box>
                            )}

                            {!isVacation && (
                                <Box display="flex" alignItems="center">
                                    <FreeBreakfastIcon sx={{ marginRight: 0.5, fontSize: "18px" }} />
                                    <Typography sx={{ color: isVacation ? "red !important" : "black !important" }}>
                                        {totalBreakTime}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    {/* Right arrow */}
                    <Box sx={{ display: "flex", alignItems: "flex-start", paddingY: 0.5 }}>
                        <ChevronRightIcon />
                    </Box>
                </Box>
                <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            </>
        );
    };

    const renderWeb = () => {
        return (
            <>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    onClick={() => handleNavigationV2(timesheet)}
                >
                    {/* day*/}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "grey.400",
                            padding: 1,
                        }}
                    >
                        <Typography>{DateTimeUtil.getFormattedDate(date)}</Typography>
                    </Box>

                    {/* record details */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: 2,
                            backgroundColor: "white",
                        }}
                    >
                        <Typography>{timesheet.earning_code?.name}</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", marginRight: 1 }}>
                            <AccessTimeIcon sx={{ marginRight: 0.5, fontSize: "18px" }} />
                            <Typography
                                variant="body1"
                                sx={{ color: isVacation ? "red !important" : "black !important" }}
                            >
                                {totalWorkDuration}
                            </Typography>
                        </Box>
                        {!isVacation && (
                            <Box display="flex" alignItems="center">
                                <FreeBreakfastIcon sx={{ marginRight: 0.5, fontSize: "18px" }} />
                                <Typography sx={{ color: isVacation ? "red !important" : "black !important" }}>
                                    {totalBreakTime}
                                </Typography>
                            </Box>
                        )}
                        {timesheet.record_type == "A" && (
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography
                                    sx={{
                                        color: isInvalid || isVacation ? "error.main" : "primary.main",
                                        cursor: "pointer",
                                        textDecoration: "none",
                                        "&:hover": {
                                            textDecoration: "underline",
                                            color: isInvalid ? "error.dark" : "primary.dark",
                                        },
                                    }}
                                >
                                    {formattedRange}
                                </Typography>
                            </Box>
                        )}

                        <Box>
                            <EditIcon />
                        </Box>
                    </Box>
                </Box>
            </>
        );
    };

    return isMobile ? renderMobile() : renderWeb();
};

export default RecordRow;
