// src/theme/ThemeContext.tsx
import React, { createContext, useState, useMemo, ReactNode } from 'react';
import { ThemeProvider, Theme } from '@mui/material/styles';
import { ThemeType, themes } from './themes';

// Define the shape of the ThemeContext
type ThemeContextType = {
  theme: Theme;
  setThemeType: (themeType: ThemeType) => void;
};

// Create the ThemeContext with an undefined default value
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// Props for ThemeContextProvider to accept initial theme type
type ThemeContextProviderProps = {
  children: ReactNode;
  initialThemeType: ThemeType;
};

// ThemeContextProvider component to manage and provide the theme
const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({ children, initialThemeType }) => {
  // State to hold the current theme type
  const [themeType, setThemeType] = useState<ThemeType>(initialThemeType);

  // Memoize the theme object to only recompute when themeType changes
  const theme = useMemo(() => themes[themeType], [themeType]);

  // Function to update the theme type
  const setTheme = (newThemeType: ThemeType) => {
    setThemeType(newThemeType);
  };

  return (
    <ThemeContext.Provider value={{ theme, setThemeType: setTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
