import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import Tooltip from "@mui/material/Tooltip";

export const REQUEST_TYPE: IStatus[] = [
  {
    value: 0,
    label: "Assigned",
    color: "secondary",
    desc: "",
  },
  {
    value: 1,
    label: "Trade",
    color: "primary",
    desc: "",
  },
  {
    value: 2,
    label: "Release",
    color: "success",
    desc: "",
  },
  {
    value: 3,
    label: "Drop",
    color: "error",
    desc: "",
  },
  {
    value: 4,
    label: "Other",
    color: "default",
    desc: "",
  },
];

interface IStatus {
  value: number;
  label: string;
  color:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  desc: string;
}

export const TXN_STATUS: IStatus[] = [
  {
    value: 0,
    label: "Pending",
    color: "primary",
    desc: "Awaiting Manager Approval",
  },
  {
    value: 1,
    label: "Accepted",
    color: "success",
    desc: "Accepted by Manager",
  },
  {
    value: 2,
    label: "Rejected",
    color: "error",
    desc: "Rejected by Manager",
  },
  {
    value: 3,
    label: "Completed",
    color: "secondary",
    desc: "Transaction Completed",
  },
  {
    value: 4,
    label: "Closed",
    color: "default",
    desc: "Transaction Closed",
  },
  {
    value: 5,
    label: "Reverted",
    color: "info",
    desc: "Transaction Reverted",
  },
];

export const TRADE = 1;
export const RELEASE = 2;
export const DROP = 3;
export const TXN_PENDING = 0;
export const TXN_ACCEPTED = 1;

/// i have made change to redeploy

export const SHIFT_STATUS = [
  {
    label: "Assigned",
    // color: "#795548",
    color: "#84494554",
    status: 0,
    icon: (
      <Tooltip title="Shift assigned" placement="top" sx={{ fontSize: "20px", borderColor: '#84494554' }}>
        <AssignmentTurnedInOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
    ),
  },
  {
    label: "Pending for trade",
    // color: "#009688",
    color: "#71797E",
    status: 1,
    icon: (
      <Tooltip
        title="Pending for trade"
        placement="top"
        sx={{ fontSize: "20px" }}
      >
        <SwapHorizontalCircleOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
    ),
  },
  {
    label: "Pending for release",
    color: "#673ab7",
    status: 2,
    icon: (
      <Tooltip
        title="Pending for release"
        placement="top"
        sx={{ fontSize: "20px" }}
      >
        <AccountCircleOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
    ),
  },
  {
    label: "Pending for drop",
    color: "#e91e63",
    status: 3,
    icon: (
      <Tooltip
        title="Pending for drop"
        placement="top"
        sx={{ fontSize: "20px" }}
      >
        <ArrowCircleDownOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
    ),
  },
  {
    label: "Rejected by manager",
    color: "#f44336",
    status: 4,
    icon: (
      <Tooltip
        title="Rejected by manager"
        placement="top"
        sx={{ fontSize: "20px" }}
      >
        <ThumbDownAltOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
    ),
  },
  {
    label: "Accepted by manager",
    color: "#4caf50",
    status: 5,
    icon: (
      <Tooltip
        title="Accepted by manager"
        placement="top"
        sx={{ fontSize: "20px" }}
      >
        <ThumbUpAltOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
    ),
  },
  {
    label: "Request Approved",
    color: "#03a9f4",
    status: 6,
    icon: (
      <Tooltip
        title="Request Approved"
        placement="top"
        sx={{ fontSize: "20px" }}
      >
        <HowToRegOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
    ),
  },
  {
    label: "Request Rejected",
    color: "#ffeb3b",
    status: 7,
    icon: (
      <Tooltip
        title="Request Rejected"
        placement="top"
        sx={{ fontSize: "20px" }}
      >
        <CancelOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
    ),
  },
  {
    label: "Not assigned",
    color: "#607d8b",
    status: 8,
    icon: (
      <Tooltip title="Not assigned" placement="top" sx={{ fontSize: "20px" }}>
        <PriorityHighOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
    ),
  },
  {
    label: "Closed",
    color: "#9e9e9e",
    status: 9,
    icon: (
      <Tooltip title="Shift closed" placement="top" sx={{ fontSize: "20px" }}>
        <EventAvailableOutlinedIcon fontSize="small" color="action" />
      </Tooltip>
    ),
  },
];

export const SHIFT_ASSIGNED = 0;
export const PENDING_FOR_TRADE = 1;
export const PENDING_FOR_RELEASE = 2;
export const PENDING_FOR_DROP = 3;
export const REQUEST_REJECTED = 7;

export const ACCEPT = true;
export const REJECT = false;

export const NORMAL_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const SCHEDULE_DATE_FORMAT = "YYYY-MM-DD";

export const MANAGE_REQUESTS = 0;
export const MY_REQUESTS = 1;
export const AVAIL_TO_PICKUP = 2;
export const AVAIL_TO_TRADE = 3;

export const MANAGER_DESC = [
  {
    des: "Would like to trade this shift",
    subDes: "Trade request from",
  },
  {
    des: "Would like to release this shift",
    subDes: "Release request from",
  },
  {
    des: "Would like to drop this shift",
    subDes: "Drop request from",
  },
];

export const EMPLOYEE_DESC = [
  {
    des: "Would like to trade this shift with you",
    subDes: "Trade request from",
  },
  {
    des: "Would like to offer this shift to you",
    subDes: "Release request from",
  },
];

//privileges:

export const MANAGER = 6;
export const SUPER_USER = 1;

export const TXN_TYPE = [
  {
    id: 1,
    value: 1,
    title: "Trade",
  },
  {
    id: 2,
    value: 2,
    title: "Release",
  },
  {
    id: 3,
    value: 3,
    title: "Drop",
  },
];

export const DRAWERWIDTH = 240;
