import API from "../axios/API";
import { getApiUrl } from "../utils";

// const baseURL = process.env.REACT_APP_BACKEND_LOCAL;
const baseURL = getApiUrl();

export const getUserDetails = async () => {
  const res = await API.post(`${baseURL}/user/user-info`);
  // console.log('res', JSON.stringify(res.data, null, 2));
  return res;
};