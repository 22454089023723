import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../axios/API";

export const getAllTimesheets = createAsyncThunk(
    "/timesheets/all",
    async (
        {
            employee_id = "",
            pay_period,
            record_type = "A",
            sort_by = "os_emp_start_datetime",
            sort_order = "DESC",
            page = "1",
        }: {
            pay_period: string;
            employee_id: string;
            record_type?: string;
            sort_by?: string;
            sort_order?: string;
            page?: string;
        },
        thunkAPI
    ) => {
        const { rejectWithValue } = thunkAPI;

        try {
            const data = { employee_id, pay_period, record_type, sort_by, sort_order, page };
            // console.log("data", JSON.stringify(data, null ,2 ));
            const response = await API.post("/timecard/timesheets", data);

            // const response = await API.get("/pay-period/all", {});
            console.log("response", response.data);
            return response.data;
        } catch (error) {
            console.error("*** getAllTimesheets API error", error);
            return rejectWithValue(error);
        }
    }
);

export const getSummary = createAsyncThunk(
    "timesheet/summary",
    async (
        data: {
            employee_id: string;
            pay_period: string;
        },
        thunkAPI
    ) => {
        const { rejectWithValue } = thunkAPI;

        try {
            const response = await API.post("/timecard/employee_hours_summary", data);
            return response.data;
        } catch (error) {
            console.error("*** getSummary API error", error);
            return rejectWithValue(error);
        }
    }
);
