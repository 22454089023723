import {
    Box,
    Button,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Note, TimesheetType } from "../../types";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back icon
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import React from "react";
import { useAppDispatch } from "../../redux/store";
import { DateTimeUtil } from "../../utils";
import { addTimecardRequest } from "../../redux/features/timesheetv2";

export type AddEditFormProps = {
    isEditMode?: boolean;
    setIsEditMode?: (isEditMode: boolean) => void;
    isAddMode?: boolean;
    setIsAddMode: (isAddMode: boolean) => void;
    timesheet?: TimesheetType;
    startTime: Date | null;
    setStartTime: (date: Date | null) => void;
    endTime: Date | null;
    setEndTime: (date: Date | null) => void;
    notes?: Note[];
};

const AddEditForm: React.FC<AddEditFormProps> = ({
    isAddMode,
    isEditMode,
    notes,
    timesheet,
    setIsAddMode,
    setIsEditMode,
    endTime,
    setEndTime,
    setStartTime,
    startTime,
}) => {
    const departments: any[] = [];
    const [inputValue, setInputValue] = React.useState("");
    const dispatch = useAppDispatch();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Handle form submission logic here
        console.log("Form submitted with value:", inputValue);
    };
    const handleBack = () => {
        if (isEditMode && setIsEditMode) {
            setIsEditMode(false);
        } else if (isAddMode && setIsAddMode) {
            setIsAddMode(false);
        }
    };

    const handleDepartmentChange = (event: SelectChangeEvent<string>) => {
        const departmentId = event.target.value;
        // Add your logic here to handle the department change
    };

    const editableDatetimePicker = (
        placeholder: string,
        date: Date | null,
        setDate: (date: Date | null) => void
    ): JSX.Element => {
        return (
            <>
                <Box>
                    {/* <TextField variant="standard" value={startTime} /> */}
                    <DateTimePicker
                        value={date}
                        disablePast={true}
                        orientation="portrait"
                        onChange={(newValue) => setDate(newValue || null)}
                        slotProps={{
                            textField: {
                                placeholder: placeholder,
                                variant: "standard",
                                fullWidth: false,
                                sx: {
                                    minWidth: "120px", // Set a minimum width
                                    width: "auto", // Auto width based on content
                                    "& .MuiInputBase-input": {
                                        textAlign: "center", // Center-align text, optional
                                    },
                                    "& .MuiInput-underline:before": {
                                        borderBottomColor: "black",
                                    },
                                    "& .MuiInput-underline:hover:before": {
                                        borderBottomColor: "black",
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottomColor: "black",
                                    },
                                },
                                InputLabelProps: {
                                    shrink: true,
                                    sx: {
                                        minWidth: "120px",
                                    },
                                },
                                error: false, // Ensure that this isn't causing a validation error state
                            },
                        }}
                    />
                </Box>
            </>
        );
    };

    const getButtonText = () => {
        if (isEditMode) {
            return "Edit";
        } else if (isAddMode) {
            return "Add";
        } else {
            return "Submit";
        }
    };

    const handleTimesheetFormSubmit = () => {
        const timesheetId = timesheet?.id;
        const startDateTime = DateTimeUtil.formatWithDayjs(startTime);
        const endDateTime = DateTimeUtil.formatWithDayjs(endTime);
        const recordType = "A";
        const earningCode = timesheet?.earning_code?.code;
        const date = DateTimeUtil.formatWithDayjs(new Date());
        
        const requestBody = {
            timeEntryId: timesheetId!,
            date,
            startDateTime: startDateTime!,
            endDateTime: endDateTime!,
            recordType: recordType,
            earningCode: earningCode!,
        }
        console.log(requestBody);

        dispatch(addTimecardRequest(requestBody));
    };

    const test = () => {
        console.log("timesheet", timesheet);
    }

    return (
        <>
            <Box
                sx={{
                    height: "100%",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    background: "transparent",
                    paddingX: 2,
                    paddingTop: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        position: "relative",
                    }}
                >
                    <IconButton color="primary" onClick={handleBack} sx={{ mr: 1 }}>
                        <ArrowBackIcon />
                    </IconButton>
                </Box>

                {/* Timesheet form */}
                <Box>
                    {/* Start and End Times */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    mb: 2,
                                }}
                            >
                                <Typography sx={{ color: "black" }}>Start:</Typography>
                                {editableDatetimePicker("Start time", startTime, setStartTime)}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    mb: 2,
                                }}
                            >
                                <Typography sx={{ color: "black" }}>End:</Typography>
                                {editableDatetimePicker("End time", endTime, setEndTime)}
                            </Box>
                        </Box>
                    </LocalizationProvider>
                    <TableContainer component={Box} sx={{ boxShadow: "none", backgroundColor: "transparent" }}>
                        <Table>
                            <TableBody>
                                {/* <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">
                                            <strong>Regular Hours:</strong>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{timesheet?.regular_hours || ""}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">
                                            <strong>Overtime Hours:</strong>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{timesheet?.overtime_hours || ""}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">
                                            <strong>Doubletime Hours:</strong>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{timesheet?.doubletime_hours || ""}</TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">
                                            <strong>Department:</strong>
                                        </Typography>
                                    </TableCell>
                                    {/* <TableCell>{timesheet?.department?.name || ""}</TableCell> */}
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <Select
                                                value={timesheet?.department?.name || ""}
                                                onChange={handleDepartmentChange}
                                                displayEmpty
                                                sx={{
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "none",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        border: "none",
                                                    },
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        border: "none",
                                                    },
                                                    "& .MuiSelect-root": {
                                                        boxShadow: "none",
                                                    },
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Department
                                                </MenuItem>
                                                {departments.map((department) => (
                                                    <MenuItem key={department.id} value={department.id}>
                                                        {department.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">
                                            <strong>Break Times:</strong>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{timesheet?.breaktimes_json?.unpaidbreaktime || ""}</TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">
                                            <strong>Deduction:</strong>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{timesheet?.deduction || ""}</TableCell>
                                </TableRow> */}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box sx={{ display: "flex", flexDirection: "row-reverse", mt: 1 }}>
                    <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ height: "40px" }}
                            onClick={test}
                        >
                            Test
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ height: "40px" }}
                            onClick={handleTimesheetFormSubmit}
                        >
                            {getButtonText()}
                        </Button>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    {/* Notes */}
                    <Box>
                        <Typography variant="body1" color="primary" sx={{ mt: 4, fontWeight: "bold" }}>
                            Note:
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 2, // Space between input and button
                                mt: 1,
                            }}
                        >
                            <TextField
                                variant="outlined"
                                value={inputValue}
                                onChange={handleInputChange}
                                sx={{ flexGrow: 1, height: "100%" }} // Make the input field flexible in width and height
                                InputProps={{
                                    sx: { height: "40px" }, // Set the height of the input field
                                }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ height: "40px" }} // Set the height of the button to match the input field
                            >
                                {getButtonText()}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AddEditForm;
