import {
    Typography,
    Box,
    IconButton,
    TextField,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
    Button,
    CircularProgress,
    Card,
    Divider,
    InputAdornment,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAppDispatch } from "../../../redux/store";
import { SwappableEmployee } from "../../../types/state/swap";
import { useSelector } from "react-redux";
import { DatetimeDetail, ShiftType } from "../../../types";
import React from "react";
import { updateSelectedSwapEmployees } from "../../../redux/features/mySchedule/ScheduleSlice";
import { DateTimeUtil } from "../../../utils";
import { DatePicker } from "@mui/x-date-pickers";
import { Clear } from "@mui/icons-material";

const SelectionForm: React.FC<{
    startDate: Date | null;
    endDate: Date | null;
    handleStartDateChange: (date: Date | null) => void;
    handleEndDateChange: (date: Date | null) => void;
    handleNameSearch: (name: string) => void;
    onSuccess: () => void;
    onSearch: () => void;
}> = ({ startDate, endDate, handleEndDateChange, handleStartDateChange, onSuccess, handleNameSearch, onSearch }) => {
    const selectedShift = useSelector((state: any) => state.mySchedule.selectedShift) as ShiftType;
    const swappableEmployees = useSelector((state: any) => state.mySchedule.swappableEmployees) as SwappableEmployee[];
    const fetchingEmployeeStatus = useSelector((state: any) => state.mySchedule.requestState) as string;
    const [grouped, setGrouped] = React.useState<Map<number, SwappableEmployee[]>>(new Map());
    const [selectedEmployees, setSelectedEmployees] = React.useState<Set<SwappableEmployee>>(new Set());
    const dispatch = useAppDispatch();
    const [startDetails, setStartDetails] = React.useState<DatetimeDetail>();
    const [searchValue, setSearchValue] = React.useState("");
    // Create refs for both start and end date inputs
    const startInputRef = useRef<HTMLInputElement>(null); // Ref type is HTMLInputElement
    const endInputRef = useRef<HTMLInputElement>(null); // Ref type is HTMLInputElement
    const [startAnchorEl, setStartAnchorEl] = React.useState<HTMLElement | null>(null);
    const [endAnchorEl, setEndAnchorEl] = React.useState<HTMLElement | null>(null);

    useEffect(() => {
        // Ensure the ref is populated before using it as anchorEl
        if (startInputRef.current) {
            setStartAnchorEl(startInputRef.current);
        }
        if (endInputRef.current) {
            setEndAnchorEl(endInputRef.current);
        }
    }, []);

    useEffect(() => {
        setStartDetails(getDatetimeDetails(selectedShift?.osEmpStartDateTime!));
    }, [selectedShift]);

    useEffect(() => {
        console.log("SelectionForm rendered");
        // console.log("swappableEmployees", JSON.stringify(swappableEmployees, null, 2));
        const grouped = groupEmployeesWithSameName();
        setGrouped(grouped);

        // setDateRange([filterStartDate, filterEndDate]);
    }, [swappableEmployees]);

    useEffect(() => {
        dispatch(updateSelectedSwapEmployees(Array.from(selectedEmployees)));
    }, [selectedEmployees]);

    const groupEmployeesWithSameName = (): Map<number, SwappableEmployee[]> => {
        const today = new Date().toUTCString();
        const employeesMap = new Map<number, SwappableEmployee[]>();
        swappableEmployees.forEach((employee) => {
            if (!DateTimeUtil.isAfter(new Date().toISOString(), employee.osEmpStartDateTime)) {
                if (employeesMap.has(employee.osEmpAccountId!)) {
                    employeesMap.get(employee.osEmpAccountId!)?.push(employee);
                } else {
                    employeesMap.set(employee.osEmpAccountId!, [employee]);
                }
            }
        });

        return employeesMap;
    };

    // Check if an employee is selected by comparing their id
    const isEmployeeSelected = useCallback(
        (employee: SwappableEmployee) => {
            return Array.from(selectedEmployees).some((selectedEmployee) => selectedEmployee.id === employee.id);
        },
        [selectedEmployees]
    );

    // Optimized handleCheckboxChangeV2 to handle adding/removing SwappableEmployee objects
    const handleCheckboxChangeV2 = useCallback((employee: SwappableEmployee, isChecked: boolean) => {
        setSelectedEmployees((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (isChecked) {
                // Add employee if not already in the set (based on id comparison)
                if (!Array.from(newSelected).some((selectedEmployee) => selectedEmployee.id === employee.id)) {
                    newSelected.add(employee);
                }
            } else {
                // Remove the employee based on id
                newSelected.forEach((selectedEmployee) => {
                    if (selectedEmployee.id === employee.id) {
                        newSelected.delete(selectedEmployee);
                    }
                });
            }
            return newSelected;
        });
    }, []);

    // Memoize grouped employees to avoid recalculating on every render
    const groupedEmployees = useMemo(() => {
        const grouped = new Map<number, SwappableEmployee[]>();
        swappableEmployees.forEach((employee) => {
            if (!DateTimeUtil.isAfter(new Date().toISOString(), employee.osEmpStartDateTime)) {
                if (!grouped.has(employee.osEmpAccountId!)) {
                    grouped.set(employee.osEmpAccountId!, []);
                }
                grouped.get(employee.osEmpAccountId!)?.push(employee);
            }
        });
        return grouped;
    }, [swappableEmployees]);

    // Memoized rendering of employees
    const renderAvailableEmployees = useMemo(() => {
        return (
            <List dense>
                {Array.from(groupedEmployees).map(([employeeID, employees]) => (
                    <Box
                        key={employeeID}
                        sx={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            padding: 1,
                            marginTop: 1,
                            backgroundColor: "#f9f9f9",
                        }}
                    >
                        <Typography variant="body2" gutterBottom>
                            {`${employees[0].osEmployeeProfile?.osEmpFirstName} ${employees[0].osEmployeeProfile?.osEmpLastName}`}
                        </Typography>
                        <Box
                            sx={{
                                height: employees.length > 3 ? 300 : "auto",
                                overflow: "auto",
                            }}
                        >
                            {employees.map((employee) => (
                                <ListItem key={employee.id} button>
                                    <ListItemIcon>
                                        <Checkbox
                                            size="small"
                                            checked={isEmployeeSelected(employee)}
                                            onChange={(e) => handleCheckboxChangeV2(employee, e.target.checked)}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={DateTimeUtil.formatDateTimeRangeUtc(
                                            employee.osEmpStartDateTime,
                                            employee.osEmpEndDateTime
                                        )}
                                    />
                                </ListItem>
                            ))}
                        </Box>
                    </Box>
                ))}
            </List>
        );
    }, [groupedEmployees, isEmployeeSelected]);

    // Reusable function to render a DatePicker
    const renderDatePicker = useCallback(
        (
            label: string,
            value: Date | null,
            onChange: (date: Date | null) => void,
            boxRef: React.RefObject<HTMLDivElement>
        ) => {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box sx={{ minWidth: 150, height: 50 }} ref={boxRef}>
                        <DatePicker
                            label={label}
                            value={value}
                            onChange={onChange}
                            slots={{
                                textField: (params) => <TextField {...params} size="small" />, // Handles the input field
                            }}
                            slotProps={{
                                popper: {
                                    anchorEl: boxRef.current,
                                    modifiers: [
                                        {
                                            name: "flip",
                                            enabled: false, // Disable flipping to avoid positioning issues
                                        },
                                        {
                                            name: "preventOverflow",
                                            options: {
                                                boundary: "viewport", // Ensure it stays within the viewport
                                            },
                                        },
                                        {
                                            name: "hide",
                                            enabled: false, // Disable hiding logic that could cause `reference-hidden`
                                        },
                                    ],
                                },
                            }}
                        />
                    </Box>
                </LocalizationProvider>
            );
        },
        []
    );

    const getDatetimeDetails = (dateTime: string): DatetimeDetail => {
        const date = new Date(dateTime);
        return {
            month: date.toLocaleString("default", { month: "long" }),
            monthNumber: date.getMonth(),
            day: date.toLocaleString("default", { day: "numeric" }),
            dayNumber: date.getDate(),
            year: date.getFullYear(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: date.getSeconds(),
            timezone: date.toTimeString().split(" ")[1],
        };
    };

    return (
        <>
            {/* <Button onClick={test}>Test</Button> */}
            {/* <Typography variant="h6">Trade Shift</Typography> */}
            <Box
                sx={{
                    // border: "1px solid rgba(0, 0, 0, 0.12)",
                    // padding: 1,
                    marginTop: 1,
                    backgroundColor: "#ffffff",
                }}
            >
                {/* Shift Details Badge */}
                <Card
                    sx={{
                        padding: 1,
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 2,
                        marginTop: 1,
                    }}
                    elevation={0}
                >
                    <Box
                        sx={{
                            marginRight: 2,
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="body2" sx={{ color: "red" }}>
                            {startDetails?.month}
                        </Typography>
                        <Typography variant="h6">{startDetails?.dayNumber}</Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1, border: 0 }}>
                        <Typography variant="body2" gutterBottom>
                            {DateTimeUtil.getFormattedTimeUtc(selectedShift?.osEmpStartDateTime)} -{" "}
                            {DateTimeUtil.getFormattedTimeUtc(selectedShift?.osEmpEndDateTime)}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {selectedShift?.osEmployeeProfile?.os_emp_first_name}{" "}
                            {selectedShift?.osEmployeeProfile?.os_emp_last_name}
                        </Typography>
                    </Box>
                </Card>

                {/* Date pickers and search button */}
                <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2, gap: 2, height: 50 }}>
                    {/* Start Date Picker */}
                    {renderDatePicker("Start", startDate, handleStartDateChange, startInputRef)}

                    {/* End Date Picker */}
                    {renderDatePicker("End", endDate, handleEndDateChange, endInputRef)}

                    {/* Search Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSearch}
                        sx={{ height: "100%" }}
                    >
                        Search
                    </Button>
                </Box>
                <Typography variant="body2" gutterBottom>
                    {selectedEmployees.size} Selected Covers:
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    placeholder="Employee search..."
                    size="small"
                    value={searchValue}
                    onChange={(e) => {
                        const value = (e.target as HTMLInputElement).value;
                        setSearchValue(value);
                        // handleNameSearch(value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleNameSearch(searchValue); // Trigger search on Enter
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchValue && (
                                    <IconButton
                                        onClick={() => {
                                            setSearchValue(""); // Clear the input value
                                            handleNameSearch(""); // Trigger search with empty string
                                        }}
                                    >
                                        <Clear />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
                {fetchingEmployeeStatus === "PENDING" ? (
                    <>
                        <CircularProgress
                            sx={{
                                display: "block",
                                margin: "auto",
                                color: "blue",
                            }}
                        />
                    </>
                ) : (
                    renderAvailableEmployees
                )}
                <Box textAlign="right" marginTop={1}>
                    <Button variant="contained" color="primary" size="small" onClick={onSuccess}>
                        Continue
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default SelectionForm;
