import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
    getAvailSchedules,
    getScheduleData,
    getAvailEmp,
    confirmTrade,
    confirmRelease,
    confirmDrop,
    getSwapTransactions,
    acceptRequest,
    getSwapRequests,
    getShiftsByDate,
    getSwapEmployees,
    updateSwapRequest,
    deleteSwapRequest,
} from "./ScheduleApi";
import { FULFILLED, PENDING, REJECTED } from "../../constants";

import { IShift } from "./models/schedule";
import { SwappableEmployee } from "../../../types/state/swap";
// import { IShift, IAvailShifts } from './models/schedule'

export const ScheduleSlice = createSlice({
    name: "schedule",
    initialState: {
        requestState: FULFILLED,
        shifts: [] as IShift[],
        selectedShift: null,
        dateRange: null,
        availShifts: null,
        availEmp: [] as any[],
        txnsForManager: [] as any[],
        txnsForUser: [] as any[],
        /** trade swap */
        createSwapReason: " " as string,
        swappableEmployees: [] as SwappableEmployee[],
        swapFilterStartDate: " " as string,
        swapFilterEndDate: " " as string,
        selectedSwapEmployees: [] as SwappableEmployee[],
        swapNote: " ",
    },
    reducers: {
        selectShift: (state, data) => {
            const shiftId = data.payload;
            const shift: any = state.shifts.find((shift) => shift.id.toString() === shiftId);
            state.selectedShift = shift;
        },
        setDateRange: (state, data) => {
            state.dateRange = data.payload;
        },
        tgShift: (state, data) => {
            let payload = data.payload;
            let idx = state.txnsForUser.findIndex((txn: any) => txn.id === payload);

            if (idx === -1) {
                return;
            } else {
                state.txnsForUser[idx].checked = true;
            }
        },
        clearTxns: (state) => {
            state.txnsForUser = [];
        },
        updateSelectedSwapEmployees: (state, data) => {
            state.selectedSwapEmployees = data.payload;
            console.log("selectedSwapEmployees", state.selectedSwapEmployees);
        },
        setFilterStartDate: (state, data) => {
            state.swapFilterStartDate = data.payload;
        },
        setFilterEndDate: (state, data) => {
            state.swapFilterEndDate = data.payload;
        },
        setSwapNote: (state, data) => {
            state.swapNote = data.payload;
            console.log("swapnote ===>", state.swapNote);
        },
    },
    extraReducers: {
        /** NEW API REDUCERS */

        /** Get shifts by date reducers */
        [getShiftsByDate.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [getShiftsByDate.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                console.log("*** payload.data", payload.data);
                state.shifts = [...payload.data];
            }
            state.requestState = FULFILLED;
        },
        [getShiftsByDate.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },

        /** get swap employees recuders */
        [getSwapEmployees.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [getSwapEmployees.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                console.log("*** payload.data", payload.data);
                state.swappableEmployees = [...payload.data];
            }
            state.requestState = FULFILLED;
        },
        [getSwapEmployees.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },

        /** update swap request reducers */
        [updateSwapRequest.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },

        [updateSwapRequest.fulfilled.type]: (state, { payload }) => {
            state.requestState = FULFILLED;
            console.log("updateSwapRequest", payload);
        },

        [updateSwapRequest.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },

        /** OLD API REDCUCERS */
        [getScheduleData.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [getScheduleData.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                state.shifts = [...payload.data];
            }
            state.requestState = FULFILLED;
        },
        [getScheduleData.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },
        [getAvailSchedules.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [getAvailSchedules.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                state.availShifts = { ...payload.data };
            }
            state.requestState = FULFILLED;
        },
        [getAvailSchedules.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },
        [getAvailEmp.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [getAvailEmp.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                state.availEmp = [...payload.data];
            }
            state.requestState = FULFILLED;
        },
        [getAvailEmp.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },
        [confirmTrade.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [confirmTrade.fulfilled.type]: (state, { payload }) => {
            state.requestState = FULFILLED;
        },
        [confirmTrade.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },
        [confirmRelease.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [confirmRelease.fulfilled.type]: (state, { payload }) => {
            state.requestState = FULFILLED;
        },
        [confirmRelease.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },
        [confirmDrop.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [confirmDrop.fulfilled.type]: (state, { payload }) => {
            state.requestState = FULFILLED;
        },
        [confirmDrop.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },
        [getSwapTransactions.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [getSwapTransactions.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                // state.txnsForManager = [...payload.data];
            }
            state.requestState = FULFILLED;
        },
        [getSwapTransactions.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },
        [acceptRequest.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [acceptRequest.fulfilled.type]: (state, { payload }) => {
            state.requestState = FULFILLED;
        },
        [acceptRequest.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },
        [getSwapRequests.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },
        [getSwapRequests.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                state.txnsForUser = [
                    ...payload.data.map((txn: any) => {
                        return { ...txn, checked: false };
                    }),
                ];
            }
            state.requestState = FULFILLED;
        },
        [getSwapRequests.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },

        [deleteSwapRequest.pending.type]: (state, action) => {
            state.requestState = PENDING;
        },

        [deleteSwapRequest.fulfilled.type]: (state, { payload }) => {
            state.requestState = FULFILLED;
        },

        [deleteSwapRequest.rejected.type]: (state, action) => {
            state.requestState = REJECTED;
        },
    },
});

export const selectShifts = createSelector(
    (state: any) => state.shifts,
    (shifts: any) => shifts
);

export const {
    selectShift,
    setDateRange,
    tgShift,
    clearTxns,
    updateSelectedSwapEmployees,
    setFilterStartDate,
    setFilterEndDate,
    setSwapNote,
} = ScheduleSlice.actions;

export default ScheduleSlice.reducer;
