import React from "react";
import { Box, Button } from "@mui/material";

import TimesheetMain from "../components/timesheet/TimesheetMain";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface DayData {
  day: string;
  date: string;
  loginTime: string[];
  logoutTime: string[];
  totalWorkingTime: string;
}

const TimesheetPage = () => {
  const isSidebarOpen = useSelector((state: RootState) => state.layout.sidebar);

  // const today = new Date();
  // const year = today.getFullYear();
  // const month = today.getMonth();

  // const daysInMonth = new Date(year, month + 1, 0).getDate();
  // const firstDayOfMonth = new Date(year, month, 1).getDay();
  // const weeksInMonth = Math.ceil((daysInMonth + firstDayOfMonth) / 7);

  // Assuming timesheetData is your data array
  // const timesheetData = [{
  //   day: today.toLocaleDateString('en-US', { weekday: 'short' }),
  //   date: today.getDate().toString(),
  //   loginTime: [],
  //   logoutTime: [],
  //   totalWorkingTime: "8 hours"
  // }];

  return (
    // <>
    //   <Header />

    //   <Box sx={{ display: "flex", overflow: 'auto' }}>
    //     <SideBar />
    //     <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 5, overflow: "auto"}}>
    //       <TimesheetComponent />
    //     </Box>
    //   </Box>
    // </>

    // <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", overflow: "hidden" }}>
    //   <Header />
    //   <Box sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
    //     <SideBar />
    //     <Box component="main" sx={{ flexGrow: 1, p: 2, overflow: "auto", padding: "0px", marginBottom: "15px" }}>
    //       <TimesheetComponent />
    //     </Box>
    //   </Box>
    // </Box>
    <>
      <Header />
      <SideBar />

      {!isSidebarOpen ? (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", overflow: "auto" }}>
          <Box sx={{ display: "flex", flexGrow: 1, overflow: "auto" }}>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
                marginBottom: 5
              }}
            >
              <TimesheetMain />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </>
  );
};

export default TimesheetPage;
