import { StepJsonTypeEnum } from "../enums";
import { ActorProfile, InboxDetailType } from "../types";

export const isManager = (type?: string) => {
    if (!type) return false;

    switch (type) {
        case StepJsonTypeEnum.ASSIGNED_MANAGER:
            return true;
        case StepJsonTypeEnum.EMPLOYEE_NAME:
        case StepJsonTypeEnum.EMPLOYEE_SELF:
            return false;
        default:
            return false;
    }
};

export const isManagerV2 = (notificationDetail?: InboxDetailType, osEmpAccountId?: string) => {
    if (!notificationDetail || !osEmpAccountId) return false;

    const managerId = notificationDetail.shiftTransaction?.manager?.os_emp_account_id;

    return managerId == osEmpAccountId;
};

export const GetUserFullName = (employee: ActorProfile) => {
    return `${employee?.os_emp_first_name || employee?.osEmpFirstName} ${
        employee?.os_emp_last_name || employee?.osEmpLastName
    }`;
};
