import { AxiosClient } from "./axiosClient";


class NextClient extends AxiosClient {
    constructor() {
        super();
    }

}

export default new NextClient();