import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Avatar,
    Card,
    CardContent,
    Divider,
    Checkbox,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Radio,
    CircularProgress,
    FormControlLabel,
    Icon,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    ActorProfile,
    ActorProfile2,
    EmployeeSchedulesMap,
    InboxDetailType,
    Schedule,
    UpdateSwapScheduleRequest,
} from "../../types";
import { NotificationCategoryEnum, SwapScheduleActionEnum } from "../../enums";
import { PENDING, REJECTED } from "../../redux/constants";
import dayjs from "dayjs";
import { DateTimeUtil, FetchMatchingEmployee, GetUserFullName, isManagerV2 } from "../../utils";
import { useAppDispatch } from "../../redux/store";
import { updateSwapRequest } from "../../redux/features/mySchedule/ScheduleApi";
import RenderEmployeeAndSchedules from "./renderEmployeeDetails";
import { IUser } from "../../redux/features/user/models/user";

const getActionString = (status: NotificationCategoryEnum) => {
    switch (status) {
        case NotificationCategoryEnum.SHIFT_OFFER:
            return "Would like to offer this shift";
        case NotificationCategoryEnum.SHIFT_REQUEST:
            return "Would like to request this shift";
        case NotificationCategoryEnum.EMP_SHIFT_SWAPPING:
            return "Would like to swap this shift";
        case NotificationCategoryEnum.TRADE:
            return "Would like to trade this shift";
        default:
            return "";
    }
};

const NotificationDetail = () => {
    const notificationDetail = useSelector((state: any) => state.notification.selectedNoticationIndexDetail) as
        | InboxDetailType
        | undefined;
    const notificationInboxDetailState = useSelector(
        (state: any) => state.notification.notificationInboxDetailState
    ) as string;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [selectedItems, setSelectedItems] = useState<Schedule[]>([]);
    const [deselectedItems, setDeselectedItems] = useState<Schedule[]>([]);
    const user = useSelector((state: any) => state.user.user) as IUser;
    const [employeeId, setEmployeeId] = useState<string>("");
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (!notificationDetail && notificationInboxDetailState !== PENDING) {
            handleBack();
        }
    }, [notificationDetail, notificationInboxDetailState]);

    useEffect(() => {
        if (notificationInboxDetailState === REJECTED) {
            handleBack();
        }
    }, [notificationDetail, notificationInboxDetailState]);

    useEffect(() => {
        setDeselectedItems(notificationDetail?.schedules || []);
    }, [notificationDetail]);

    useEffect(() => {
        if (user) {
            setEmployeeId(user.id!.toString());
        }
    }, [user]);

    const handleRejectClick = () => {
        setIsRejectModalOpen(true);
    };

    const handleRejectConfirm = () => {
        setIsRejectModalOpen(false);
        handleSubmission(SwapScheduleActionEnum.REJECT);
    };

    const test = () => {
        console.log("notificationDetail :::: ", notificationDetail);
        console.log("selectedItems :::: ", selectedItems);
        console.log("deselectedItems :::: ", deselectedItems);
    };

    const getStartEndFormatted = (schedule: Schedule) => {
        const startDateTime = dayjs(schedule.osEmpStartDateTime).format("MMMM D, h:mm A");
        const endDateTime = dayjs(schedule.osEmpEndDateTime).format("h:mm A");
        return `${startDateTime} - ${endDateTime}`;
    };

    const getUserName = (detail?: InboxDetailType) => {
        return `${detail?.shiftTransaction?.employee?.os_emp_first_name} ${detail?.shiftTransaction?.employee?.os_emp_last_name}`;
    };

    const handleSelect = (schedule: Schedule, checked: boolean) => {
        if (checked) {
            setSelectedItems((prev) => [...prev, schedule]);
            setDeselectedItems((prev) => prev.filter((item) => item.id !== schedule.id));
        } else {
            setSelectedItems((prev) => prev.filter((item) => item.id !== schedule.id));
            setDeselectedItems((prev) => [...prev, schedule]);
        }
    };

    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            setSelectedItems(notificationDetail?.schedules || []);
            setDeselectedItems([]);
        } else {
            setSelectedItems([]);
            setDeselectedItems(notificationDetail?.schedules || []);
        }
    };

    const handleRadioSelect = (schedule: Schedule) => {
        setSelectedItems([schedule]);
        setDeselectedItems((notificationDetail?.schedules || []).filter((item) => item.id !== schedule.id));
    };

    const handleSubmission = (type: SwapScheduleActionEnum) => {
        // Initialize two maps, one for approved schedules and one for rejected schedules.
        let approved: EmployeeSchedulesMap = {};
        let rejected: EmployeeSchedulesMap = {};

        // Check if the action type is "APPROVE"
        if (type === SwapScheduleActionEnum.APPROVE) {
            // If the action is APPROVE, selected items should be considered as approved.
            approved = selectedItems.reduce((acc, schedule) => {
                const empId = schedule.osEmpAccountId ? schedule.osEmpAccountId.toString() : undefined;
                const scheduleId = schedule.id ? schedule.id.toString() : undefined;

                // Ensure empId and scheduleId are defined before proceeding.
                if (empId && scheduleId) {
                    // If this employee is not already in the map, initialize an empty array for them.
                    if (!acc[empId]) {
                        acc[empId] = [];
                    }
                    // Add the schedule ID to the employee's list of approved schedules.
                    acc[empId].push(scheduleId);
                }

                return acc; // Return the accumulated result.
            }, {} as EmployeeSchedulesMap);

            // The deselected items should be considered as rejected for the APPROVE action.
            rejected = deselectedItems.reduce((acc, schedule) => {
                const empId = schedule.osEmpAccountId ? schedule.osEmpAccountId.toString() : undefined;
                const scheduleId = schedule.id ? schedule.id.toString() : undefined;

                // Ensure empId and scheduleId are defined before proceeding.
                if (empId && scheduleId) {
                    // If this employee is not already in the map, initialize an empty array for them.
                    if (!acc[empId]) {
                        acc[empId] = [];
                    }
                    // Add the schedule ID to the employee's list of rejected schedules.
                    acc[empId].push(scheduleId);
                }

                return acc; // Return the accumulated result.
            }, {} as EmployeeSchedulesMap);
        } else if (type === SwapScheduleActionEnum.REJECT) {
            // If the action is REJECT, selected items should be considered as rejected.
            rejected = [...selectedItems, ...deselectedItems].reduce((acc, schedule) => {
                const empId = schedule.osEmpAccountId ? schedule.osEmpAccountId.toString() : undefined;
                const scheduleId = schedule.id ? schedule.id.toString() : undefined;

                // Ensure empId and scheduleId are defined before proceeding.
                if (empId && scheduleId) {
                    // If this employee is not already in the map, initialize an empty array for them.
                    if (!acc[empId]) {
                        acc[empId] = [];
                    }
                    // Add the schedule ID to the employee's list of rejected schedules.
                    acc[empId].push(scheduleId);
                }

                return acc; // Return the accumulated result.
            }, {} as EmployeeSchedulesMap);

            // For REJECT action, we don't need to set the approved schedules, so it remains empty.
        }

        // Construct the request payload for the API call.
        let data: UpdateSwapScheduleRequest = {
            userAccountId: user.userAccountId!, // User who triggered the notification.
            wfCode: notificationDetail?.workflowTransactionLines?.wfEventJson?.WF_CODE!, // Workflow code for the action.
            referenceId: notificationDetail?.notification?.alertJson?.REF_ID, // Reference ID for the notification.
            actorId: employeeId, // The logged-in user's employee ID.
            status: type === SwapScheduleActionEnum.APPROVE ? true : false, // Status depends on the action type.
            result: {
                APPROVED: approved, // Approved schedules (if any).
                REJECTED: rejected, // Rejected schedules.
            },
        };

        // Dispatch the action to update the swap request with the newly formed data.
        dispatch(updateSwapRequest(data));

        // Navigate back after submission.
        handleBack();
    };

    const isTransactionDone = () => {
        const transactionStatus = notificationDetail?.workflowTransactionLines?.currentStatusCodeTxn;
        console.log("** current wf txn line status=", transactionStatus);
        if (transactionStatus == 1 || transactionStatus == -1) {
            console.log("** isTransactionDone=true");
            return true; // if transaction status 1 then true, else false
        }
        console.log("** isTransactionDone=false");
        return false;
    };

    const GetHeading = (): string => {
        let Header = "";
        if (notificationDetail?.notification?.notificationCategory === NotificationCategoryEnum.EMP_SHIFT_SWAPPING) {
            Header = "Swapping Request";
        }

        return Header;
    };

    const GetSchedulesByStatus = (status: "APPROVED" | "REJECTED"): Schedule[] => {
        const schedules: Schedule[] = [];

        // Safely access the `RESULT?.[status]`
        const statusResult = notificationDetail?.workflowTransactionLines?.wfTaskActedJson?.RESULT?.[status];
        console.log(`${status} statusResult`, statusResult);
        if (notificationDetail?.schedules && statusResult) {
            // Get the schedule IDs based on status (approved/rejected)
            const scheduleIds = Object.values(statusResult).flat();

            // Loop through the IDs and find the corresponding schedules
            scheduleIds.forEach((id) => {
                const schedule = notificationDetail?.schedules?.find((s) => s.id === Number(id));
                if (schedule) schedules.push(schedule);
            });
        }

        return schedules;
    };

    const CheckIsApprovedOrSelected = (schedule: Schedule): boolean => {
        let result = false;

        const alreadyAppoved = () => {
            if (notificationDetail?.workflowTransactionLines?.wfTaskActedJson?.RESULT?.APPROVED) {
                const approvedScheduleIds = Array.from(
                    new Set(
                        Object.values(
                            notificationDetail?.workflowTransactionLines?.wfTaskActedJson?.RESULT?.APPROVED || {}
                        ).flat()
                    )
                );

                return approvedScheduleIds.includes(schedule.id!.toString());
            }
        };

        const alreadySelected = () => {
            return selectedItems.some((item) => item.id === schedule.id);
        };

        if (isTransactionDone()) {
            result = alreadyAppoved() || alreadySelected();
        } else {
            result = alreadySelected();
        }

        return result;
    };

    const GetSchedules = () => {
        const ApprovedByManager = notificationDetail?.workflowTransactionLines?.wfEventJson?.SWAPPING_DETAILS?.Approved;

        // if logged in user is not manager and there are schedules approved by manager
        if (!isManagerV2() && ApprovedByManager) {
            console.log("Approved", Object.values(ApprovedByManager).flat());
            const val = Object.values(ApprovedByManager).flat();

            return notificationDetail?.schedules?.filter((s) => val.includes(s.id!.toString())) || [];
        }
        return notificationDetail?.schedules || [];
    };

    const GetCurrentSchedule = (schedules?: Schedule[], scheduleId?: number): Schedule | undefined => {
        if (!scheduleId || !schedules) return undefined;
        return schedules.find((s) => s.id === scheduleId);
    };

    return (
        <>
            <Box
                sx={{
                    maxHeight: "100vh",
                    overflowY: "auto", // This makes the entire content scrollable
                    paddingBottom: 10,
                }}
            >
                {notificationInboxDetailState === PENDING ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress color="inherit" />
                    </Box>
                ) : (
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" mb={2} justifyContent={"space-between"}>
                                <Button
                                    startIcon={<ArrowBackIcon />}
                                    onClick={handleBack}
                                    variant="text"
                                    color="primary"
                                >
                                    Back
                                </Button>
                                <Typography variant="h6" ml={2}>
                                    {GetHeading()}
                                </Typography>
                            </Box>
                            <Divider />
                            <Box display="flex" alignItems="center" mt={2}>
                                <Avatar
                                    alt="altImage"
                                    src={
                                        FetchMatchingEmployee(
                                            Number(notificationDetail?.shiftTransaction?.osEmpAccountIdOrig),
                                            notificationDetail?.employees
                                        )?.osEmpImageName || ""
                                    }
                                />
                                <Box ml={2}>
                                    <Typography variant="body1">
                                        {getUserName(notificationDetail)} (
                                        {isManagerV2(
                                            notificationDetail,
                                            notificationDetail?.shiftTransaction?.employee?.user_account_id
                                        )
                                            ? "Manager"
                                            : "Employee"}
                                        )
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {getActionString(
                                            notificationDetail?.notification
                                                ?.notificationCategory as NotificationCategoryEnum
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography variant="body2" color="textSecondary" mt={1}>
                                Comments: {notificationDetail?.shiftTransaction?.osEmpOrigComments}
                            </Typography>
                            {notificationDetail?.shiftTransaction?.employeeSchedule && (
                                <Box mt={2} p={1} border={1} borderColor="grey.300" borderRadius={1}>
                                    <Typography variant="body2">
                                        {DateTimeUtil.formatDateTimeRangeUtc(
                                            notificationDetail?.shiftTransaction?.employeeSchedule?.osEmpStartDateTime,
                                            notificationDetail?.shiftTransaction?.employeeSchedule?.osEmpEndDateTime
                                        )}
                                    </Typography>
                                    {notificationDetail?.shiftTransaction?.employeeSchedule?.title && (
                                        <Typography variant="body2">
                                            {notificationDetail?.shiftTransaction?.employeeSchedule?.title}
                                        </Typography>
                                    )}
                                    <Typography variant="body2">
                                        {notificationDetail?.shiftTransaction?.employeeSchedule?.departmentCode}
                                    </Typography>

                                    {notificationDetail?.shiftTransaction?.manager && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <Typography variant="body2" sx={{ mr: 1 }}>
                                                Manager:
                                            </Typography>

                                            <Typography variant="body2">
                                                {GetUserFullName(notificationDetail?.shiftTransaction?.manager)}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            )}

                            {!isTransactionDone() && (
                                <Box display="flex" justifyContent="center" mt={2}>
                                    <Icon color="primary" sx={{ fontSize: 40 }}>
                                        <SyncIcon sx={{ fontSize: 40 }} />
                                    </Icon>
                                </Box>
                            )}

                            {/* 
                                SECTION TO DISPLAY SHIFT DETAILS AFTER APPROVAL
                            */}

                            {/* isManagerV2(notificationDetail, employeeId) && */}
                            {isTransactionDone() && GetSchedulesByStatus("APPROVED").length > 0 && (
                                <Box
                                    sx={{
                                        mt: 2,
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        maxHeight: "500px", // Limit the height and make the container scrollable
                                        overflowY: "auto", // Enable vertical scrolling
                                    }}
                                >
                                    {RenderEmployeeAndSchedules({
                                        schedules: GetSchedulesByStatus("APPROVED"),
                                        title: "Approved Schedules",
                                        employees: notificationDetail?.employees,
                                    })}
                                </Box>
                            )}

                            {isTransactionDone() && GetSchedulesByStatus("REJECTED").length > 0 && (
                                <Box
                                    sx={{
                                        mt: 2,
                                        display: "flex",
                                        width: "100%",
                                        flexDirection: "column",
                                        maxHeight: "500px", // Limit the height and make the container scrollable
                                        overflowY: "auto", // Enable vertical scrolling
                                    }}
                                >
                                    {RenderEmployeeAndSchedules({
                                        schedules: GetSchedulesByStatus("REJECTED"),
                                        title: "Rejected Schedules",
                                        employees: notificationDetail?.employees,
                                    })}
                                </Box>
                            )}

                            {/* 
                                ENDS - SECTION TO DISPLAY SHIFT DETAILS AFTER APPROVAL
                            */}

                            {/* 
                                POTENTIAL ACCEPTOR SECTION WHEN TRANSACTION IS NOT DONE
                            */}
                            {!isTransactionDone() && (
                                <Box>
                                    <Typography variant="h6" mt={2}>
                                        To Potential Acceptors:
                                    </Typography>

                                    {/* Manager select all checkbox */}
                                    {isManagerV2(notificationDetail, employeeId) && (
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            selectedItems.length ===
                                                            notificationDetail?.schedules?.length
                                                        }
                                                        onChange={(e) => handleSelectAll(e.target.checked)}
                                                        color="primary"
                                                        disabled={isTransactionDone()}
                                                    />
                                                }
                                                label="Select All"
                                            />
                                        </Box>
                                    )}
                                    <Box sx={{ maxHeight: 300, overflow: "auto" }}>
                                        <List>
                                            {GetSchedules().map((schedule) => {
                                                const osEmpAccountId = schedule.osEmpAccountId;
                                                const employee = FetchMatchingEmployee(
                                                    osEmpAccountId,
                                                    notificationDetail?.employees
                                                );
                                                return (
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar src={employee?.osEmpImageName || ""} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="primary"
                                                                    >
                                                                        {/* {notificationDetail?.workflowTransactionLines?.stepCode ===
                                                            "3"
                                                                ? GetUserFullName(
                                                                      notificationDetail.workflowTransactionLines
                                                                          ?.ApprovedEmployee!
                                                                  )
                                                                : GetUserFullName(schedule!.employee!)} */}
                                                                        {GetUserFullName(employee!)}
                                                                    </Typography>
                                                                </>
                                                            }
                                                            secondary={
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    {DateTimeUtil.formatDateTimeRangeUtc(
                                                                        schedule.osEmpStartDateTime,
                                                                        schedule.osEmpEndDateTime
                                                                    )}
                                                                </Typography>
                                                            }
                                                        />
                                                        {isManagerV2(notificationDetail, employeeId) ? (
                                                            <Checkbox
                                                                checked={CheckIsApprovedOrSelected(schedule)}
                                                                onChange={(e) =>
                                                                    handleSelect(schedule, e.target.checked)
                                                                }
                                                                disabled={isTransactionDone()}
                                                            />
                                                        ) : (
                                                            <Radio
                                                                checked={CheckIsApprovedOrSelected(schedule)}
                                                                onChange={() => handleRadioSelect(schedule)}
                                                                disabled={isTransactionDone()}
                                                            />
                                                        )}
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Box>
                                    <Divider />

                                    <Divider />
                                    <Box display="flex" justifyContent="flex-end" mt={2}>
                                        <Button
                                            disabled={isTransactionDone()}
                                            onClick={handleRejectClick} //() => handleSubmission(SwapScheduleActionEnum.REJECT)
                                            sx={{
                                                backgroundColor: isTransactionDone() ? "grey.300" : "red", // Make reject button red
                                                "&:hover": {
                                                    backgroundColor: isTransactionDone() ? "grey.300" : "darkred", // Darker red on hover
                                                },
                                                mr: 2,
                                            }}
                                        >
                                            <Typography sx={{ color: isTransactionDone() ? "grey.600" : "white" }}>
                                                Reject all
                                            </Typography>
                                        </Button>

                                        <Button
                                            disabled={isTransactionDone()}
                                            onClick={() => handleSubmission(SwapScheduleActionEnum.APPROVE)}
                                            sx={{
                                                backgroundColor: isTransactionDone() ? "grey.300" : "#4caf50", // Brighter green for Approve button
                                                "&:hover": {
                                                    backgroundColor: isTransactionDone() ? "grey.300" : "#43a047", // Slightly darker green on hover
                                                },
                                            }}
                                        >
                                            <Typography sx={{ color: isTransactionDone() ? "grey.600" : "white" }}>
                                                {isManagerV2(notificationDetail, employeeId) ? "Approve" : "Accept"}
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
            <Dialog
                open={isRejectModalOpen}
                onClose={() => setIsRejectModalOpen(false)}
                aria-labelledby="reject-dialog-title"
                aria-describedby="reject-dialog-description"
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "12px", // Rounded corners
                        padding: "16px", // More space around content
                        boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)", // Elegant shadow
                        backgroundColor: "#ffffff", // Soft white background
                    },
                }}
            >
                <DialogTitle
                    id="reject-dialog-title"
                    sx={{
                        color: "#d32f2f", // A more muted, elegant red
                        fontWeight: 600, // Slightly bolder text for emphasis
                        fontSize: "1.25rem", // Larger title for better readability
                        // textAlign: "center", // Center-align the title for symmetry
                    }}
                >
                    Confirm Rejection
                </DialogTitle>
                <DialogContent
                    sx={{
                        padding: "8px 24px", // Add spacing for better content separation
                        // textAlign: "center", // Center-align the content for elegance
                    }}
                >
                    <DialogContentText
                        id="reject-dialog-description"
                        sx={{
                            fontSize: "1rem", // Clean and readable font size
                            color: "#555555", // Soft gray for the text
                        }}
                    >
                        Are you sure you want to reject the selected shifts? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        // justifyContent: "space-between", // Spread buttons evenly
                        padding: "16px", // Ensure buttons have ample padding
                    }}
                >
                    {/* Cancel Button */}
                    <Button
                        onClick={() => setIsRejectModalOpen(false)}
                        sx={{
                            color: "#ffffff", // White text for contrast
                            backgroundColor: "#f44336", // Red button color
                            "&:hover": {
                                backgroundColor: "#d32f2f", // Darker red on hover
                            },
                            borderRadius: "8px", // Slightly rounded buttons for modern feel
                            padding: "8px 16px", // More padding for a tactile feel
                            textTransform: "none", // No uppercase text for a cleaner look
                            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for button
                        }}
                    >
                        Cancel
                    </Button>

                    {/* Confirm Button */}
                    <Button
                        onClick={handleRejectConfirm}
                        sx={{
                            color: "#ffffff", // White text for contrast
                            backgroundColor: "#4caf50", // Green button color
                            "&:hover": {
                                backgroundColor: "#388e3c", // Darker green on hover
                            },
                            borderRadius: "8px", // Slightly rounded buttons for modern feel
                            padding: "8px 16px", // More padding for a tactile feel
                            textTransform: "none", // No uppercase text for a cleaner look
                            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for button
                        }}
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NotificationDetail;
