import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
// import testSlice from "./features/test/testSlice";
import ScheduleSlice from "./features/mySchedule/ScheduleSlice";
import UserSlice from "./features/user/UserSlice";
import LayoutSlice from "./features/layout/LayoutSlice";
import ScheduleNotificationSlice from "./features/notifications/scheduleNotification/ScheduleNotificationSlice";
import { PayperiodSliceReducer } from "./features/payperiod";
import { TimesheetReducer } from "./features/timesheet";
import { NotificationReducer } from "./features/notifications";
import { TimesheetV2Reducer } from "./features/timesheetv2";

export const rootReducer = combineReducers({
    mySchedule: ScheduleSlice,
    user: UserSlice,
    layout: LayoutSlice,
    schNotification: ScheduleNotificationSlice,
    payperiod: PayperiodSliceReducer,
    timesheet: TimesheetReducer,
    notification: NotificationReducer,
    timesheetV2: TimesheetV2Reducer
});

// dispatch does not take types for thunks into account and thus the return type is typed incorrectly. Please use the actual Dispatch type from the store as decsribed in the documentation. Ref: https://stackoverflow.com/questions/63811401/property-then-does-not-exist-on-type-asyncthunkaction-redux-toolkit
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
    reducer: rootReducer,
});
