import React from "react";
import SideBar from "../components/SideBar";
import { Navigate, Route, Routes } from "react-router-dom";
import { DrawerRoutes } from "../routes";
import { styled } from "@mui/material";
import { DRAWERWIDTH } from "../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const MainSidePanel = styled("div", {
    shouldForwardProp: (prop) => prop !== "open",
})<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    // height: `calc(100vh - 160px)`,
    height: "-webkit-fill-available",
    // overflow: "hidden",
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `${DRAWERWIDTH}px`,
    }),
    [theme.breakpoints.up("md")]: {
        marginLeft: `${DRAWERWIDTH}px`,
    },
}));

export const Main = () => {
    const isSidebarOpen = useSelector((state: RootState) => state.layout.sidebar);
    return (
        <React.Fragment>
            <SideBar />
            <MainSidePanel open={isSidebarOpen} sx={{}}>
                <Routes>
                    {/* Redirect to /Schedule as the default */}
                    <Route path="/" element={<Navigate to="/Schedule" />} />
                    {DrawerRoutes.map((props, key) => {
                        return <Route path={props.path} element={<props.component />} key={props.path} />;
                    })}
                </Routes>
            </MainSidePanel>
        </React.Fragment>
    );
};
