import { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { CustomizeTheme } from "./CustomizeTheme";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { getUserDetails, getUserDetailsMe } from "./redux/features/user/UserApi";
import { useAppDispatch } from "./redux/store";
import { ThemeContextProvider, ThemeType } from "./theme";

function App() {
  console.info(
    "Application Running On",
    process.env.REACT_APP_ENV,
    process.env.REACT_APP_BACKEND_URL
  );

  const dispatch = useAppDispatch();

  dispatch(getUserDetails());
  

  // return (
  //   <Suspense fallback={<CircularProgress />}>
  //     <ThemeProvider theme={CustomizeTheme}>
  //       <RouterProvider router={router} />
  //     </ThemeProvider>
  //   </Suspense>
  // );

  return (
    <Suspense fallback={<CircularProgress />}>
      <ThemeContextProvider initialThemeType={ThemeType.DEFAULT}>
        <RouterProvider router={router} />
      </ThemeContextProvider>
    </Suspense>
  );

}

export default App;
