import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Audit, Note, TimesheetType } from "../../types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back icon
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Box,
    Grid,
    Typography,
    Button,
    Divider,
    Paper,
    useMediaQuery,
    IconButton,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    TableHead,
    CircularProgress,
    Modal,
    Drawer
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DateTimeUtil } from "../../utils";
import { useAppDispatch } from "../../redux/store";
import { getAudit, getNotes } from "../../redux/features/timesheetv2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { FULFILLED, PENDING } from "../../redux/constants";
import { UserType } from "../../types/state/user";
import AddEditForm from "./addEditForm";
import { setAllowRefetch } from "../../redux/features/timesheetv2/slice";
import TimesheetDetailsWeb from "./web/timesheetDetails.web";
const RecordDetailsComponent: React.FC = () => {
    const timesheet = useSelector((state: any) => state.timesheetV2.selectedRecord) as TimesheetType;
    const requestStatus = useSelector((state: any) => state.timesheetV2.requestStatus);
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm")); // Detect mobile view
    const audits = useSelector((state: any) => state.timesheetV2.audits) as Audit[];
    const notes = useSelector((state: any) => state.timesheetV2.notes) as Note[];
    const user = useSelector((state: any) => state.user.user) as UserType;
    const dispatch = useAppDispatch();
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isAddMode, setIsAddMode] = useState<boolean>(false);
    const [isDeleteMode, setIsDeleteMode] = useState<boolean>(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to manage the sidebar

    useEffect(() => {
        console.log("***user", user);
    }, []);

    const toggleDrawer = (open: boolean) => {
        setIsDrawerOpen(open);
    };
    useEffect(() => {
        if (requestStatus === FULFILLED && !timesheet) {
            handleBack();
        }
    }, [requestStatus, timesheet, navigate]);

    useEffect(() => {
        if (timesheet?.start_datetime) {
            const dateTime = DateTimeUtil.parseDate(timesheet?.start_datetime);
            setStartTime(dateTime);
        }
    }, [timesheet?.start_datetime]);

    useEffect(() => {
        if (timesheet?.end_datetime) {
            const dateTime = DateTimeUtil.parseDate(timesheet?.end_datetime);
            setEndTime(dateTime);
        }
    }, [timesheet?.end_datetime]);

    useEffect(() => {
        if (timesheet?.emp_account_id && timesheet?.id) {
            const body = {
                id: timesheet?.id,
                employee_id: user.os_emp_account_id!,
            };
            dispatch(getAudit(body));
            dispatch(getNotes(body));
        }
    }, []);

    // Extracting the date and the timesheet array (since it's a grouped object with one date key)
    const renderAuditDetails = (auditLogs: Audit[]) => {
        return (
            <Accordion
                sx={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                }}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Audit Logs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Action</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Updated Column</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Current Value</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>New Value</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Created</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Created By</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {auditLogs.map((audit, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{audit.action}</TableCell>
                                        <TableCell>{audit.column_name_updated}</TableCell>
                                        <TableCell>{audit.column_orig_value}</TableCell>
                                        <TableCell>{audit.column_new_value}</TableCell>
                                        <TableCell>{DateTimeUtil.formatDate(audit.created)}</TableCell>
                                        <TableCell>{audit.created_by}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderNotes = (notes: Note[]) => {
        return (
            <Accordion
                sx={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                }}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Notes</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Created By</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Created</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Updated</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Updated By</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {notes.map((note, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{note.notes_json?.notes || ""}</TableCell>
                                        <TableCell>
                                            {note.first_name || ""} {note.last_name || ""}
                                        </TableCell>
                                        <TableCell>{DateTimeUtil.formatDate(note.created) || ""}</TableCell>
                                        <TableCell>{DateTimeUtil.formatDate(note.updated) || ""}</TableCell>
                                        <TableCell>{note.updated_by || ""}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        );
    };

    const onDeleteModeOn = () => {
        setIsDeleteMode(true);
    };

    const onDeleteModeClose = () => {
        setIsDeleteMode(false);
    };

    const handleBack = () => {
        dispatch(setAllowRefetch(false));
        navigate(-1); // Go back to the previous page
    };

    const handleEditClick = () => {
        setIsEditMode(true);
    };

    const handleSaveClick = () => {
        setIsEditMode(false);
    };

    const handleAddClick = () => {
        setIsAddMode(true);
    };

    const test = () => {
        console.log("audit", audits);
    };

    const ActionableDrawer = () => {
        return (
            <Drawer anchor="right" open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
                <Box
                    sx={{
                        width: 250,
                        padding: 2,
                        display: "flex",
                        flexDirection: "column",
                    }}
                    role="presentation"
                    onClick={() => toggleDrawer(false)}
                >
                    <Typography variant="h6">Right Sidebar Content</Typography>
                    <Typography variant="body1">
                        Add any content you want here, such as additional details, actions, etc.
                    </Typography>
                </Box>
            </Drawer>
        );
    };

    const renderTimesheetDetails = (timesheet: TimesheetType) => {
        return (
            <Box sx={{ p: 1, backgroundColor: "transparent" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        position: "relative",
                        mb: 2,
                    }}
                >
                    <IconButton color="primary" onClick={handleBack} sx={{ mr: 1 }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Box onClick={test}>
                        <Typography color={"primary"}>Test</Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <AddCircleIcon sx={{ fontSize: "24px", mr: 1 }} onClick={handleAddClick} />
                        {isEditMode ? (
                            <SaveIcon sx={{ fontSize: "24px", mr: 1 }} onClick={handleSaveClick} />
                        ) : (
                            <EditIcon sx={{ fontSize: "24px", mr: 1 }} onClick={handleEditClick} />
                        )}
                        <DeleteIcon sx={{ fontSize: "24px", mr: 1 }} onClick={onDeleteModeOn} />
                    </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: "bold" }}>Date:</Typography>
                        <Typography sx={{ ml: 1 }}>
                            {DateTimeUtil.getFormattedDate(timesheet?.start_datetime!)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: "bold" }}>Start:</Typography>
                        <Typography sx={{ ml: 1 }}>
                            {DateTimeUtil.getFormattedTime(timesheet?.start_datetime!)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: "bold" }}>End:</Typography>
                        <Typography sx={{ ml: 1 }}>
                            {DateTimeUtil.getFormattedTime(timesheet?.end_datetime!)}
                        </Typography>
                    </Box>
                </Box>

                {/* Other Details in Table */}
                {isMobile ? (<TableContainer component={Paper} sx={{ boxShadow: "none", backgroundColor: "transparent" }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" color="textPrimary">
                                        <strong>Regular Hours:</strong>
                                    </Typography>
                                </TableCell>
                                <TableCell>{timesheet?.regular_hours || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" color="textPrimary">
                                        <strong>Overtime Hours:</strong>
                                    </Typography>
                                </TableCell>
                                <TableCell>{timesheet?.overtime_hours || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" color="textPrimary">
                                        <strong>Doubletime Hours:</strong>
                                    </Typography>
                                </TableCell>
                                <TableCell>{timesheet?.doubletime_hours || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" color="textPrimary">
                                        <strong>Department:</strong>
                                    </Typography>
                                </TableCell>
                                <TableCell>{timesheet?.department?.name || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" color="textPrimary">
                                        <strong>Site Code:</strong>
                                    </Typography>
                                </TableCell>
                                <TableCell>{timesheet?.site?.code || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" color="textPrimary">
                                        <strong>Break Times:</strong>
                                    </Typography>
                                </TableCell>
                                <TableCell>{timesheet?.breaktimes_json?.unpaidbreaktime || ""}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" color="textPrimary">
                                        <strong>Deduction:</strong>
                                    </Typography>
                                </TableCell>
                                <TableCell>{timesheet?.deduction || ""}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>): (<TimesheetDetailsWeb timesheet={timesheet}/>)}
            </Box>
        );
    };

    const renderMainUi = () => {
        if (isEditMode || isAddMode) {
            return (
                <AddEditForm
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    isAddMode={isAddMode}
                    setIsAddMode={setIsAddMode}
                    timesheet={timesheet}
                    notes={notes}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                />
            );
        } else {
            return (
                <>
                    <Box
                        sx={{
                            height: "100%",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",
                            background: "transparent",
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Box sx={{ paddingX: 1, paddingY: 1, background: "transparent" }}>
                                {renderTimesheetDetails(timesheet)}

                                {audits.length > 0 && <Box sx={{}}>{renderAuditDetails(audits)}</Box>}

                                {notes.length > 0 && <Box sx={{}}>{renderNotes(notes)}</Box>}
                            </Box>
                        </Box>
                    </Box>
                </>
            );
        }
    };

    const DeleteModal = () => {
        return (
            <Modal
                open={isDeleteMode}
                onClose={onDeleteModeClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{}}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Modal Title
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        This is an example modal.
                    </Typography>
                    <Button onClick={onDeleteModeClose} sx={{ mt: 2 }} variant="contained">
                        Close
                    </Button>
                </Box>
            </Modal>
        );
    };

    return (
        <>
            {requestStatus === PENDING ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress color="inherit" />
                </Box>
            ) : (
                renderMainUi()
            )}

            <DeleteModal />
            <ActionableDrawer/>
        </>
    );
};

export default RecordDetailsComponent;
