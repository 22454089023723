import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/features/layout/LayoutSlice";
import { RootState } from "../../redux/store";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/PowerSettingsNew";
import { Avatar, Typography } from "@mui/material";
import UserIcon from '@mui/icons-material/PersonOutline';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { providePhpRoute } from "../../routes/phpRoutes";
import { PhpRoute } from "../../enums";

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
}));

export default function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSidebarOpen = useSelector((state: RootState) => state.layout.sidebar);
    const User = useSelector((state: RootState) => state.user.user);
    const handleToggleSidebar = () => {
        dispatch(toggleSidebar(!isSidebarOpen));
    };

    // Use useRef to anchor the menu to the AccountCircle icon
    const accountCircleRef = React.useRef<HTMLButtonElement | null>(null);

    // Control menu state
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    // Handle mouse hover and leave for the account icon
    const handleMenuOpen = () => {
        if (accountCircleRef.current) {
            setAnchorEl(accountCircleRef.current);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const navToProfile = () => {
        handleMenuClose();
        window.open(providePhpRoute(PhpRoute.PROFILE), "_blank", "noopener,noreferrer");
    };

    const handleLogout = () => {
        handleMenuClose();
        window.location.href = providePhpRoute(PhpRoute.LOGOUT);
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            disableAutoFocus // Disabling autofocus
            disableAutoFocusItem
            MenuListProps={{
                onMouseLeave: handleMenuClose, // Close menu on mouse leave
            }}
        >
            {/* <MenuItem onClick={navToProfile}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        cursor: "pointer",
                        flexDirection: "row",
                    }}
                >
                    <UserIcon fontSize="small" />
                    <Typography>Profile</Typography>
                </Box>
            </MenuItem> */}
            {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
            <MenuItem onClick={handleLogout}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        cursor: "pointer",
                        flexDirection: "row",
                    }}
                >
                    <LogoutIcon fontSize="small" />
                    <Typography>Logout</Typography>
                </Box>
            </MenuItem>
        </Menu>
    );

    const navToNotificationScreen = () => {
        navigate("/notifications");
    };

    const navToSchedules = () => { 
        navigate("/Schedule");
    }

    const navToDashboard = () => {
        window.open(providePhpRoute(PhpRoute.HOME), "_blank", "noopener,noreferrer");
        // navigate("/Dashboard");
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" elevation={1}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge color="error">
                                <HomeIcon onClick={navToDashboard} />
                            </Badge>
                        </IconButton>
                        {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge color="error">
                                <InfoIcon onClick={navToKnowledgeBase}/>
                            </Badge>
                        </IconButton> */}
                        {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge color="error">
                                <CalendarIcon onClick={navToSchedules}/>
                            </Badge>
                        </IconButton> */}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                            onClick={navToNotificationScreen}
                        >
                            <Badge color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                            ref={accountCircleRef} // Attach ref here
                            onMouseEnter={handleMenuOpen} // Open menu on hover
                        >
                            {/* <AccountCircle /> */}
                            <Avatar
                                alt={User.osEmpFirstName + " " + User.osEmpLastName}
                                src={User.osEmpImageName ?? ""}
                                sx={{ width: 32, height: 32 }}
                            />
                        </IconButton>
                        {renderMenu}

                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ ml: 2, display: { xs: "flex", md: "none" } }}
                            onClick={handleToggleSidebar}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
