import { createSlice} from "@reduxjs/toolkit";
import {
    FULFILLED,
    PENDING,
    REJECTED
} from '../../../constants'
import {
    getContent,
    getManagerTxns,
    getMyTxns,
    getAvailPickups,
    getAvailTrades,
    getTradeShifts
} from './ScheduleNotificationApi'
import { AVAIL_TO_PICKUP, AVAIL_TO_TRADE, MANAGE_REQUESTS } from "../../../../utils/constants";

export const ScheduleNotificationSlice = createSlice({
    name: 'scheduleNotification',
    initialState: {
        test: 'test',
        requestState: FULFILLED,
        txnsForManager: [] as any[],
        txnsForUser: [] as any[],
        availPickupTxns: [] as any[],
        availTradeTxns: [] as any[],
        selectedRequest: null as any,
        sideBarContent: [] as any[],
        items: [] as any[],
        filterOption: null as any
    },
    reducers: {
        test: () => {
        },
        selectRequest: (state: any, { payload }: any) => {
            state.sideBarContent = [];
            var selectedTxns = null;

            switch (payload.type) {
                case MANAGE_REQUESTS:
                    selectedTxns = state.txnsForManager.find((txn: any) => txn.id === payload.id);
                    break;
                case AVAIL_TO_PICKUP:
                    selectedTxns = state.availPickupTxns.find((txn: any) => txn.id === payload.id);
                    break;
                case AVAIL_TO_TRADE:
                    selectedTxns = state.availTradeTxns.find((txn: any) => txn.id === payload.id);
                    break;
            }
            state.selectedRequest = {
                type: payload.type,
                txnDetail: selectedTxns
            }

        },
        clearSidebar: (state: any) => {
            state.sideBarContent = [];
            state.selectedRequest = null;
        },
        setItems: (state: any, { payload }) => {
            state.items = [...payload];
        },
        checkItem: (state: any, { payload }) => {
            state.items[payload].checked = !state.items[payload].checked;
        },
        checkAll: (state: any) => {
            state.items.map((item: any) => {
                return item.checked = true;
            })
        },
        unCheckAll: (state: any) => {
            state.items.map((item: any) => {
                return item.checked = false;
            })
        } 
    },
    extraReducers: {
        [getManagerTxns.pending.type]: (state, action) => {
            state.requestState = PENDING
        },
        [getManagerTxns.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                state.txnsForManager = [...payload.data];
            }
            state.requestState = FULFILLED
        },
        [getManagerTxns.rejected.type]: (state, action) => {
            state.requestState = REJECTED
        },
        [getContent.pending.type]: (state, action) => {
            state.requestState = PENDING
        },
        [getContent.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                state.sideBarContent = [...payload.data];
            } else {
                state.sideBarContent = [];
            }
            state.requestState = FULFILLED
        },
        [getContent.rejected.type]: (state, action) => {
            state.requestState = REJECTED
        },
        [getMyTxns.pending.type]: (state, action) => {
            state.requestState = PENDING
        },
        [getMyTxns.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                state.txnsForUser = [...payload.data];
            } else {
                state.txnsForUser = [];
            }
            state.requestState = FULFILLED
        },
        [getMyTxns.rejected.type]: (state, action) => {
            state.requestState = REJECTED
        },
        [getAvailPickups.pending.type]: (state, action) => {
            state.requestState = PENDING
        },
        [getAvailPickups.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                state.availPickupTxns = [...payload.data];
            } else {
                state.availPickupTxns = [];
            }
            state.requestState = FULFILLED
        },
        [getAvailPickups.rejected.type]: (state, action) => {
            state.requestState = REJECTED
        },
        [getAvailTrades.pending.type]: (state, action) => {
            state.requestState = PENDING
        },
        [getAvailTrades.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                state.availTradeTxns = [...payload.data];
            } else {
                state.availTradeTxns = [];
            }
            state.requestState = FULFILLED
        },
        [getAvailTrades.rejected.type]: (state, action) => {
            state.requestState = REJECTED
        },
        [getTradeShifts.pending.type]: (state, action) => {
            state.requestState = PENDING
        },
        [getTradeShifts.fulfilled.type]: (state, { payload }) => {
            if (payload) {
                state.sideBarContent = [...payload.data];
            } else {
                state.sideBarContent = [];
            }
            state.requestState = FULFILLED
        },
        [getTradeShifts.rejected.type]: (state, action) => {
            state.requestState = REJECTED
        },
    }
})

export const {
    test,
    selectRequest,
    clearSidebar,
    setItems,
    checkAll,
    unCheckAll,
    checkItem
} = ScheduleNotificationSlice.actions;

export default ScheduleNotificationSlice.reducer;