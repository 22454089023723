import React, { useState } from 'react';
import {
  Box,
  Avatar,
  Typography,
  Tab,
  Tabs,
  Button,
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const ProfilePage = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tabValue, setTabValue] = useState(0);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', height: '100vh', overflow: 'auto', padding: 2 }}>
      {/* Profile Header */}
      <Box sx={{ width: '100%', bgcolor: 'green', color: 'white', padding: 2, display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt="Charlotte Danielle Abbott"
          src="https://example.com/charlotte.jpg"
          sx={{ width: 100, height: 100, border: '2px solid white' }}
        />
        <Box sx={{ marginLeft: 2 }}>
          <Typography variant="h5">Charlotte Danielle Abbott</Typography>
          <Typography variant="subtitle1">Sr. HR Administrator</Typography>
        </Box>
        <Box sx={{ marginLeft: 'auto' }}>
          <Button variant="contained" color="primary" sx={{ marginRight: 1 }}>
            Request a Change
          </Button>
          <IconButton color="inherit" onClick={handleClick}>
            <SettingsIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile Settings</MenuItem>
            <MenuItem onClick={handleClose}>Account Settings</MenuItem>
          </Menu>
        </Box>
      </Box>
      
      {/* Profile Details */}
      <Box sx={{ display: 'flex', padding: 2, borderBottom: '1px solid lightgray' }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary="801-724-6600 Ext. 1272" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="cabbott@efficientoffice.com" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LinkedInIcon />
            </ListItemIcon>
            <ListItemText primary="LinkedIn" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FacebookIcon />
            </ListItemIcon>
            <ListItemText primary="Facebook" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <TwitterIcon />
            </ListItemIcon>
            <ListItemText primary="Twitter" />
          </ListItem>
        </List>
        <List sx={{ marginLeft: 'auto' }}>
          <ListItem>
            <ListItemText primary="Hire Date" secondary="Jan 16, 2022" />
          </ListItem>
          <ListItem>
            <ListItemText primary="#1" secondary="Full Time" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Human Resources" />
          </ListItem>
        </List>
      </Box>
      
      {/* Profile Tabs */}
      <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: '1px solid lightgray' }}>
        <Tab label="Personal" />
        <Tab label="Job" />
        <Tab label="Time Off" />
        <Tab label="Documents" />
        <Tab label="Timesheet" />
        <Tab label="Benefits" />
        <Tab label="Training" />
        <Tab label="Assets" />
        <Tab label="More" />
      </Tabs>
      
      {/* Timesheet */}
      <Paper sx={{ marginTop: 2, padding: 2 }}>
        <Typography variant="h6">Timesheet</Typography>
        <Typography variant="subtitle1">Feb 1-15</Typography>
        <List>
          <ListItem>
            <ListItemText primary="Sun, Jan 28" secondary="0h 00m" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Mon, Jan 29" secondary="0h 00m" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Tue, Jan 30" secondary="0h 00m" />
          </ListItem>
        </List>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">Not Clocked In</Typography>
          <Typography variant="subtitle2">0h 00m Today</Typography>
          <Typography variant="body2">Clocked Out: Yesterday at 9:00 PM</Typography>
          <Button variant="contained" color="primary" sx={{ marginTop: 1 }}>
            Clock In
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default ProfilePage;
