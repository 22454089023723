import React, { useEffect, useState } from "react";
import { getUserDetails } from "../../services/userServices";
import { CircularProgress } from "@mui/material";

export const WelcomePage = () => {
  const [userDetails, setUserDetails] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  async function fetchUserDetails() {
    try {
      setIsLoading(true);
      const result: any = await getUserDetails();
      setUserDetails(result.data);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchUserDetails();
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <main>
      Welcome {`${userDetails?.userNameFirst} ${userDetails?.userNameLast}`}
    </main>
  );
};
