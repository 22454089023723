import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../../axios/API";
import { DROP, RELEASE, TRADE } from "../../../../utils/constants";

export const getManagerTxns = createAsyncThunk(
  "/swap/managerTxns",
  async (data: any) => {
    try {
      // console.log('data log get txns for manager ====>', data.user)
      const response = await API.post(
        "/shift-swap-transaction/txns/manager",
        data
      );
      // console.log('api call result: txns ====>', response.data);
      return response.data;
    } catch (error) {}
  }
);

export const getMyTxns = createAsyncThunk("/swap/myTxns", async (data: any) => {
  try {
    console.log("data log get txns for employee ====>", data.user);
    const response = await API.post("/shift-swap-transaction/txns/me", {
      user: data.user,
    });
    // console.log('api call result: txns ====>', response.data);
    return response.data;
  } catch (error) {
    console.log("error ===>", error);
  }
});

export const getAvailPickups = createAsyncThunk(
  "/swap/pickups",
  async (data: any) => {
    try {
      const response = await API.post(
        "/shift-swap-transaction/txns/pickups",
        data
      );
      // console.log('api call result: pickups ====>', response.data);
      return response.data;
    } catch (error) {
      console.log("error ===>", error);
    }
  }
);

export const getAvailTrades = createAsyncThunk(
  "/swap/trades",
  async (data: any) => {
    try {
      const response = await API.post(
        "/shift-swap-transaction/txns/trades",
        data
      );
      // console.log('api call result: trades ====>', response.data);
      return response.data;
    } catch (error) {
      console.log("error ===>", error);
    }
  }
);

export const getContent = createAsyncThunk(
  "/swap/content",
  async (data: any) => {
    try {
      // console.log('data log get shifts or employees ====>', data)
      var response = null;
      if (data.type === TRADE) {
        response = await API.post("/os_schedules_employee_shift/shifts", data);
      } else if (data.type === RELEASE) {
        response = await API.post("/os_employee_profile/employees", data);
      } else if (data.type === DROP) {
        return null;
      }
      // console.log('api call result: get shifts or employees ====>', response?.data);

      return response?.data;
    } catch (error) {
      console.log("error ===>", error);
    }
  }
);

export const confirmRequests = createAsyncThunk(
  "/swap/confirm",
  async (data: any) => {
    try {
      // console.log('data log get confirm requests ====>', data)
      const response = await API.post("/shift-swap-transaction/txns/confirm", {
        request: data,
      });
      // console.log('api call result: trades ====>', response.data);
      return response.data;
    } catch (error) {
      console.log("error ===>", error);
    }
  }
);

export const tradeShift = createAsyncThunk("/swap/trade", async (data: any) => {
  try {
    const response = await API.post(
      "/shift-swap-transaction/trade/confirm",
      data
    );
    // console.log('api call result: trade ====>', response.data);
    return response.data;
  } catch (error) {
    console.log("error ===>", error);
  }
});

export const releaseShift = createAsyncThunk(
  "/swap/release",
  async (data: any) => {
    try {
      // console.log('data log get release confirm requests ====>', data)
      const response = await API.post(
        "/shift-swap-transaction/release/confirm",
        data
      );
      // console.log('api call result: release ====>', response.data);
      return response.data;
    } catch (error) {
      console.log("error ===>", error);
    }
  }
);

export const getTradeShifts = createAsyncThunk(
  "/swap/shifts",
  async (data: any) => {
    try {
      // console.log('data log get trade shifts ====>', data)
      const response = await API.post(
        "/shift-swap-transaction/trade/shifts",
        data
      );
      // console.log('api call result: trade shifts ====>', response.data);
      return response.data;
    } catch (error) {
      console.log("error ===>", error);
    }
  }
);
