import { createAsyncThunk } from "@reduxjs/toolkit";
import { phpClient } from "../../../axios";
import { TimesheetType } from "../../../types";

export const getTimeSheets = createAsyncThunk(
    "timesheet/timesheets",
    async (
        data: { employeeId: string; payPeriod: string; sortBy: string; sortOrder: string; recordType: string },
        thunkAPI
    ) => {
        const { rejectWithValue } = thunkAPI;
        const request = {
            employee_id: data.employeeId,
            pay_period: data.payPeriod,
            sort_by: data.sortBy,
            sort_order: data.sortOrder,
            record_type: data.recordType,
        };

        console.log("request", JSON.stringify(request));
        try {
            const response = await phpClient.post({
                url: "/timecard/get_timesheets",
                data: {
                    employee_id: data.employeeId,
                    pay_period: data.payPeriod,
                    sort_by: data.sortBy,
                    sort_order: data.sortOrder
                    // record_type: data.recordType,
                },
            });
            console.log("response", response);
            return response as TimesheetType[];
        } catch (error) {
            
            console.error("*** getTimeSheets API error", error);
            return rejectWithValue(error);
        }
    }
);

export const getSummary = createAsyncThunk(
    "/timecard/employee_hours_summary",
    async (
        data: {
            employeeId: string;
            payPeriod: string;
        },
        thunkAPI
    ) => {
        const { rejectWithValue } = thunkAPI;
        const {employeeId, payPeriod} = data;
        try {
            const requestBody = { 
                pay_period: payPeriod,
                employee_id: employeeId,
            }
            const response = await phpClient.post({
                url: "/timecard/get_employee_hours_summary",
                data: requestBody,
            });
            return response;
        } catch (error) {
            console.error("*** getSummary API error", error);
            return rejectWithValue(error);
        }
    }
);

export const getAudit = createAsyncThunk(
    "/timecard/audit",
    async (
        data: {
            employee_id: string;
            id: string;
        },
        thunkAPI
    ) => {
        const { rejectWithValue } = thunkAPI;

        try {
            const response = await phpClient.post({
                url: "/timecard/get_audit",
                data,
            });
            return response;
        } catch (error) {
            console.error("*** getAudit API error", error);
            return rejectWithValue(error);
        }
    }
);

export const getNotes = createAsyncThunk(
    "/timecard/notes",
    async (
        data: {
            employee_id: string;
            id: string;
        },
        thunkAPI
    ) => {
        const { rejectWithValue } = thunkAPI;

        try {
            const response = await phpClient.post({
                url: "/timecard/get_notes",
                data,
            });
            return response;
        } catch (error) {
            console.error("*** getNotes API error", error);
            return rejectWithValue(error);
        }
    }
);


export const addTimecardRequest = createAsyncThunk(
    "/timecard/add",
    async (
        data: {
            timeEntryId: string;
            date:string;
            startDateTime: string;
            endDateTime: string;
            recordType: string;
            earningCode: string;
            breakTimesJson?: string;
        },
        thunkAPI
    ) => {
        const { rejectWithValue } = thunkAPI;
        const { date, earningCode, endDateTime, recordType, startDateTime, timeEntryId, } = data;
        try {
            const requestBody = {
                time_entry_id: timeEntryId,
                schedules_id: timeEntryId,
                date,
                start_datetime: startDateTime,
                end_datetime: endDateTime,
                earning_code: earningCode,
                record_type: recordType,

            };
            const response = await phpClient.post({
                url: "/timecard/employee_request",
                data: requestBody,
            });
            return response;
        } catch (error) {
            console.error("*** addTimecard API error", error);
            return rejectWithValue(error);
        }
    }
)


    