import { TimesheetGroupedByDate, TimesheetType } from "../../types";
import { Typography, List, ListItem, Paper, Grid, Divider, Box, Button, Link } from "@mui/material";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { ArrowForward, Add } from "@mui/icons-material"; // Importing the ArrowForward and Add icons
import { green } from "@mui/material/colors";

const StyledPaper = styled(Paper)(
    ({ theme }) => `
    padding: ${theme.spacing(2)};
    background-color: ${theme.palette.background.paper};
    border-radius: ${theme.shape.borderRadius};
    box-shadow: 1;
  `
);

const StyledHeader = styled(Typography)(
    ({ theme }) => `
    margin-bottom: ${theme.spacing(2)};
    display: flex;
    align-items: center;
  `
);

const StyledDateSection = styled("div")(
    ({ theme }) => `
    margin-bottom: ${theme.spacing(3)};
  `
);

const StyledTotalHours = styled(Typography)(
    ({ theme }) => `
    font-weight: bold;
    color: ${theme.palette.primary.main};
  `
);

const StyledList = styled(List)(
    ({ theme }) => `
    margin-top: ${theme.spacing(1)};
  `
);

const StyledListItem = styled(ListItem)(
    ({ theme }) => `
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    padding: ${theme.spacing(1)};
    border-radius: ${theme.shape.borderRadius};
    background-color: ${theme.palette.background.default};
  `
);

const StyledIcon = styled(FontAwesomeIcon)(
    ({ theme }) => `
    margin-right: ${theme.spacing(1)};
  `
);

const TimeEntry = styled("div")(
    ({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
);

const TimeEntryDetails = styled("div")(
    ({ theme }) => `
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  `
);

const TimeEntryDuration = styled(Typography)(
    ({ theme }) => `
    margin-left: 10px;
    font-weight: bold;
  `
);

const TimesheetComponent: React.FC<{ timesheetsGroup: TimesheetGroupedByDate; handleAddEntry: () => void }> = ({
    timesheetsGroup,
    handleAddEntry,
}) => {
    const getTotalHours = (timesheets: TimesheetType[]) => {
        const totalHours = timesheets.reduce((total, timesheet) => {
            const start = timesheet.os_emp_start_datetime ? new Date(timesheet.os_emp_start_datetime) : null;
            const end = timesheet.os_emp_end_datetime ? new Date(timesheet.os_emp_end_datetime) : null;
            if (!start || !end || isNaN(start.getTime()) || isNaN(end.getTime())) {
                return total;
            }
            const hours = (end.getTime() - start.getTime()) / 1000 / 60 / 60;
            return total + hours;
        }, 0);

        return totalHours.toFixed(2);
    };

    const getDuration = (start: string, end: string) => {
        const startTime = new Date(start);
        const endTime = new Date(end);
        const duration = (endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60;
        const hours = Math.floor(duration);
        const minutes = Math.round((duration - hours) * 60);
        return `${hours}h ${minutes}m`;
    };

    return (
        <StyledPaper>
            <StyledHeader variant="h4">
                <StyledIcon icon={faClock} />
                Timesheet
            </StyledHeader>
            {Object.entries(timesheetsGroup).map(([date, timesheets]) => (
                <StyledDateSection key={date}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography
                                sx={{
                                    fontSize: "1.1rem",
                                }}
                            >
                                {format(new Date(date), "PPP")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <StyledTotalHours
                                sx={{
                                    color: green[500],
                                }}
                            >
                                Total Hours: {getTotalHours(timesheets)}
                            </StyledTotalHours>
                        </Grid>
                    </Grid>
                    <Divider />
                    <StyledList>
                        {timesheets.map((timesheet) => (
                            <StyledListItem key={timesheet.id}>
                                <TimeEntry>
                                    <TimeEntryDetails>
                                        <Typography
                                            sx={{
                                                fontSize: "0.9rem",
                                            }}
                                        >
                                            {format(new Date(timesheet.os_emp_start_datetime || ""), "p")} -{" "}
                                            {format(new Date(timesheet.os_emp_end_datetime || ""), "p")}
                                        </Typography>

                                        {/* <Typography variant="caption">
                                            {format(new Date(timesheet.os_emp_start_datetime || ""), "MMMM d, yyyy")}
                                        </Typography> */}
                                    </TimeEntryDetails>
                                    <ArrowForward
                                        fontSize="small"
                                        sx={{
                                            marginLeft: "10px",
                                        }}
                                    />
                                    <TimeEntryDuration variant="body2">
                                        {getDuration(
                                            timesheet.os_emp_start_datetime || "",
                                            timesheet.os_emp_end_datetime || ""
                                        )}
                                    </TimeEntryDuration>
                                </TimeEntry>
                            </StyledListItem>
                        ))}
                        <Link
                            href="#"
                            onClick={handleAddEntry} // Replace this with your function
                            underline="hover"
                            sx={{
                                color: "#1976d2", // You can adjust the color as needed
                                fontSize: "0.875rem", // Adjust the font size to match your design
                            }}
                        >
                            + Add Time Entry
                        </Link>
                    </StyledList>
                </StyledDateSection>
            ))}
        </StyledPaper>
    );
};

export default TimesheetComponent;
