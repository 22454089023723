import TradeShift from "../components/mySchedule/sideBar/TradeShift";
import ReleaseShift from "../components/mySchedule/sideBar/ReleaseShift";
import DropShift from "../components/mySchedule/sideBar/DropShift";
import ApproveDialog from "../components/mySchedule/ApproveDialog";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { Button, Typography, Menu, MenuItem, IconButton, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Calendar from "../components/mySchedule/Calendar";

import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";

import { toggleSidebar } from "../redux/features/layout/LayoutSlice";
import { ShiftDetails } from "../components/mySchedule/sideBar/ShiftDetails";
import React, { useEffect } from "react";
import ReleaseShiftForm from "../components/mySchedule/ReleaseShiftForm";
import TradeShiftForm from "../components/mySchedule/trade/TradeShiftForm";
import { Schedule, ShiftType } from "../types";
import { getShiftsByDate } from "../redux/features/mySchedule/ScheduleApi";
import { UserSettingType, UserSettingName } from "../enums";
import { IUser, UserSetting, UserSettingValueJSONClass } from "../redux/features/user/models/user";
import { DateTimeUtil } from "../utils";

dayjs.extend(utc);

const MySchedule = () => {
    var { id = "schedule" } = useParams();

    const layout = useSelector((state: any) => state.layout);
    const processing = useSelector((state: any) => state.layout.processing);
    const dateRange = useSelector((state: any) => state.mySchedule.dateRange);
    const User = useSelector((state: any) => state.user.user) as IUser;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleSideBarClose = () => {
        setCurrentView("schedule");
        dispatch(toggleSidebar(false));
        fetchShifts();
        // navigate("/my-schedule/schedule");
    };

    // State for menu and view
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currentView, setCurrentView] = React.useState(id);
    const [releaseShiftStep, setReleaseShiftStep] = React.useState(1);
    const selectedShift = useSelector((state: any) => state.mySchedule.selectedShift) as ShiftType;
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (view: string) => {
        setCurrentView(view);
        setReleaseShiftStep(1);
        handleClose();
    };

    const handleBackClick = () => {
        setCurrentView("schedule");
    };

    const EnforceAdjustmentDays = (renderUi: JSX.Element | null, shift?: ShiftType): JSX.Element | null => {
        // If no shift is selected, return null, meaning no UI will be shown
        if (!shift) return null;

        // Find user settings for swapping configuration
        const userSettings = User.userSettings?.find(
            (setting: UserSetting) =>
                setting.userSettingType == UserSettingType.USER_SETTING_SWAPPING &&
                setting.userSettingName == UserSettingName.SWAPPING_CONFIG
        );

        // Parse the userSettingValue from JSON string into an object of UserSettingValueJSONClass
        const settingValue = userSettings?.userSettingValueJson as UserSettingValueJSONClass;

        // Extract adjustment days from the user setting value
        const adjustmentDays = settingValue?.ADJUSTMENT_DAYS; // Number of adjustment days

        // Use dayjs to parse the shift's osEmpStartDateTime (which is in UTC format)
        const startTime = dayjs.utc(shift.osEmpStartDateTime);

        console.log("startTime (UTC):", startTime.format());

        // Get the current local date
        const currentDate = dayjs();

        // If adjustmentDays is provided, calculate current date + adjustmentDays
        if (adjustmentDays !== undefined && adjustmentDays !== null) {
            const adjustedCurrentDate = currentDate.add(adjustmentDays, "day");

            console.log("adjustedCurrentDate:", adjustedCurrentDate.format());

            // If osEmpStartDateTime is after adjustedCurrentDate, show the UI
            if (startTime.isAfter(adjustedCurrentDate)) {
                console.log("UI rendered");
                return renderUi;
            }

            // If currentDate + adjustmentDays is not before osEmpStartDateTime, return null
            console.log("UI not rendered");
            return null;
        }

        // If adjustmentDays are not provided, just show the UI
        return renderUi;
    };

    const renderActionables = (shift?: ShiftType) => {
        if (!shift) return null; // If no shift is selected, return nothing

        return (
            <>
                {/* options section START */}
                <Box
                    sx={{
                        padding: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        flexGrow: 0,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            flexDirection: "row",
                            width: "100%",
                            gap: 2, // Add gap between buttons
                        }}
                    >
                        {/* If shift status is 0 (ASSIGNED), means it is ready for swap/trade */}
                        {shift.osScheduleShiftStatus === 0 && (
                            <Button
                                variant="outlined"
                                onClick={() => handleMenuItemClick("trade")}
                                sx={{
                                    padding: "5px 15px",
                                    marginTop: 2,
                                    // backgroundColor: "#1aabab",
                                    // ":hover": {
                                    //     backgroundColor: "#1aabab",
                                    // },
                                }}
                            >
                                Trade Shift
                            </Button>
                        )}
                        {/* <Button
                            variant="outlined"
                            onClick={() => handleMenuItemClick("release")}
                            sx={{
                                padding: "5px 15px",
                                marginTop: 2,
                                backgroundColor: "#1aabab",
                                ":hover": {
                                    backgroundColor: "#1aabab",
                                },
                            }}
                        >
                            Release Shift
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => handleMenuItemClick("drop")}
                            sx={{
                                padding: "5px 15px",
                                marginTop: 2,
                                backgroundColor: "#1aabab",
                                ":hover": {
                                    backgroundColor: "#1aabab",
                                },
                            }}
                        >
                            Drop Shift
                        </Button> */}
                    </Box>
                </Box>
                {/* options section END */}
            </>
            // </>
        );
    };

    const fetchShifts = () => {
        const payload = {
            startDatetime: dayjs().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
            endDatetime: dayjs().endOf("year").format("YYYY-MM-DD HH:mm:ss"),
        };
        dispatch(getShiftsByDate(payload)); // Fetch shifts
    };

    useEffect(() => {
        fetchShifts();
    }, [dispatch]);

    const allowActionableByShiftStatus = (selectedShift?: ShiftType): boolean => {
        // status?: number | null, startDate?: string | null
        // selectedShift.osScheduleShiftStatus, selectedShift.osEmpStartDateTime
        if (!selectedShift) return false;

        const isBefore = dayjs(selectedShift?.osEmpStartDateTime).isBefore(dayjs(), "day");
        var ret = true;

        if (
            selectedShift.osScheduleShiftStatus === undefined ||
            selectedShift.osScheduleShiftStatus === null ||
            !selectedShift?.osEmpStartDateTime
        )
            return false;
        // Check if the startDate is in the past

        if (isBefore || selectedShift.osScheduleShiftStatus === 4) {
            ret = false;
        }
        return ret;
    };

    const IsShiftInFuture = (selectedShift: ShiftType): boolean => {
        const isSameOrBefore = dayjs(selectedShift?.osEmpStartDateTime).isSameOrBefore(dayjs(), "day");
        if (isSameOrBefore) {
            return false;
        }

        return true;
    };

    return (
        <Box height={"100vh"}>
            <Calendar />
            <Drawer
                anchor={"right"}
                open={layout.sidebar}
                onClose={handleSideBarClose}
                PaperProps={{
                    sx: { top: "65px", height: "calc(100% - 65px)" },
                }}
            >
                <Box
                    sx={{
                        width: "35vw",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflow: "auto",
                            // padding: "0px 18px",
                        }}
                    >
                        <List
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                overflow: "auto",
                            }}
                            component="nav"
                            aria-label="mailbox folders"
                        >
                            <ListItem sx={{ p: 2 }}>
                                {currentView !== "schedule" && (
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleBackClick}
                                        aria-label="back"
                                        sx={{ marginRight: 2 }}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                )}
                                <Typography variant="h6">
                                    {currentView === "schedule" ? "Shift Details" : ""}
                                    {currentView === "trade" ? "Trade Shift" : ""}
                                    {currentView === "release" ? "Release Shift" : ""}
                                    {currentView === "drop" ? "Drop Shift" : ""}
                                </Typography>

                                {/* <Button onClick={test}> test </Button> */}

                                <Box sx={{ flexGrow: 1 }} />
                                <Button variant="text" size="small" onClick={handleSideBarClose}>
                                    <CloseRoundedIcon sx={{ color: "black" }} />
                                </Button>
                            </ListItem>
                            <Divider />
                            <ListItem
                                sx={{
                                    display: "block",
                                    // flexGrow: 1,
                                    // backgroundColor: "#eeeeee",
                                    overflowY: "auto",
                                }}
                            >
                                <Box
                                    sx={{
                                        // border: "1px solid rgba(0, 0, 0, 0.12)",
                                        // boxSizing: "border-box",
                                        width: "100%",
                                        // backgroundColor: "white",
                                    }}
                                >
                                    {currentView === "schedule" ? <ShiftDetails title="Shift Details" /> : null}
                                    {currentView === "trade" ? <TradeShiftForm/> : null}
                                    {currentView === "release" ? (
                                        <ReleaseShiftForm step={releaseShiftStep} setStep={setReleaseShiftStep} />
                                    ) : null}
                                    {currentView === "drop" ? <DropShift title="Drop Shift" /> : null}
                                </Box>
                            </ListItem>
                        </List>
                    </Box>

                    {currentView === "schedule" &&
                        selectedShift &&
                        IsShiftInFuture(selectedShift) &&
                        EnforceAdjustmentDays(renderActionables(selectedShift), selectedShift)}
                    {/* // allowActionableByShiftStatus(selectedShift) &&
                        // selectedShift?.osScheduleShiftStatus == 0 && (
                        //     <>
                        //         <Divider />
                        //         {}
                        //     </>
                        // )} */}
                </Box>
            </Drawer>
            <Box>
                <ApproveDialog />
            </Box>
            {/* <Box>
                <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={processing}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box> */}
        </Box>
    );
};

export default MySchedule;
