import { createSlice } from "@reduxjs/toolkit";
import { TimesheetGroupedByDate, TimesheetState } from "../../../types/state";
import { getAllTimesheets, getSummary } from "./api";
import { FULFILLED, PENDING, REJECTED } from "../../constants";
import { TimesheetType } from "../../../types";

const initialState: TimesheetState = {
    timesheets: [],
    requestStatus: FULFILLED,
    timesheetSummary: {},
    timesheetGroupedByDate: {},
    allowRefetch: true,
    selectedTab: 0,
};

export const TimesheetSlice = createSlice({
    name: "timesheet",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        /** get timesheets */
        [getAllTimesheets.pending.type]: (state) => {
            state.timesheets = [];
            state.requestStatus = PENDING;
        },

        [getAllTimesheets.fulfilled.type]: (state, { payload }) => {
            if (payload.data) {
                const timesheets = payload.data as TimesheetType[];
                state.timesheets = timesheets;

                // group timesheets by date
                const timesheetsGroupedByDate = timesheets.reduce(
                    (acc: TimesheetGroupedByDate, timesheet: TimesheetType) => {
                        if (!timesheet.os_emp_start_datetime) return acc;
                        const date = timesheet.os_emp_start_datetime?.split(" ")[0];
                        if (date && !acc[date]) {
                            acc[date] = [];
                        }
                        acc[date].push(timesheet);
                        return acc;
                    },
                    {}
                );

                state.timesheetGroupedByDate = timesheetsGroupedByDate;
            }
            state.requestStatus = FULFILLED;
        },

        [getAllTimesheets.rejected.type]: (state) => {
            state.timesheets = [];
            state.requestStatus = REJECTED;
        },

        /** get timesheet summary */
        [getSummary.pending.type]: (state) => {
            state.timesheetSummary = {};
            state.requestStatus = PENDING;
        },

        [getSummary.fulfilled.type]: (state, { payload }) => {
            console.log("getSummary.fulfilled", payload.data);
            if (payload.data) {
                state.timesheetSummary = payload.data;
            }
            state.requestStatus = FULFILLED;
        },

        [getSummary.rejected.type]: (state) => {
            state.timesheetSummary = {};
            state.requestStatus = REJECTED;
        },
    },
});

export const {} = TimesheetSlice.actions;
export default TimesheetSlice.reducer;
