import React from "react";
import { Box, Typography, TextField, Button, Checkbox, List, ListItem, ListItemText, ListItemIcon, Divider } from "@mui/material";

const ReleaseShiftForm = ({ step, setStep }: { step: number, setStep: React.Dispatch<React.SetStateAction<number>> }) => {
    return (
        <>
            {step === 1 && (
                <Box padding={2}>
                    <Typography variant="h5">Release Shift</Typography>
                    <Box
                        sx={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            padding: 2,
                            marginTop: 2,
                            backgroundColor: "#ffffff",
                        }}
                    >
                        <Typography variant="body2" gutterBottom>
                            Feb 9, 8:00am - 4:00pm
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Bartender, Restaurant Ginger Main
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            1. You will be asked to enter a reason for requesting the release and to select coworkers who could potentially pick up the shift.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            2. Management will either approve or reject your request. If approved, the employee you've nominated to pick up the shift will be notified.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            3. If that employee agrees to pick up your shift, management will confirm and complete the release.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Reason for request:
                        </Typography>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            margin="normal"
                        />
                        <Box textAlign="right" marginTop={2}>
                            <Button variant="contained" color="primary" onClick={() => setStep(2)}>
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
            {step === 2 && (
                <Box padding={2}>
                    <Typography variant="h5">Release Shift</Typography>
                    <Box
                        sx={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            padding: 2,
                            marginTop: 2,
                            backgroundColor: "#ffffff",
                        }}
                    >
                        <Typography variant="body2" gutterBottom>
                            Feb 9, 8:00am - 4:00pm
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Bartender, Restaurant Ginger Main
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            0 Selected Covers:
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            placeholder="Employee search..."
                        />
                        <Typography variant="body2" gutterBottom>
                            Available
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Select All Available
                        </Typography>
                        <Box
                            sx={{
                                maxHeight: 200,
                                overflow: 'auto',
                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                borderRadius: 1,
                                padding: 1,
                                marginBottom: 2,
                            }}
                        >
                            <List>
                                {["Abigail Bell", "Aiden Bennett", "Anna Mitchelle", "Brianna Powel", "Jackie Mitchelle", "Keith Cooper", "Mathew Lewis", "Meghan Alexander"].map(employee => (
                                    <ListItem key={employee} button>
                                        <ListItemIcon>
                                            <Checkbox />
                                        </ListItemIcon>
                                        <ListItemText primary={employee} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Typography variant="body2" gutterBottom>
                            Unavailable
                        </Typography>
                        <Box
                            sx={{
                                maxHeight: 200,
                                overflow: 'auto',
                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                borderRadius: 1,
                                padding: 1,
                                marginBottom: 2,
                            }}
                        >
                            <List>
                                {["Angel Jackson", "Arron Long", "Ella Hamilton"].map(employee => (
                                    <ListItem key={employee} button>
                                        <ListItemIcon>
                                            <Checkbox />
                                        </ListItemIcon>
                                        <ListItemText primary={employee} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Box textAlign="right" marginTop={2}>
                            <Button variant="contained" color="primary">
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ReleaseShiftForm;
