import { Typography, AppBar, Tabs } from '@mui/material';
import { styled } from '@mui/system';


export const Root = styled("div")(({ theme }) => ({
    width: "100%",
    // maxWidth: 360,
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
        // maxWidth: 600,
    width: "100%",

    },
    backgroundColor: theme.palette.background.paper,
}));

export const InlineTypography = styled(Typography)(({ theme }) => ({
    display: "inline",
    color: theme.palette.primary.main,
}));

export const ButtonGroup = styled("div")(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start', // Align buttons to start
    gap: theme.spacing(1), // Add space between buttons
    marginTop: theme.spacing(1),
}));

export const CustomAppBar = styled(AppBar)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    backgroundColor: "#E0E0E0", // Shade of grey
}));

export const CustomTabs = styled(Tabs)(({ theme }) => ({
    // "& .MuiTab-root": {
    //     color: "#000000", // Default tab label color black
    // },
}));
