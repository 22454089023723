import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../axios/API";
import { phpClient } from "../../../axios";

export const getPayperiods = createAsyncThunk("/payperiods/all", async (data: {}, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;

  try {
    const response = await phpClient.get({
      url: "/account/payperiods"
    });
    // console.log("response", response.data);
    return response;
  } catch (error) {
    console.error("*** getPayPeriods API error", error);
    return rejectWithValue(error);
  }
});

export const getPayperiod = createAsyncThunk(
  "/payperiods/single",
  async (
    data: {
      payperiod_id?: string;
    },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await phpClient.post({
        url: "/account/get_payperiod",
        data
      });
      return response;
    } catch (error) {
      console.error("*** getPayPeriod API error", error);
      return rejectWithValue(error);
    }
  }
);
