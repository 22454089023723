import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { tgApproveDialog } from "../../redux/features/layout/LayoutSlice";
import { confirmRequests } from "../../redux/features/mySchedule/ScheduleApi";
import {
  tgShift,
  clearTxns,
} from "../../redux/features/mySchedule/ScheduleSlice";
import { Box, CardContent, Divider, Grid, Typography } from "@mui/material";
import { TRADE } from "../../utils/constants";
import { formatShiftDate } from "../../utils/helper";

const ApproveDialog = () => {
  const [aptRequests, setAptRequests] = useState([] as number[]);
  const [rejRequests, setRejRequests] = useState([] as number[]);

  const approveDialog = useSelector((state: any) => state.layout.approveDialog);
  const user = useSelector((state: any) => state.user.user);
  const txnsForUser = useSelector((state: any) => state.mySchedule.txnsForUser);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(tgApproveDialog(false));
  };

  const handleAccept = (shId: number) => {
    let apts = [...aptRequests];
    let idx = apts.findIndex((apt) => apt === shId);

    if (idx === -1) {
      apts.push(shId);
      dispatch(tgShift(shId));
    }

    setAptRequests([...apts]);
  };

  const handleReject = (shId: number) => {
    let rejs = [...rejRequests];
    let idx = rejs.findIndex((rej) => rej === shId);

    if (idx === -1) {
      rejs.push(shId);
      dispatch(tgShift(shId));
    }

    setRejRequests([...rejs]);
  };

  const handleRequestConfirm = async () => {
    await dispatch(
      confirmRequests({ user: user, apts: aptRequests, rejs: rejRequests })
    );
    dispatch(clearTxns());
    dispatch(tgApproveDialog(false));
  };
  return (
    <Dialog open={approveDialog}>
      <DialogTitle>Notification</DialogTitle>
      <Divider />
      <DialogContent style={{ minWidth: "35vw", backgroundColor: "#eeeeee" }}>
        {txnsForUser.map((txn: any) => {
          return (
            <Card variant="outlined" sx={{ m: 0.5 }} key={txn.id}>
              <CardContent sx={{ p: 2.5 }}>
                <Grid container>
                  <Grid md={12} item>
                    <Typography color="gray">
                      {txn.requestType === TRADE
                        ? "Trade Request"
                        : "Release Request"}
                    </Typography>
                  </Grid>
                  <Grid md={12} item>
                    <Typography>
                      Request Person: {txn.empNew.fullName}
                    </Typography>
                  </Grid>
                  <Grid md={12} item>
                    Origin Shift: {formatShiftDate(txn.origShift)}
                  </Grid>
                  <Grid md={12} item>
                    New Shift: {formatShiftDate(txn.newShift)}
                  </Grid>
                  <Grid md={12} sx={{ display: "flex", mt: 2 }} item>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                      variant="contained"
                      size="small"
                      disabled={txn.checked}
                      sx={{ mr: 0.5 }}
                      onClick={() => handleAccept(txn.id)}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      disabled={txn.checked}
                      color="error"
                      onClick={() => handleReject(txn.id)}
                    >
                      Reject
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ m: 1 }}>
        <Button onClick={handleRequestConfirm} size="small" variant="contained">
          Confirm
        </Button>
        <Button
          onClick={handleClose}
          color="inherit"
          size="small"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveDialog;
