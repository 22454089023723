import React, { useEffect } from "react";
import { Typography, Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TimesheetSummary } from "../../types";
import { UseFetchTimesheetsAndSummaries } from "../../hooks";

export type SummaryComponentProps = {
    timesheetSummary: TimesheetSummary;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    row: {
        display: "flex",
        flexWrap: "wrap",
        borderBottom: "1px solid #e0e0e0",
        // justifyContent: 'space-between',
        // gap: theme.spacing(2),
    },
    item: {
        flex: "1 1 calc(33.333% - 16px)", // Takes 1/3rd of the row with spacing
        padding: theme.spacing(2),
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        // justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        boxSizing: "border-box",
    },
    title: {
        fontWeight: "bold",
        marginBottom: theme.spacing(1),
        fontSize: "0.875rem",
        color: theme.palette.text.secondary,
    },
    value: {
        fontSize: "1.5rem",
        color: theme.palette.text.primary,
    },
}));

const SummaryComponent: React.FC<SummaryComponentProps> = ({ timesheetSummary }) => {
    const classes = useStyles();

    const renderSummaryItem = (label: string, value: string | number | undefined) => (
        <Box className={classes.item}>
            <Typography variant="body2" className={classes.title}>
                {label}
            </Typography>
            <Typography variant="body1" className={classes.value}>
                {value !== undefined ? value : "0.0"}
            </Typography>
        </Box>
    );

    return (
        <Box className={classes.root}>
            <Box className={classes.row}>
                {renderSummaryItem("Regular", timesheetSummary.items?.REG?.hours)}
                {renderSummaryItem("Overtime", timesheetSummary.items?.OT?.hours)}
                {renderSummaryItem("Double Time", timesheetSummary.items?.DT?.hours)}
            </Box>
            <Box className={classes.row}>
                {renderSummaryItem("Paid Time Off", timesheetSummary.items?.timeoffP?.hours)}
                {renderSummaryItem("Unpaid Time Off", timesheetSummary.items?.timeoffU?.hours)}
                {renderSummaryItem("Regular v2", timesheetSummary.items?.regv2?.hours)}
            </Box>
            <Box className={classes.row}>
                {renderSummaryItem("Total Paid Time Off", timesheetSummary.summary?.total_paid_timeoff)}
                {renderSummaryItem("Total Unpaid Time Off", timesheetSummary.summary?.total_unpaid_timeoff)}
            </Box>
            <Box className={classes.row} sx={{ flexDirection: "row" }}>
                {renderSummaryItem("Total Hours", timesheetSummary.summary?.total_hours)}
            </Box>
        </Box>
    );
};

export default SummaryComponent;
