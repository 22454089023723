import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { getApiUrl } from "../utils";

interface RequestOptions {
  url: string;
  data?: any;
  params?: any;
  extraHeaders?: Record<string, string>;
}

class AxiosClient {
  protected axiosInstance: AxiosInstance;

  constructor() {
    // const devUrl = process.env.REACT_APP_BACKEND_LOCAL;
    // const prodUrl = process.env.REACT_APP_PHP_PROD;
    const prodUrl = getApiUrl();


    this.axiosInstance = axios.create({
      baseURL: prodUrl,
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        'appkey': process.env.REACT_APP_APP_KEY,
      },
    });

    // Add a request interceptor
    this.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const token = Cookies.get("U_EVAL_VALUE");

        config.headers.Authorization = `Bearer ${token}`;

        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        // Handle response error here
        return Promise.reject(error);
      }
    );
  }

  async get<T>({ url, data = {}, params = {}, extraHeaders = {} }: RequestOptions): Promise<T> {
    try {
      const config: AxiosRequestConfig = {
        url,
        params,
        headers: {
          ...this.axiosInstance.defaults.headers.common,
          ...extraHeaders,
        },
        data, // Include the body in the GET request config
        method: "get", // Explicitly set the method to 'get'
      };

      const response: AxiosResponse<T> = await this.axiosInstance.request(config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      return {} as T; // This line should be unreachable, but TypeScript needs it
    }
  }

  async post<T>({ url, data = {}, extraHeaders = {} }: RequestOptions): Promise<T> {
    try {
      const config: AxiosRequestConfig = {
        headers: {
          ...this.axiosInstance.defaults.headers.common,
          ...extraHeaders,
        },
      };

      const response: AxiosResponse<T> = await this.axiosInstance.post(url, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      return {} as T; // This line should be unreachable, but TypeScript needs it
    }
  }

  async put<T>({ url, data = {}, extraHeaders = {} }: RequestOptions): Promise<T> {
    try {
      const config: AxiosRequestConfig = {
        headers: {
          ...this.axiosInstance.defaults.headers.common,
          ...extraHeaders,
        },
      };

      const response: AxiosResponse<T> = await this.axiosInstance.put(url, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      return {} as T; // This line should be unreachable, but TypeScript needs it
    }
  }

  async delete<T>({ url, extraHeaders = {} }: RequestOptions): Promise<T> {
    try {
      const config: AxiosRequestConfig = {
        headers: {
          ...this.axiosInstance.defaults.headers.common,
          ...extraHeaders,
        },
      };

      const response: AxiosResponse<T> = await this.axiosInstance.delete(url, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      return {} as T; // This line should be unreachable, but TypeScript needs it
    }
  }

  private handleError(error: any): never {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Error(`Error: ${error.response.status}, ${error.response.data}`);
      } else if (error.request) {
        // The request was made but no response was received
        throw new Error("No response received from the server");
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error("Error setting up request");
      }
    } else {
      // Handle non-Axios errors
      throw new Error("An unexpected error occurred");
    }
  }
}

export { AxiosClient };
export default new AxiosClient();
