import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    Paper,
    Grid,
    Divider,
    Select,
    MenuItem,
    Modal,
    TextField,
    InputLabel,
    FormControl,
    useMediaQuery,
    useTheme,
    Popover,
    IconButton,
    List,
    ListItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getPayperiod, getPayperiods } from "../../redux/features/payperiod";
import { getAllTimesheets, getSummary } from "../../redux/features/timesheet";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { Department, PayperiodFilterType, PayperiodType, TimesheetGroupedByDate, TimesheetSummary, TimesheetType } from "../../types";
import { ArrowBack, ArrowForward, CalendarToday } from "@mui/icons-material";
import DateTimeFilter from "../dateTimeDisaplay/DateTimeFilter";
import TimesheetComponent from "./TimesheetComponent";

interface DayData {
    day: string;
    date: string;
    loginTime: string[];
    logoutTime: string[];
    totalWorkingTime: string;
}

const TimesheetMain: React.FC = () => {
    const [clockedIn, setClockedIn] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [formRows, setFormRows] = useState([{ startTime: "", endTime: "", selectedProject: "", note: "" }]);

    const dispatch = useAppDispatch();
    // const payperiods = useSelector((state: any) => state.payperiod.payperiods) as PayperiodType[];
    const selectedPayperiod = useSelector((state: any) => state.payperiod.selectedPayperiod) as PayperiodType;
    const selectedPayperiodFilter = useSelector(
        (state: any) => state.payperiod.selectedPayperiodFilter
    ) as PayperiodFilterType;
    const user = useSelector((state: any) => state.user.user);
    const timesheets = useSelector((state: any) => state.timesheet.timesheets) as TimesheetType[];
    const timesheetSummary = useSelector((state: any) => state.timesheet.timesheetSummary) as TimesheetSummary;
    const timesheetsGrouped = useSelector((state: any) => state.timesheet.timesheetGroupedByDate) as TimesheetGroupedByDate;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // Toggle clock in/out status
    const handleClockInOut = () => {
        setClockedIn(!clockedIn);
    };

    useEffect(() => {
        console.log("!!! selectedPayperiod", selectedPayperiod);
        console.log("!!! selectedPayperiodFilter", selectedPayperiodFilter);
        if (selectedPayperiodFilter?.start_datetime && selectedPayperiodFilter?.end_datetime) {
            const req = {
                employee_id: user[0].os_emp_account_id ?? selectedPayperiod?.company_id,
                pay_period: `${selectedPayperiodFilter?.start_datetime} - ${selectedPayperiodFilter?.end_datetime}`,
            };
            dispatch(getAllTimesheets(req));
            dispatch(getSummary(req));
        }
    }, [dispatch, selectedPayperiod, selectedPayperiodFilter]);

    // Test function to log pay periods
    const test = () => {
        console.log("timesheets", timesheets);
        console.log('timesheets group', timesheetsGrouped);
        console.log("user", user[0]);
    };

    // Calculate current date string
    const today = new Date();
    const year = today.getFullYear();
    const month = 5; // June (0-indexed)
    const currentDateString = `${(today.getDate() < 10 ? "0" : "") + today.getDate()}/${month < 9 ? "0" : ""}${
        month + 1
    }/${year}`;

    // Generate days for the current month
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const monthDays: DayData[] = [];
    for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(year, month, i);
        const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "short" });
        const formattedDate = `${(date.getDate() < 10 ? "0" : "") + date.getDate()}/${date.getMonth() < 9 ? "0" : ""}${
            date.getMonth() + 1
        }/${date.getFullYear()}`;
        monthDays.push({
            day: dayOfWeek,
            date: formattedDate,
            loginTime: [], // Assuming no login time for now
            logoutTime: [], // Assuming no logout time for now
            totalWorkingTime: "8 hours", // Assuming a fixed working time for now
        });
    }

    // Handle adding a new time entry
    const handleAddEntry = () => {
        setFormRows([{ startTime: "", endTime: "", selectedProject: "", note: "" }]);
        setOpenModal(true);
    };

    // Handle closing the modal
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    // Handle adding a new row in the form
    const handleAddRow = () => {
        if (formRows.length < 3) {
            setFormRows([...formRows, { startTime: "", endTime: "", selectedProject: "", note: "" }]);
        }
    };

    // Handle form field changes
    const handleFormChange = (index: number, field: string, value: string) => {
        const updatedRows = formRows.map((row, i) => (i === index ? { ...row, [field]: value } : row));
        setFormRows(updatedRows);
    };


    return (
        <Paper sx={{ padding: 2, marginTop: 2 }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6" gutterBottom>
                        Timesheet
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <DateTimeFilter />
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button variant="contained" size="small" onClick={test} sx={{ marginRight: 1 }}>
                            Test
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom>
                Jun 1-30
            </Typography>
            <Grid container spacing={2}>
                {/* Left Column */}
                <Grid item xs={12} md={8}>
                    <TimesheetComponent timesheetsGroup={timesheetsGrouped} handleAddEntry={handleAddEntry}/>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ padding: 2, textAlign: "center" }}>
                        <Box sx={{ textAlign: "left" }}>
                            <Typography variant="subtitle1">Summary</Typography>
                            <Typography variant="body2">Total hours: {timesheetSummary.summary?.total_hours || 0} hours</Typography>
                            <Typography variant="body2">Total paid timeoff: {timesheetSummary.summary?.total_paid_timeoff || 0} hours</Typography>
                            <Typography variant="body2">Total unpaid timeoff: {timesheetSummary.summary?.total_unpaid_timeoff || 0} hours</Typography>
                            <Divider sx={{ my: 1 }} />
                            <Typography variant="subtitle1">Breakdown</Typography>
                            <Typography variant="body2">{timesheetSummary.items?.REG?.name || "Regular"}: {timesheetSummary.items?.REG?.hours || 0} hours</Typography>
                            <Typography variant="body2">{timesheetSummary.items?.OT?.name || "Overtime"}: {timesheetSummary.items?.OT?.hours || 0} hours</Typography>
                            <Typography variant="body2">{timesheetSummary.items?.DT?.name || "DT"}: {timesheetSummary.items?.DT?.hours || 0} hours</Typography>
                            <Typography variant="body2">{timesheetSummary.items?.timeoffP?.name|| "timeoffP"}: {timesheetSummary.items?.timeoffP?.hours || 0} hours</Typography>
                            <Typography variant="body2">{timesheetSummary.items?.timeoffU?.name|| "timeoffU"}: {timesheetSummary.items?.timeoffU?.hours || 0} hours</Typography>
                        </Box>
                        <Divider sx={{ my: 3 }}/>

                        {/* <Box sx={{ textAlign: "left" }}>
                            <Typography variant="subtitle1">This Week</Typography>
                            <Typography variant="body2">Jun 4 - 10 (6 days left)</Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                0h 00m
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="subtitle1">This Pay Period</Typography>
                            <Typography variant="body2">Jun 1 - 30 (24 days left)</Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                7h 22m
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Will be sent for approval on Jul 1
                            </Typography>
                        </Box> */}
                    </Paper>
                </Grid>
            </Grid>

            {/* MODEL for time entry  */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Paper sx={{ width: isMobile ? "90%" : 600, margin: "100px auto", padding: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Edit Time Worked
                    </Typography>
                    {formRows.map((row, index) => (
                        <Grid container spacing={2} key={index} alignItems="center">
                            <Grid item xs={12} sm={3}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel htmlFor={`start-time-${index}`}>Start Time</InputLabel>
                                    <TextField
                                        id={`start-time-${index}`}
                                        type="time"
                                        value={row.startTime}
                                        onChange={(e) => handleFormChange(index, "startTime", e.target.value)}
                                        fullWidth
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel htmlFor={`end-time-${index}`}>End Time</InputLabel>
                                    <TextField
                                        id={`end-time-${index}`}
                                        type="time"
                                        value={row.endTime}
                                        onChange={(e) => handleFormChange(index, "endTime", e.target.value)}
                                        fullWidth
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel htmlFor={`project-task-${index}`}>Save time to...</InputLabel>
                                    <Select
                                        id={`project-task-${index}`}
                                        value={row.selectedProject}
                                        onChange={(e) => handleFormChange(index, "selectedProject", e.target.value)}
                                        fullWidth
                                    >
                                        <MenuItem value="">Select Project/Task</MenuItem>
                                        {/* Add your project/task options here */}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Add note"
                                    value={row.note}
                                    onChange={(e) => handleFormChange(index, "note", e.target.value)}
                                    multiline
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleAddRow}
                        sx={{ marginTop: 2 }}
                        disabled={formRows.length >= 3}
                    >
                        Add Row
                    </Button>
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
                        <Button variant="contained" color="primary" onClick={handleCloseModal}>
                            Save
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="error">
                            Delete Time Entry
                        </Button>
                    </Box>
                </Paper>
            </Modal>
        </Paper>
    );
};

export default TimesheetMain;
