// src/theme/themes.ts
import { createTheme, Theme } from '@mui/material/styles';

// Enum to define different theme types
export enum ThemeType {
  DEFAULT = 'DEFAULT',
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

// Object to hold the theme configurations based on the ThemeType enum
export const themes: Record<ThemeType, Theme> = {
  // Default theme configuration
  [ThemeType.DEFAULT]: createTheme({
    palette: {
      primary: {
        main: '#2196F3', // Blue
      },
      secondary: {
        main: '#4CAF50', // Green
      },
      error: {
        main: '#f44336', // Red
      },
      warning: {
        main: '#FF9800', // Orange
      },
      info: {
        main: '#00BCD4', // Cyan
      },
      success: {
        main: '#4CAF50', // Green
      },
      background: {
        default: '#F0F2F5', // Light grey background
        paper: '#FFFFFF', // White paper background
      },
      text: {
        primary: '#212121', // Dark text
        secondary: '#757575', // Medium dark text
      },
    },
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif', // Font family
      h1: {
        fontSize: '2.125rem', // Font size
        fontWeight: 500, // Font weight
        color: '#2196F3', // Color
      },
      h6: {
        color: '#2196F3', // Color for h6 headers
      },
      body1: {
        color: '#212121', // Color for body1 text
      },
      body2: {
        color: '#4CAF50', // Color for body2 text
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Disable uppercase transformation
            borderRadius: '8px', // Border radius
            padding: '8px 16px', // Padding
          },
          contained: {
            backgroundColor: '#2196F3', // Background color for contained buttons
            color: '#FFFFFF', // Text color for contained buttons
            '&:hover': {
              backgroundColor: '#1976D2', // Hover state background color
            },
          },
          outlined: {
            borderColor: '#2196F3', // Border color for outlined buttons
            color: '#2196F3', // Text color for outlined buttons
            '&:hover': {
              borderColor: '#1976D2', // Hover state border color
              backgroundColor: 'rgba(33, 150, 243, 0.1)', // Hover state background color
            },
          },
        },
        defaultProps: {
          disableRipple: true, // Disable ripple effect
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: '#F0F2F5', // Light grey background for Tabs
            borderBottom: '1px solid #e8e8e8', // Bottom border for Tabs
          },
          indicator: {
            backgroundColor: '#2196F3', // Indicator color
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Disable uppercase transformation
            minWidth: 72, // Minimum width
            fontWeight: 500, // Font weight
            backgroundColor: '#F0F2F5', // Background color for unselected tabs
            color: '#757575', // Color for unselected tabs
            '&:hover': {
              color: '#1976D2', // Hover state text color
              opacity: 1, // Hover state opacity
            },
            '&.Mui-selected': {
              color: '#1976D2', // Selected tab text color
              fontWeight: 700, // Selected tab font weight
              backgroundColor: '#FFFFFF', // Background color for selected tab
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'rgba(100, 95, 228, 0.32)', // Focus state background color
            },
          },
        },
      },
    },
  }),
  // Dark theme configuration
  [ThemeType.DARK]: createTheme({
    palette: {
      mode: 'dark', // Dark mode
      primary: {
        main: '#90CAF9', // Light blue
      },
      secondary: {
        main: '#F48FB1', // Pink
      },
      error: {
        main: '#F44336', // Red
      },
      warning: {
        main: '#FF9800', // Orange
      },
      info: {
        main: '#00BCD4', // Cyan
      },
      success: {
        main: '#4CAF50', // Green
      },
      background: {
        default: '#121212', // Dark background
        paper: '#1E1E1E', // Dark paper
      },
      text: {
        primary: '#FFFFFF', // White text
        secondary: '#B0BEC5', // Light grey text
      },
    },
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif', // Font family
      h1: {
        fontSize: '2.125rem', // Font size
        fontWeight: 500, // Font weight
        color: '#90CAF9', // Color
      },
      h6: {
        color: '#90CAF9', // Color for h6 headers
      },
      body1: {
        color: '#E0E0E0', // Color for body1 text
      },
      body2: {
        color: '#F48FB1', // Color for body2 text
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Disable uppercase transformation
            borderRadius: '8px', // Border radius
            padding: '8px 16px', // Padding
          },
          contained: {
            backgroundColor: '#90CAF9', // Background color for contained buttons
            color: '#121212', // Text color for contained buttons
            '&:hover': {
              backgroundColor: '#64B5F6', // Hover state background color
            },
          },
          outlined: {
            borderColor: '#90CAF9', // Border color for outlined buttons
            color: '#90CAF9', // Text color for outlined buttons
            '&:hover': {
              borderColor: '#64B5F6', // Hover state border color
              backgroundColor: 'rgba(144, 202, 249, 0.1)', // Hover state background color
            },
          },
        },
        defaultProps: {
          disableRipple: true, // Disable ripple effect
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: '#1E1E1E', // Darker background for Tabs
            borderBottom: '1px solid #424242', // Bottom border for Tabs
          },
          indicator: {
            backgroundColor: '#90CAF9', // Indicator color
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Disable uppercase transformation
            minWidth: 72, // Minimum width
            fontWeight: 500, // Font weight
            backgroundColor: '#1E1E1E', // Background color for unselected tabs
            color: '#B0BEC5', // Color for unselected tabs
            '&:hover': {
              color: '#64B5F6', // Hover state text color
              opacity: 1, // Hover state opacity
            },
            '&.Mui-selected': {
              color: '#64B5F6', // Selected tab text color
              fontWeight: 700, // Selected tab font weight
              backgroundColor: '#121212', // Background color for selected tab
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'rgba(100, 95, 228, 0.32)', // Focus state background color
            },
          },
        },
      },
    },
  }),
  // Light theme configuration
  [ThemeType.LIGHT]: createTheme({
    palette: {
      mode: 'light', // Light mode
      primary: {
        main: '#1976D2', // Darker blue for contrast
      },
      secondary: {
        main: '#66BB6A', // Lighter green
      },
      error: {
        main: '#EF5350', // Light red
      },
      warning: {
        main: '#FFA726', // Light orange
      },
      info: {
        main: '#29B6F6', // Light cyan
      },
      success: {
        main: '#66BB6A', // Light green
      },
      background: {
        default: '#E3F2FD', // Very light blue
        paper: '#FFFFFF', // White paper
      },
      text: {
        primary: '#212121', // Dark text
        secondary: '#757575', // Medium dark text
      },
    },
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif', // Font family
      h1: {
        fontSize: '2.125rem', // Font size
        fontWeight: 500, // Font weight
        color: '#1976D2', // Color
      },
      h6: {
        color: '#1976D2', // Color for h6 headers
      },
      body1: {
        color: '#212121', // Color for body1 text
      },
      body2: {
        color: '#66BB6A', // Color for body2 text
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Disable uppercase transformation
            borderRadius: '8px', // Border radius
            padding: '8px 16px', // Padding
          },
          contained: {
            backgroundColor: '#1976D2', // Background color for contained buttons
            color: '#FFFFFF', // Text color for contained buttons
            '&:hover': {
              backgroundColor: '#1565C0', // Hover state background color
            },
          },
          outlined: {
            borderColor: '#1976D2', // Border color for outlined buttons
            color: '#1976D2', // Text color for outlined buttons
            '&:hover': {
              borderColor: '#1565C0', // Hover state border color
              backgroundColor: 'rgba(25, 118, 210, 0.1)', // Hover state background color
            },
          },
        },
        defaultProps: {
          disableRipple: true, // Disable ripple effect
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: '#E3F2FD', // Light background for Tabs
            borderBottom: '1px solid #e8e8e8', // Bottom border for Tabs
          },
          indicator: {
            backgroundColor: '#1976D2', // Indicator color
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Disable uppercase transformation
            minWidth: 72, // Minimum width
            fontWeight: 500, // Font weight
            backgroundColor: '#E3F2FD', // Background color for unselected tabs
            color: '#757575', // Color for unselected tabs
            '&:hover': {
              color: '#1565C0', // Hover state text color
              opacity: 1, // Hover state opacity
            },
            '&.Mui-selected': {
              color: '#1565C0', // Selected tab text color
              fontWeight: 700, // Selected tab font weight
              backgroundColor: '#FFFFFF', // Background color for selected tab
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'rgba(100, 95, 228, 0.32)', // Focus state background color
            },
          },
        },
      },
    },
  }),
};
