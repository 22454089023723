import DateTimeUtil from "./dateTime";

export * from "./user";
export * from './ui';
export * from './employeeShift';

export const getApiUrl = (): string => {
    const localhost = "http://localhost:4000/v1";
    const env = process.env.REACT_APP_ENV;

    console.log('Env being used ::: ', env);

    // return localhost;
    // return "https://backend_dev.webapph.com/v1";
    switch (env) {
        case "local" : 
            return process.env.REACT_APP_BACKEND_LOCAL || localhost;

        case "dev":
            return process.env.REACT_APP_BACKEND_DEV || localhost;
        
        case "prod":
            return process.env.REACT_APP_BACKEND_URL || localhost;
        
        default:
            return localhost;
    }
};

export { DateTimeUtil };
