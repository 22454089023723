import React, { useState, useEffect, ReactNode } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface PullToRefreshProps {
  onRefresh: () => Promise<void>;
  children: ReactNode;
  threshold?: number;
  pullingContent?: ReactNode;
  refreshingContent?: ReactNode;
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({
  onRefresh,
  children,
  threshold = 50,
  pullingContent,
  refreshingContent,
}) => {
  const [isPulling, setIsPulling] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [startY, setStartY] = useState<number | null>(null);

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      if (window.scrollY === 0) {
        setStartY(e.touches[0].clientY);
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (startY !== null) {
        const currentY = e.touches[0].clientY;
        if (currentY - startY > threshold) {
          setIsPulling(true);
        }
      }
    };

    const handleTouchEnd = () => {
      if (isPulling) {
        setIsRefreshing(true);
        onRefresh().finally(() => {
          setIsRefreshing(false);
          setIsPulling(false);
          setStartY(null);
        });
      } else {
        setStartY(null);
      }
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [startY, isPulling, onRefresh, threshold]);

  return (
    <Box sx={{ minHeight: '100vh', overflow: 'hidden' }}>
      {isRefreshing && (
        <Box display="flex" justifyContent="center" alignItems="center" py={2}>
          {refreshingContent || <CircularProgress />}
        </Box>
      )}
      {!isRefreshing && isPulling && (
        <Box display="flex" justifyContent="center" alignItems="center" py={2}>
          {pullingContent || <Typography>Pull down to refresh...</Typography>}
        </Box>
      )}
      <Box sx={{ transform: isPulling ? `translateY(${threshold}px)` : 'none', transition: 'transform 0.2s' }}>
        {children}
      </Box>
    </Box>
  );
};

export default PullToRefresh;
