
export enum NotificationCategoryEnum {
    SHIFT_OFFER = "SHIFT_OFFER",
    SHIFT_REQUEST = "SHIFT_REQUEST",
    EMP_SHIFT_SWAPPING = "EMP_SHIFT_SWAPPING",
    TRADE = "TRADE"
}

export enum NotificationDefinitionEnum {
    SCHEDULE_SWAP_INITIATED = "SCHEDULE_SWAP_INITIATED"
}