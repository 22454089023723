import { AxiosClient } from "./axiosClient";

class PhpClient extends AxiosClient {
    constructor() {
        super();
        this.axiosInstance.defaults.baseURL = process.env.REACT_APP_PHP_BACKEND_URL;
    }
}

export default new PhpClient(); 
