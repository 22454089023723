import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API from "../../../axios/API";
import { createCIURL } from "../../../utils/helper";

export const logout = createAsyncThunk("/user/logout", async (version: number) => {
    try {
        const url = createCIURL(version, "/auth/logout");
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const getUsers = createAsyncThunk("/user/list", async (user: any) => {
    try {
        const response = await API.post("/employee-profile/user/list", user);
        console.log("api call result: user list ====>", response.data);
        return response.data;
    } catch (error) {
        console.log("error ===>", error);
    }
});

export const getUserDetails = createAsyncThunk("/userInfo", async () => {
    try {
        const response = await API.post("/user/user-info");
        console.log(">>> getUserDetails ", response.data);
        return response.data;
    } catch (error) {
        console.log("Error ===>", error);
    }
});

export const getUserDetailsMe = createAsyncThunk("/user/me", async () => {
    try {
        const response = await API.post("/user/user-info");
        // console.log(">>> getUserDetails me", response.data);
        return response.data;
    } catch (error) {
        console.log("Error ===>", error);
    }
});
