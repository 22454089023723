import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListItemAvatar from "@mui/material/ListItemAvatar/ListItemAvatar";
import Avatar from "@mui/material/Avatar/Avatar";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/system";
import { Button, Typography } from "@mui/material";
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../redux/store";
import { formatShift } from "../../../utils/helper";
import { SHIFT_STATUS } from "../../../utils/constants";

import { deleteSwapRequest, revertAction } from "../../../redux/features/mySchedule/ScheduleApi";

import { toggleProcessing, toggleSidebar } from "../../../redux/features/layout/LayoutSlice";

import { getScheduleData } from "../../../redux/features/mySchedule/ScheduleApi";
import React from "react";
interface PropsData {
    title: string;
}

export const ShiftDetails = (props: PropsData) => {
    const menuItems = [
        {
            title: "Trade shift",
            description: "Select shifts from other employees to initiate trade",
            url: "trade",
            icon: <SwapHorizontalCircleOutlinedIcon />,
        },
        {
            title: "Release shift",
            description: "Select other employees to pick up this shift from you",
            url: "release",
            icon: <AccountCircleOutlinedIcon />,
        },
        {
            title: "Drop shift",
            description: "Drop your shift manager will be notified",
            url: "drop",
            icon: <ArrowCircleDownOutlinedIcon />,
        },
    ];

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [fmShift, setFmShift] = useState(null as any);
    const [stRevertBtn, setStRevertBtn] = useState(false);
    const dateRange = useSelector((state: any) => state.mySchedule.dateRange);

    const selectedShift = useSelector((state: any) => state.mySchedule.selectedShift);
    const user = useSelector(({ user }: any) => user.user);

    useEffect(() => {
        if (!selectedShift) return;
        setFmShift(formatShift(selectedShift));
    }, [selectedShift]);

    const createHandleMenuClick = (menuItem: string) => {
        return () => {
            navigate("/my-schedule/" + menuItem);
        };
    };

    const handleRevert = async () => {
        if (selectedShift.id === -1) {
            return;
        }
        // await dispatch(revertAction({ schId: selectedShift.id, userId: user.id }));
        // dispatch(toggleSidebar(false));
        // dispatch(toggleProcessing(true));

        // await dispatch(getScheduleData({ dateRange: dateRange, user: user }));
        // dispatch(toggleProcessing(false));

        await dispatch(deleteSwapRequest({ scheduleId: selectedShift.id }));    

        dispatch(toggleSidebar(false));
    };

    if (!fmShift) {
        return <></>;
    }

    // console.log(">>>>>fmShift", fmShift);
    return (
        <>
            <List component="nav" sx={{ paddingBottom: 0 }}>
                <ListItem sx={{ px: 2 }}>
                    <ListItemAvatar>
                        <Avatar alt={fmShift.fullName} src={fmShift.osEmpImageUrl} sx={{ width: 40, height: 40 }} />
                    </ListItemAvatar>
                    <ListItemText primary={fmShift.fullName} />
                    {fmShift.pending && (
                        <Box sx={{ display: "flex", padding: 1 }}>
                            <Box sx={{ display: "flex", alignItems: "center", marginRight: 1 }}>
                                <Box
                                    component="span"
                                    sx={{
                                        width: 15,
                                        height: 15,
                                        borderRadius: "50%",
                                        bgcolor: "#ff9800",
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography color="GrayText">{SHIFT_STATUS[fmShift.status].label}</Typography>
                            </Box>
                        </Box>
                    )}
                </ListItem>
                {/* <Divider /> */}
                <ListItem>
                    <Box>
                        <Grid container>
                            {/* time */}
                            <Grid md={12} item>
                                <Box sx={{ p: 1 }}>
                                    <Typography display="block" gutterBottom sx={{ color: "GrayText" }}>
                                        Time
                                    </Typography>
                                    <Typography display="block">
                                        {fmShift.date} at {fmShift.time}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* title */}
                            {fmShift.title !== "" && (
                                <Grid md={6} item>
                                    <Box sx={{ p: 1 }}>
                                        <Typography display="block" gutterBottom sx={{ color: "GrayText" }}>
                                            Title
                                        </Typography>
                                        <Typography display="block">{fmShift.title}</Typography>
                                    </Box>
                                </Grid>
                            )}

                            {/* site */}
                            {fmShift.site && (
                                <Grid md={6} item>
                                    <Box sx={{ p: 1 }}>
                                        <Typography display="block" gutterBottom sx={{ color: "GrayText" }}>
                                            Site
                                        </Typography>
                                        <Typography display="block">{fmShift.site}</Typography>
                                    </Box>
                                </Grid>
                            )}

                            {/* department */}
                            {fmShift.department !== "" && (
                                <Grid md={6} item>
                                    <Box sx={{ p: 1 }}>
                                        <Typography display="block" gutterBottom sx={{ color: "GrayText" }}>
                                            Department
                                        </Typography>
                                        <Typography display="block">{fmShift.department}</Typography>
                                    </Box>
                                </Grid>
                            )}

                            {/* job code */}
                            {fmShift.jobcode && (
                                <Grid md={6} item>
                                    <Box sx={{ p: 1 }}>
                                        <Typography display="block" gutterBottom sx={{ color: "GrayText" }}>
                                            Job Code
                                        </Typography>
                                        <Typography display="block">{fmShift.jobcode}</Typography>
                                    </Box>
                                </Grid>
                            )}

                            {/* notes */}
                            {fmShift.notes !== null && (
                                <Grid md={12} item>
                                    <Box sx={{ p: 1 }}>
                                        <Typography display="block" gutterBottom sx={{ color: "GrayText" }}>
                                            Notes
                                        </Typography>
                                        <Typography display="block">{fmShift.notes}</Typography>
                                    </Box>
                                </Grid>
                            )}

                            {/* custom fields */}
                            {fmShift.customFields !== null &&
                                fmShift.customFields?.map((field: any, idx: number) => {
                                    return (
                                        <Grid md={6} item>
                                            <Box sx={{ p: 1 }}>
                                                <Typography display="block" gutterBottom sx={{ color: "GrayText" }}>
                                                    {Object.keys(field).map((key: any) => key)}
                                                </Typography>
                                                <Typography display="block">
                                                    {Object.keys(field).map((key: any) => field[key])}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </Box>
                </ListItem>
                {/* <Divider /> */}

                {/* shift status */}
                <ListItem>
                    <Box sx={{ width: "100%" }}>
                        <Grid container>
                            <Grid md={6} item>
                                <Box sx={{ p: 1 }}>
                                    <Typography display="block" gutterBottom sx={{ color: "GrayText" }}>
                                        Shift Status
                                    </Typography>
                                    <Typography display="block">{fmShift.label}</Typography>
                                </Box>
                            </Grid>

                            {/* revert */}
                            {fmShift.canRevert ? (
                                <Grid md={6} item>
                                    <Box sx={{ p: 1 }}>
                                        <Typography display="block" gutterBottom sx={{ color: "GrayText" }}>
                                            Revert
                                        </Typography>

                                        {/* revert decision button */}
                                        <Button
                                            // disabled={!fmShift.canRevert}
                                            // disabled={true}
                                            onClick={handleRevert}
                                            size="small"
                                            variant="contained"
                                        >
                                            Revert Decision
                                        </Button>
                                    </Box>
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Box>
                </ListItem>

                {/* actionable */}
                {fmShift.actionable && (
                    <ListItem sx={{ display: "flex" }}>
                        <Box sx={{ width: "100%" }}>
                            <Grid container>
                                <Grid md={12} item>
                                    <Dropdown>
                                        <TriggerButton sx={{ width: "100%" }} disabled={!fmShift.actionable}>
                                            Can't work
                                        </TriggerButton>
                                        <Menu slots={{ listbox: StyledListbox }}>
                                            {menuItems.map((item, idx) => {
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <StyledMenuItem
                                                            onClick={createHandleMenuClick(item.url)}
                                                            sx={{ cursor: "pointer" }}
                                                        >
                                                            <Box sx={{ display: "flex", padding: 1 }}>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        paddingRight: 2,
                                                                    }}
                                                                >
                                                                    {item.icon}
                                                                </Box>
                                                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                                                    <Box>
                                                                        <Typography>{item.title}</Typography>
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography
                                                                            color="GrayText"
                                                                            sx={{ fontSize: "13px" }}
                                                                        >
                                                                            {item.description}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </StyledMenuItem>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Menu>
                                    </Dropdown>
                                </Grid>
                            </Grid>
                        </Box>
                    </ListItem>
                )}
            </List>
        </>
    );
};

const blue = {
    50: "#F0F7FF",
    100: "#DAECFF",
    200: "#99CCF3",
    300: "#66B2FF",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
};

const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
};

const StyledListbox = styled("ul")(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    box-shadow: 0px 2px 16px ${theme.palette.mode === "dark" ? grey[900] : grey[200]};
    z-index: 1;
    `
);

const StyledMenuItem = styled(MenuItem)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${menuItemClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const TriggerButton = styled(MenuButton)(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 16px;
    line-height: 1.5;
    background: transparent;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    color: ${theme.palette.mode === "dark" ? blue[300] : blue[500]};
    cursor: pointer;
  
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }
  
    &:focus-visible {
      border-color: ${blue[400]};
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[500] : blue[200]};
    }

    &:disabled {
        outline: 3px solid ${grey[50]};
        color: ${grey[200]};
      }
    `
);

// export default ShiftDetails;
