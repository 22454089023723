import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Tab,
    Typography,
    Box,
    Grid,
    CircularProgress,
    Button,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Root, InlineTypography, CustomAppBar, CustomTabs } from "./styles";
import { useNavigate } from "react-router";
import { manageRequests, myRequests, pickups, trades, Notification } from "./sampleData";
import { useAppDispatch } from "../../redux/store";
import { getCategories, getInbox, getInboxDetails } from "../../redux/features/notifications/api";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { NotificationType } from "../../types";
import { NotificationCategoryEnum, NotificationDefinitionEnum } from "../../enums";
import { resetSelectedNotificationIndexDetail } from "../../redux/features/notifications/slice";
import { PENDING } from "../../redux/constants";
import { GetEmployeeShiftSwapStatusText } from "../../utils";

const getStatusColor = (status: string) => {
    switch (status) {
        case "Accepted":
            return "green";
        case "Pending":
            return "orange";
        case "Rejected":
            return "red";
        case "Open":
            return "blue";
        default:
            return "gray";
    }
};

const NotificationComponent: React.FC = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const allNotifications = useSelector((state: any) => state.notification.allNotifications) as NotificationType[];
    const notificationInboxDetailState = useSelector(
        (state: any) => state.notification.notificationInboxDetailState
    ) as string;
    const trades = useSelector((state: any) => state.notification.trades) as NotificationType[];
    const requestState = useSelector((state: any) => state.notification.requestState) as string;

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleListItemClick = (notification: NotificationType) => {
        if (notification.notificationCategory === NotificationCategoryEnum.EMP_SHIFT_SWAPPING && notification.id) {
            // dispatch(resetSelectedNotificationIndexDetail(null));
            dispatch(getInboxDetails({ notificationId: notification.id }));
            // navigate(`/notification/${id}`);
            navigate(`/notificationDetail`);
        }
    };

    const getNotifications = () => {
        switch (tabIndex) {
            case 0:
                return trades;
            case 1:
                return allNotifications;
            // case 2:
            //   return pickups;
            // case 3:
            //   return trades;
            default:
                // return manageRequests;
                return [];
        }
    };

    useEffect(() => {
        console.log("calling getCategories");
        const today = new Date();
        today.setHours(23, 59, 59, 999);
        const day = dayjs(today).format("YYYY-MM-DD HH:mm:ss");
        dispatch(getCategories());
        dispatch(
            getInbox({
                category: [NotificationCategoryEnum.EMP_SHIFT_SWAPPING],
                dateRange: `2024-01-01 00:00:00 - ${day}`,
            })
        );
    }, []);

    const getUserName = (notification: NotificationType) => {
        if (notification.notificationParamJson?.notifier_name) {
            return notification.notificationParamJson?.notifier_name;
        } else if (notification.actorProfile?.osEmpFirstName && notification.actorProfile?.osEmpLastName) {
            return `${notification.actorProfile?.osEmpFirstName} ${notification.actorProfile?.osEmpLastName}`;
        } else {
            return "Unknown";
        }
    };

    const getUserPhoto = (notification: NotificationType) => {
        if (notification.notificationParamJson?.notifier_photo) {
            return notification.notificationParamJson?.notifier_photo;
        } else if (notification.actorProfile?.osEmpImageName) {
            return notification.actorProfile?.osEmpImageName;
        } else {
            return "";
        }
    };

    const getActionString = (notification: NotificationType) => {
        const defaultResponse = (
            <>
                <Typography component="span" variant="body2" color="primary">
                    {getUserName(notification)}
                </Typography>{" "}
                {` has send a notification!`}
            </>
        );
        if (notification.notificationDefinationCode) {
            switch (notification.notificationDefinationCode) {
                case NotificationDefinitionEnum.SCHEDULE_SWAP_INITIATED:
                    return (
                        <>
                            <Typography component="span" variant="body2" color="primary">
                                {getUserName(notification)}
                            </Typography>{" "}
                            {` initiated a shift swap request!`}
                        </>
                    );

                default:
                    return defaultResponse;
            }
        } else {
            return defaultResponse;
        }
    };

    const getProcessTimeString = (notification: NotificationType) => {
        if (notification.notificationProcessDatetime) {
            return (
                <Typography component="span" variant="body2" color="textSecondary">
                    {dayjs(notification.notificationProcessDatetime).format("MMMM D, YYYY h:mm A")}
                </Typography>
            );
        }
        return null;
    };

    const renderList = () => {
        return (
            <List>
                {getNotifications().map((notification: NotificationType) => (
                    <ListItem
                        alignItems="flex-start"
                        key={notification.id}
                        button
                        onClick={() => handleListItemClick(notification)}
                    >
                        <ListItemAvatar>
                            <Avatar
                                alt={notification.notificationParamJson?.notifier_name}
                                src={getUserPhoto(notification)}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={getActionString(notification)}
                            secondary={
                                <Box component="span" display="flex" flexDirection="row" justifyContent={"space-between"}>
                                <Typography variant="body2" color="textSecondary">
                                    {getProcessTimeString(notification)}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {/* Add your additional information here */}
                                    {GetEmployeeShiftSwapStatusText(notification.transaction?.osEmpShiftSwapStatus)}
                                </Typography>
                            </Box>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        );
    };

    const test = () => {
        console.log("trades", trades);
    }

    return (
        // <ThemeProvider theme={theme}>
            <Root>
                <CustomAppBar position="static">
                    <CustomTabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                    >
                        <Tab label="Shift Swapping"></Tab>
                        {/* <Tab label="Others"></Tab> */}
                        {/* <Tab label="Manage Requests" />
                            <Tab label="My Requests" />
                            <Tab label="Shifts Available to Pickup" />
                            <Tab label="Shifts Available to Trade" /> */}
                    </CustomTabs>
                </CustomAppBar>
                {/* <Button onClick={test}><Typography>Test</Typography></Button> */}
                <Box sx={{ height: "calc(100vh - 64px)", overflowY: "auto" }}>
                    {requestState === PENDING && tabIndex === 0 ? (
                        <>
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <CircularProgress color="inherit" />
                            </Box>
                        </>
                    ) : (
                        <>{renderList()}</>
                    )}
                </Box>
            </Root>
        // </ThemeProvider>
    );
};

export default NotificationComponent;
