import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar/ListItemAvatar';
import Avatar from '@mui/material/Avatar/Avatar';
import { Button, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {
    formatShift
} from '../../../utils/helper'
import { useAppDispatch } from "../../../redux/store";
import { useSelector } from 'react-redux'
import {
    confirmDrop, getScheduleData
} from '../../../redux/features/mySchedule/ScheduleApi';
import {
    toggleProcessing,
    toggleSidebar
} from '../../../redux/features/layout/LayoutSlice'
import { useNavigate } from "react-router-dom";
import { DROP } from '../../../utils/constants';

interface PropsData {
    title: string
}

const DropShift = (props: PropsData) => {

    const [fmShift, setFmShift] = useState(null as any);
    const [reason, setReason] = useState('');
    const [btnState, setBtnState] = useState(true);


    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const selectedShift = useSelector((state: any) => state.mySchedule.selectedShift);
    const dateRange = useSelector((state: any) => state.mySchedule.dateRange);
    const user = useSelector((state: any) => state.user.user);

    useEffect(() => {
        setFmShift(formatShift(selectedShift));
    }, [selectedShift]);

    const handleDropConfirm = async () => {
        dispatch(toggleSidebar(false));
        dispatch(toggleProcessing(true))

        await dispatch(confirmDrop({
            user: user,
            selectedShift: fmShift.id,
            reason: reason,
            requestType:DROP
        }));
        await dispatch(getScheduleData({ dateRange: dateRange, user: user }));
        dispatch(toggleProcessing(false))

        navigate('/my-schedule/schedule');
    }


    const handleTextField = (event: any) => {
        var text = event.target.value;
        if (text === '') {
            setBtnState(true);
        } else {
            setBtnState(true);
        }
        setReason(text);
    }

    if (!fmShift) return (<></>)
    return (
        <List component="nav">
            <ListItem sx={{ px: 2 }}>
                <ListItemAvatar>
                    <Avatar
                        alt={fmShift.fullName}
                        src={fmShift.osEmpImageUrl}
                        sx={{ width: 40, height: 40 }}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={fmShift.date + ' | ' + fmShift.time}
                    secondary={fmShift.fullName}
                />
            </ListItem>
            <Divider />
            <ListItem sx={{ py: 3 }}>
                <TextField
                    id="outlined-multiline-flexible"
                    placeholder='Reason for Request'
                    multiline
                    size='small'
                    rows={8}
                    fullWidth
                    onChange={handleTextField}
                />
            </ListItem>
            <ListItem sx={{ pb: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="contained" disabled={!btnState} onClick={handleDropConfirm} size='small' endIcon={<SendIcon />}>
                    Confirm
                </Button>
            </ListItem>
        </List>
    )
}

export default DropShift;