import { createSlice } from "@reduxjs/toolkit";
import { TimesheetGroupedByDate, TimesheetV2State } from "../../../types/state";
import { getTimeSheets, getSummary, getAudit, getNotes, addTimecardRequest } from "./api";
import { FULFILLED, PENDING, REJECTED } from "../../constants";
import { AuditLogs, TimesheetType } from "../../../types";
import { noAuto } from "@fortawesome/fontawesome-svg-core";

const initialState: TimesheetV2State = {
    timesheets: [],
    requestStatus: FULFILLED,
    timesheetSummary: {},
    timesheetGroupedByDate: {},
    audits: [],
    notes: [],
    selectedRecord: undefined,
    selectedTab: 0,
    allowRefetch: true,
};

export const TimesheetV2Slice = createSlice({
    name: "timesheetV2",
    initialState: initialState,
    reducers: {
        setSelectedRecord: (state, { payload }) => {
            state.selectedRecord = payload;
        },

        clearSelectedRecord: (state) => {
            state.selectedRecord = undefined;
        },

        setSelecedTab: (state, { payload }) => {
            state.selectedTab = payload;
        },

        setAllowRefetch: (state, { payload }) => {
            console.log('!!! payload', payload);
            state.allowRefetch = payload;
        },

        resetSelectedTab: (state) => {
            state.selectedTab = 0;
        },

        resetAllowRefetch: (state) => {
            state.allowRefetch = true;
        },

        reset: () => initialState,
    },
    extraReducers: {
        /** get timesheets */
        [getTimeSheets.pending.type]: (state) => {
            state.timesheets = [];
            state.requestStatus = PENDING;
        },

        [getTimeSheets.fulfilled.type]: (state, { payload }) => {
            if (payload.data) {
                const timesheets = payload.data as TimesheetType[];
                state.timesheets = timesheets;

                // group timesheets by date
                const timesheetsGroupedByDate = timesheets.reduce(
                    (acc: TimesheetGroupedByDate, timesheet: TimesheetType) => {
                        if (!timesheet.start_datetime) return acc;
                        const date = timesheet.start_datetime?.split(" ")[0];
                        if (date && !acc[date]) {
                            acc[date] = [];
                        }
                        acc[date].push(timesheet);
                        return acc;
                    },
                    {}
                );

                state.timesheetGroupedByDate = timesheetsGroupedByDate;

                console.log("timesheetsGroupedByDate", timesheetsGroupedByDate);
            }
            state.requestStatus = FULFILLED;
        },

        [getTimeSheets.rejected.type]: (state) => {
            state.timesheets = [];
            state.requestStatus = REJECTED;
        },

        /** get timesheet summary */
        [getSummary.pending.type]: (state) => {
            state.timesheetSummary = {};
            state.requestStatus = PENDING;
        },

        [getSummary.fulfilled.type]: (state, { payload }) => {
            if (payload.data) {
                state.timesheetSummary = payload.data;
            }
            state.requestStatus = FULFILLED;
        },

        [getSummary.rejected.type]: (state) => {
            state.timesheetSummary = {};
            state.requestStatus = REJECTED;
        },

        /** get timesheet audit */
        [getAudit.pending.type]: (state) => {
            state.audits = [];
            state.requestStatus = PENDING;
        },

        [getAudit.fulfilled.type]: (state, { payload }) => {
            if (payload.data) {
                state.audits = payload.data.map((audit: any) => ({
                    ...audit,
                    audit_logs: audit.audit_logs ? (JSON.parse(audit.audit_logs) as AuditLogs) : null,
                }));
            }
            console.log("*** audits", state.audits);
            state.requestStatus = FULFILLED;
        },

        [getAudit.rejected.type]: (state) => {
            state.audits = [];
            state.requestStatus = REJECTED;
        },

        /** get timesheet notes */
        [getNotes.pending.type]: (state) => {
            state.notes = [];
            state.requestStatus = PENDING;
        },

        [getNotes.fulfilled.type]: (state, { payload }) => {
            if (payload.data) {
                state.notes = payload.data.map((note: any) => ({
                    ...note,
                    notes_json: note.notes_json ? JSON.parse(note.notes_json) : null,
                }));
                console.log("*** notes", state.notes);
            }
            state.requestStatus = FULFILLED;
        },

        [getNotes.rejected.type]: (state) => {
            state.notes = [];
            state.requestStatus = REJECTED;
        },

        /** Add employee timecard request */
        [addTimecardRequest.pending.type]: (state) => {
            state.requestStatus = PENDING;
        },

        [addTimecardRequest.fulfilled.type]: (state, { payload }) => {
            state.requestStatus = FULFILLED;
        },

        [addTimecardRequest.rejected.type]: (state) => {
            state.requestStatus = REJECTED;
        },
    },
});

export const {
    clearSelectedRecord,
    setSelectedRecord,
    resetAllowRefetch,
    resetSelectedTab,
    setAllowRefetch,
    setSelecedTab,
    reset,
} = TimesheetV2Slice.actions;
export default TimesheetV2Slice.reducer;
